import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgOnlineShopping = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M5.56 6.045a.437.437 0 1 0 0 .875.437.437 0 0 0 0-.875Zm0 .583a.146.146 0 0 1-.145-.146c0-.08.065-.145.145-.145.08 0 .145.065.145.145 0 .08-.065.146-.145.146Zm1.164-.583a.437.437 0 1 0 0 .875.437.437 0 0 0 0-.875Zm0 .583a.146.146 0 0 1-.146-.146.146.146 0 0 1 .29 0c0 .08-.065.146-.144.146Zm1.163-.583a.437.437 0 1 0 0 .875.437.437 0 0 0 0-.875Zm0 .583a.146.146 0 0 1-.145-.146c0-.08.066-.145.145-.145.08 0 .146.065.146.145 0 .08-.066.146-.146.146ZM18.672 5H5.327A1.33 1.33 0 0 0 4 6.328l.016 7.301v3.74c0 .632.514 1.147 1.147 1.147h13.51A1.33 1.33 0 0 0 20 17.189V6.327A1.33 1.33 0 0 0 18.673 5h-.001Zm.535 12.19c0 .294-.241.534-.536.535l-9.784.015H5.162a.371.371 0 0 1-.371-.37V7.965h14.416v9.225Zm0-10.018H4.792v-.845c0-.295.24-.535.535-.536h13.345c.295 0 .535.24.536.535v.845l-.001.001Zm-3.3 4.117h-.364L14.26 9.277a.367.367 0 1 0-.62.396l1.03 1.616h-5.3l1.031-1.616a.368.368 0 1 0-.62-.396l-1.283 2.012h-.404a.874.874 0 0 0-.873.873v.054c0 .37.231.686.559.814l.546 3.002a.472.472 0 0 0 .018.068 1.17 1.17 0 0 0 1.098.75h5.12c.489 0 .92-.294 1.097-.75.015-.04.046-.12.566-3.069a.876.876 0 0 0 .557-.813v-.053a.874.874 0 0 0-.873-.873l-.003-.003Zm-.944 4.56a.443.443 0 0 1-.404.264H9.44a.437.437 0 0 1-.398-.253l-.582-3.205a.368.368 0 0 0-.29-.295H8.16c-.02-.003-.04-.007-.063-.007h-.005a.136.136 0 0 1-.137-.136v-.053c0-.076.061-.137.137-.137h7.813c.076 0 .137.061.137.137v.053a.136.136 0 0 1-.137.136H15.9a.322.322 0 0 0-.058.006h-.012a.37.37 0 0 0-.291.297 222.383 222.383 0 0 1-.577 3.192l.001.001Zm-4.25-.697a.276.276 0 0 1-.274.318.278.278 0 0 1-.274-.238l-.312-2.144a.276.276 0 1 1 .548-.08l.312 2.144Zm3.434-2.064-.312 2.144a.278.278 0 0 1-.274.238c-.013 0-.027 0-.04-.004a.277.277 0 0 1-.234-.314l.312-2.144a.277.277 0 1 1 .548.08Zm-1.87-.04v2.144a.277.277 0 0 1-.555 0v-2.144a.277.277 0 0 1 .554 0Z"
      />
    </SvgIcon>
  )
}
export default SvgOnlineShopping
