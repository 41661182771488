import React, { PropsWithChildren, createElement } from 'react'
import { DEFAULT_WRAPPER } from './constants'

export type ConditionalWrapper = {
  condition: boolean
  wrapperTag?: keyof HTMLElementTagNameMap
  className?: string
}

export const ConditionalWrapper: React.FC<PropsWithChildren<ConditionalWrapper>> = ({
  condition,
  children,
  wrapperTag = DEFAULT_WRAPPER,
  ...rest
}): JSX.Element => {
  return condition ? createElement(wrapperTag, rest, children) : <div className={rest.className}>{children}</div>
}
