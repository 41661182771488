import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgUVBlocker165 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M10.915 12.573a3.076 3.076 0 0 0 2.36.048 3.065 3.065 0 0 0 1.704-1.635 3.07 3.07 0 0 0 .05-2.36 3.088 3.088 0 0 0-3.996-1.753A3.088 3.088 0 0 0 9.28 10.87c.299.768.88 1.372 1.634 1.704Zm.395-4.99a2.328 2.328 0 0 1 3.01 1.32c.225.58.212 1.21-.038 1.779A2.303 2.303 0 0 1 13 11.912a2.302 2.302 0 0 1-1.778-.036 2.326 2.326 0 0 1 .09-4.292ZM9.834 4.85a.38.38 0 0 1 .71-.277l.416 1.068a.38.38 0 0 1-.71.277L9.835 4.85Zm3.683.844.46-1.05a.38.38 0 1 1 .697.306L14.214 6a.381.381 0 0 1-.697-.308v.002Zm2.25 2.643a.38.38 0 0 1 .217-.492l1.068-.417a.38.38 0 0 1 .276.71l-1.068.416a.381.381 0 0 1-.493-.216Zm-.06 2.969a.38.38 0 0 1 .501-.196l1.05.46a.38.38 0 1 1-.306.696l-1.05-.46a.38.38 0 0 1-.195-.501v.001Zm-1.65 2.272.417 1.068a.38.38 0 0 1-.71.276l-.416-1.068a.38.38 0 0 1 .71-.276Zm-3.461-.277a.38.38 0 0 1 .195.5l-.46 1.05a.381.381 0 0 1-.697-.306l.46-1.05a.38.38 0 0 1 .502-.196v.002Zm-3.833-1.45a.38.38 0 0 1 .217-.493l1.068-.416a.38.38 0 0 1 .276.709l-1.068.416a.381.381 0 0 1-.493-.216Zm.092-4.428a.38.38 0 0 1 .501-.196l1.05.46a.38.38 0 1 1-.306.696l-1.05-.46a.38.38 0 0 1-.195-.501Zm11.908 7.815-.028 1.81a.38.38 0 0 1-.38.375h-.006a.381.381 0 0 1-.375-.386l.014-.934-5.535 4.152a.377.377 0 0 1-.47-.01l-5.656-4.677a.381.381 0 0 1 .485-.587l5.425 4.484 5.16-3.87-.86-.012a.381.381 0 0 1 .01-.761l1.806.026a.26.26 0 0 1 .04.003l.036.005c.193.024.337.189.335.383h-.001Z"
      />
    </SvgIcon>
  )
}
export default SvgUVBlocker165
