import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCloudAndRain = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M8.441 9.503a.335.335 0 0 1-.318-.44 3.535 3.535 0 0 1 3.36-2.424.335.335 0 0 1 0 .67c-1.238 0-2.334.79-2.724 1.965a.335.335 0 0 1-.318.23v-.001ZM6.653 18.68l1.25-2.085a.335.335 0 0 0-.574-.344l-1.25 2.085a.334.334 0 0 0 .287.507.334.334 0 0 0 .287-.163Zm1.95 0 1.251-2.085a.335.335 0 0 0-.574-.344l-1.25 2.085a.334.334 0 0 0 .286.507.334.334 0 0 0 .287-.163Zm1.95 0 1.252-2.085a.335.335 0 0 0-.574-.344L9.98 18.335a.334.334 0 0 0 .287.507.334.334 0 0 0 .287-.163Zm1.848 0 1.251-2.085a.335.335 0 0 0-.574-.344l-1.251 2.085a.334.334 0 0 0 .287.507.334.334 0 0 0 .287-.163Zm1.95 0 1.251-2.085a.335.335 0 0 0-.574-.344l-1.25 2.085a.334.334 0 0 0 .286.507.334.334 0 0 0 .287-.163Zm1.95 0 1.252-2.085a.335.335 0 1 0-.574-.344l-1.251 2.085a.334.334 0 0 0 .287.507.334.334 0 0 0 .287-.163ZM20 11.298c0 2.18-1.634 3.99-3.8 4.21l-.032.002-7.36.02H7.25A3.255 3.255 0 0 1 4 12.28c0-1.448.976-2.728 2.35-3.124A5.316 5.316 0 0 1 11.544 5a5.34 5.34 0 0 1 4.21 2.067h.014A4.237 4.237 0 0 1 20 11.3Zm-.67 0a3.567 3.567 0 0 0-3.726-3.558.33.33 0 0 1-.286-.139 4.67 4.67 0 0 0-3.773-1.932 4.647 4.647 0 0 0-4.578 3.818.334.334 0 0 1-.259.268 2.593 2.593 0 0 0-2.037 2.523 2.585 2.585 0 0 0 2.581 2.582h1.556l7.342-.02a3.55 3.55 0 0 0 3.181-3.543v.001Z"
      />
    </SvgIcon>
  )
}
export default SvgCloudAndRain
