import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSunglassesGlasses = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M19.736 12.487a.308.308 0 0 0-.006-.044l-.006-.026a.396.396 0 0 0-.073-.148l-.021-.025a.514.514 0 0 0-.035-.035c-.084-.074-.406-.335-.987-.565l-4.983-2.817a3.688 3.688 0 0 0-2.58-.392l-.774.167a.395.395 0 0 0-.306.472c.04.186.2.315.39.315.028 0 .315-.059.86-.177a2.896 2.896 0 0 1 2.018.307l3.138 1.773h-.009c-1.264.051-2.648.466-4.11 1.235h-.994c-1.566-.822-3.038-1.24-4.378-1.24-.591 0-1.151.083-1.665.244l-.007.003 2.083-3.537a2.057 2.057 0 0 1 1.767-1.01h.066a.399.399 0 0 0 0-.797h-.066a2.856 2.856 0 0 0-2.454 1.403L3.84 12.287a.198.198 0 0 0-.015.03l-.004.006a.37.37 0 0 0-.04.107l-.002.013-.005.03v.012l-.004.013c-.097 1.005.24 2.502 1.208 3.578.65.722 1.478 1.104 2.393 1.104 2.125 0 2.844-1.465 3.605-3.017.16-.328.296-.595.426-.84h.705c.129.244.264.511.426.841.761 1.551 1.48 3.017 3.605 3.017.915 0 1.742-.382 2.393-1.104.972-1.08 1.306-2.582 1.206-3.591Zm-9.116.61c-.129.248-.252.498-.359.718-.733 1.493-1.262 2.57-2.89 2.57-.693 0-1.299-.281-1.8-.84-.749-.83-1.05-2.018-1.018-2.825v-.002c.27-.191 1.035-.636 2.323-.636 1.132 0 2.392.34 3.743 1.011h.002l-.001.004Zm7.319 2.449c-.503.558-1.109.84-1.8.84-1.63 0-2.16-1.078-2.891-2.57-.107-.221-.23-.47-.359-.72v-.001l.001-.002c1.35-.671 2.61-1.011 3.743-1.011.586 0 1.128.09 1.612.27l.713.404c.022.801-.283 1.974-1.017 2.79h-.002Zm-1.185-.087a.397.397 0 0 1-.178-.754c.413-.209.537-.614.542-.632a.403.403 0 0 1 .48-.274.396.396 0 0 1 .286.489c-.01.035-.226.76-.95 1.128a.399.399 0 0 1-.18.042v.001Zm-8.726 0a.397.397 0 0 1-.18-.752c.416-.21.54-.616.544-.633a.4.4 0 0 1 .48-.274.399.399 0 0 1 .286.486c-.01.039-.225.763-.952 1.13a.402.402 0 0 1-.179.042l.001.001Z"
      />
    </SvgIcon>
  )
}
export default SvgSunglassesGlasses
