import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgComfortableLens = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M19.16 14.11c-.732-1.901-2.154-3.968-4.005-5.819s-3.917-3.273-5.819-4.005c-2.002-.77-3.612-.689-4.532.231-.027.027-.051.057-.077.085l-.026.022c-.863.863-1.214 2.862-.897 5.093.212 1.493.917 4.345 3.251 6.68 2.334 2.334 5.186 3.038 6.679 3.25.545.078 1.076.115 1.579.115 1.56 0 2.861-.36 3.513-1.012a.415.415 0 0 0 .044-.054c.019-.018.04-.033.059-.052.92-.92 1.001-2.53.23-4.532v-.002ZM9.059 5.01c1.802.692 3.772 2.054 5.548 3.83 1.776 1.775 3.136 3.746 3.83 5.548.645 1.675.625 3.027-.055 3.708-.362.363-.89.534-1.53.534-1.984 0-5.057-1.639-7.726-4.308-3.532-3.532-5.26-7.77-3.774-9.256.35-.35.878-.526 1.535-.526.621 0 1.359.156 2.172.47ZM7.602 15.847c-2.175-2.175-2.833-4.843-3.032-6.24a11.145 11.145 0 0 1-.084-.79c.667 1.881 2.082 4.04 4.092 6.05 2.013 2.014 4.177 3.43 6.06 4.096a11.393 11.393 0 0 1-.797-.084c-1.397-.2-4.065-.857-6.24-3.032h.001Zm2.416-2.283a.386.386 0 1 1 .548-.547c2.367 2.368 4.8 3.697 6.692 3.644a.386.386 0 1 1 .021.773l-.124.002c-2.077 0-4.669-1.405-7.136-3.872Z"
      />
    </SvgIcon>
  )
}
export default SvgComfortableLens
