import { isPictureMedia } from '@typesApp/cmsPlacement/Placement'
import { getCmsImageCrops, getImageFromCMS } from '@utils/url'
import { IPreloadLinks, TMedia } from '../../types/cmsPlacement/Media'
import useBreakpoints from '../useBreakpoints'

const useMediaByDeviceType = (medias?: TMedia[]) => {
  const { isMobile } = useBreakpoints()
  return getMediaByDeviceType(isMobile, medias)
}

// Useful for non-functional components or for conditional rendering
const getMediaByDeviceType = (isMobile: boolean, medias?: TMedia[]) => {
  return !!medias ? (isMobile && medias?.length > 1 ? medias[1] : medias[0]) : undefined
}

export const getPreloadLinks = (
  hostnameUrls: Record<string, string>,
  type?: string,
  medias?: TMedia[]
): IPreloadLinks | undefined => {
  if (!medias || !type) return
  const desktopMedia = medias[0]
  const mobileMedia = medias[1]

  const { mobile, deskL, deskS, tabletL, tabletP } = getCmsImageCrops(type)

  // Mobile
  const pictureMobile = isPictureMedia(mobileMedia) ? mobileMedia : undefined
  const videoMobile = mobileMedia?.type === 'CMVideo' ? mobileMedia : undefined
  const mobileUrl = pictureMobile?.uriTemplate ?? videoMobile?.picture?.uriTemplate ?? ''

  // Desktop
  const pictureDesktop = isPictureMedia(desktopMedia) ? desktopMedia : undefined
  const videoDesktop = desktopMedia?.type === 'CMVideo' ? desktopMedia : undefined
  const desktopUrl = pictureDesktop?.uriTemplate ?? videoDesktop?.picture?.uriTemplate ?? ''

  return {
    mobile: getImageFromCMS(hostnameUrls, mobileUrl, mobile?.crop || '', mobile?.width),
    deskL: getImageFromCMS(hostnameUrls, desktopUrl, deskL?.crop || '', deskL?.width),
    deskS: getImageFromCMS(hostnameUrls, desktopUrl, deskS?.crop || '', deskS?.width),
    tabletL: getImageFromCMS(hostnameUrls, desktopUrl, tabletL?.crop || '', tabletL?.width),
    tabletP: getImageFromCMS(hostnameUrls, desktopUrl, tabletP?.crop || '', tabletP?.width),
  }
}

export default useMediaByDeviceType
