import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const Svg12HrsPlusMinus167 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M13.412 4.96v-.371h.083c.597 0 1.083-.487 1.083-1.084v-.092c0-.597-.486-1.083-1.083-1.083h-2.55c-.597 0-1.083.486-1.083 1.083v.092c0 .597.486 1.084 1.083 1.084h.083v.37C7.181 5.539 4.22 8.865 4.22 12.87c0 4.412 3.589 8 8 8s8-3.588 8-8c0-4.006-2.96-7.332-6.808-7.91Zm-2.468-1.276a.179.179 0 0 1-.178-.179v-.092c0-.098.08-.178.178-.178h2.55c.098 0 .178.08.178.178v.092c0 .098-.08.179-.179.179h-.535a.453.453 0 0 0-.452.452v.742c-.096-.004-.192-.008-.287-.008-.096 0-.192.004-.287.008v-.742a.453.453 0 0 0-.453-.452h-.535Zm1.275 16.283a7.103 7.103 0 0 1-7.095-7.095 7.103 7.103 0 0 1 7.095-7.095 7.103 7.103 0 0 1 7.095 7.095 7.103 7.103 0 0 1-7.095 7.095Zm-3.2-10.87-.614.159-.148-.88 1.045-.306h.767v4.16h-1.05V9.098Zm2.184.354-.631-.672c.419-.49.856-.75 1.498-.75.803 0 1.37.49 1.37 1.233v.018c0 .555-.26.891-.804 1.393l-.69.642h1.505v.914h-2.856v-.838l1.316-1.263c.336-.33.467-.513.467-.75 0-.236-.16-.395-.42-.395-.259 0-.46.154-.754.467h-.002Zm4.59.242h.933v.92h-.933v.95h-.95v-.95h-.932v-.92h.932v-.95h.95v.95Zm-4.514 5.502v2.207H10.24v-1.883c0-.294-.148-.453-.366-.453-.219 0-.384.159-.384.453v1.883H8.453V13.13H9.49v1.493c.182-.248.437-.483.844-.483.596 0 .944.412.944 1.056v-.001Zm2.372-1.038v1.139h-.07c-.503 0-.78.295-.78.938v1.169h-1.037v-3.192h1.038v.578c.154-.4.407-.655.85-.63v-.002Zm2.637 2.253v.012c0 .667-.483 1.05-1.203 1.05-.43 0-.909-.13-1.321-.437l.389-.696c.313.219.642.354.927.354.182 0 .26-.076.26-.177v-.011c0-.125-.148-.19-.467-.307-.561-.207-.974-.43-.974-1.008v-.012c0-.642.496-1.033 1.186-1.033.395 0 .814.113 1.169.343l-.373.708c-.26-.159-.56-.271-.784-.271-.166 0-.249.07-.249.165v.012c0 .117.16.182.472.306.578.224.967.46.967 1.004l.002-.002Z"
      />
    </SvgIcon>
  )
}
export default Svg12HrsPlusMinus167
