import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const Svg12HrsPlusMinus166 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M20.13 12.342a8.008 8.008 0 0 1-3.042 5.873 7.948 7.948 0 0 1-5.899 1.662 7.954 7.954 0 0 1-5.345-2.997C3.11 13.412 3.71 8.368 7.179 5.636a8.029 8.029 0 0 1 9.401-.37l-.15-.918a.389.389 0 0 1 .54-.419.402.402 0 0 1 .241.302l.296 1.816a.436.436 0 0 1-.076.314.393.393 0 0 1-.256.162l-1.821.3a.387.387 0 0 1-.429-.252.44.44 0 0 1-.024-.11l-.002-.02a.389.389 0 0 1 .324-.424l.867-.142a7.248 7.248 0 0 0-8.43.372C4.528 8.714 3.986 13.267 6.452 16.4a7.18 7.18 0 0 0 4.827 2.706 7.18 7.18 0 0 0 5.326-1.5 7.236 7.236 0 0 0 2.746-5.303.389.389 0 0 1 .777.042v-.002ZM9.153 11.337h.902V7.763h-.658l-.898.263.127.756.527-.137V11.337Zm1.354 0h2.454v-.785h-1.293L12.26 10c.466-.43.69-.72.69-1.196v-.016c0-.638-.487-1.06-1.177-1.06-.552 0-.927.224-1.287.645l.542.577c.253-.268.431-.4.649-.4.217 0 .36.136.36.34 0 .203-.111.36-.401.644l-1.13 1.085v.72-.002Zm3.56-.523h.745v-.745h.73v-.722h-.73v-.745h-.745v.745h-.731v.722h.73v.745Zm-5.4 4.966h.893v-1.617c0-.254.136-.39.33-.39.193 0 .314.136.314.39v1.617h.892v-1.896c0-.552-.299-.908-.811-.908-.35 0-.567.204-.725.416v-1.283h-.892v3.67Zm2.845 0h.892v-1.004c0-.552.239-.807.669-.807h.06v-.978c-.379-.02-.598.198-.729.542v-.496h-.892v2.742Zm2.855.06c.618 0 1.034-.329 1.034-.901v-.01c0-.466-.335-.669-.831-.862-.269-.106-.405-.162-.405-.263v-.01c0-.081.07-.142.213-.142.192 0 .45.096.674.233l.32-.609a1.864 1.864 0 0 0-1.004-.294c-.593 0-1.019.334-1.019.888v.01c0 .496.355.69.837.866.273.1.4.157.4.263v.01c0 .087-.065.152-.223.152-.243 0-.527-.116-.796-.304l-.334.597c.354.264.765.376 1.135.376h-.001Z"
      />
    </SvgIcon>
  )
}
export default Svg12HrsPlusMinus166
