import React, { useEffect } from 'react'
import { useSite } from '../../foundation/hooks/useSite'
import useExternalScript from '../../hooks/useExternalScript'

interface Props {
  grandTotal: string | number
  variant?: 'credit-promotion-badge' | 'credit-promotion-standard' | 'credit-promotion-auto-size'
}

const KlarnaOnSiteMessage: React.FC<Props> = ({ grandTotal, variant = 'credit-promotion-auto-size' }) => {
  const { mySite } = useSite()

  const urlKlarna =
    process?.env?.NODE_ENV === 'development'
      ? 'https://eu-library.playground.klarnaservices.com/lib.js'
      : 'https://osm.klarnaservices.com/lib.js' // prod

  const [isLoaded, KlarnaOnsiteService] = useExternalScript(urlKlarna, 'KlarnaOnsiteService', {
    id: 'klarna-on-site-message',
    'data-environment': process?.env?.NODE_ENV === 'development' ? '' : 'production',
    'data-clientId':
      process?.env?.NODE_ENV === 'development'
        ? '994a146c-e79a-51b7-bc83-d484aa697d60'
        : 'aafebe73-4c90-5dde-aa71-898158596f3e', //prod
    defer: true,
    async: process?.env?.NODE_ENV === 'development' ? false : true,
    type: 'text/javascript',
  })

  const dataLocale = mySite.locale.replace('_', '-')

  useEffect(() => {
    if (isLoaded && grandTotal) {
      KlarnaOnsiteService?.push({
        eventName: 'refresh-placements',
      })
    }
  }, [KlarnaOnsiteService, isLoaded, grandTotal])

  const grandTotalWithCents = (
    (typeof grandTotal !== 'number' ? parseFloat(grandTotal || '0') : grandTotal) * 100
  ).toFixed(2)

  if (!isLoaded || !grandTotal) return null

  return (
    //   @ts-ignore
    <klarna-placement data-key={variant} data-locale={dataLocale} data-purchase-amount={`${grandTotalWithCents}`} />
  )
}

export default KlarnaOnSiteMessage
