import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMoneyBackGuaranteeDollar = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M18.346 16.957a7.934 7.934 0 0 1-5.332 2.989 7.928 7.928 0 0 1-5.884-1.657 7.99 7.99 0 0 1-3.034-5.859.388.388 0 0 1 .774-.04 7.214 7.214 0 0 0 2.74 5.29 7.161 7.161 0 0 0 5.312 1.495 7.165 7.165 0 0 0 4.814-2.699c2.46-3.123 1.921-7.666-1.203-10.127a7.23 7.23 0 0 0-8.41-.37l.866.141a.39.39 0 0 1 .323.424l-.002.02a.455.455 0 0 1-.027.117.388.388 0 0 1-.425.242l-1.817-.3a.39.39 0 0 1-.247-.15.448.448 0 0 1-.082-.324l.295-1.811a.403.403 0 0 1 .24-.303.388.388 0 0 1 .538.418l-.15.916a8.008 8.008 0 0 1 9.378.369c3.46 2.725 4.057 7.756 1.333 11.215v.004Zm-4.875-4.253c-.118-.21-.304-.4-.555-.562-.237-.153-.591-.311-1.052-.47-.416-.148-.71-.275-.873-.377a.902.902 0 0 1-.32-.305.878.878 0 0 1-.092-.435c0-.284.1-.501.309-.663.22-.17.524-.258.907-.258.411 0 .85.097 1.304.286l.162.068.018-.04a.364.364 0 0 0 .3-.212.373.373 0 0 0-.04-.378l.023-.044-.175-.075a3.973 3.973 0 0 0-1.32-.313v-.93a.375.375 0 0 0-.375-.374.375.375 0 0 0-.374.375v.958c-.37.062-.69.2-.95.41-.382.307-.576.71-.576 1.197 0 .44.13.798.388 1.067.244.256.68.492 1.295.704.39.133.688.257.885.369.182.103.311.215.388.333.073.113.11.264.11.445 0 .297-.114.53-.347.713-.244.19-.586.287-1.016.287-.573 0-1.056-.072-1.445-.22l-.024-.007a.365.365 0 0 0-.455.251.37.37 0 0 0 .24.453l.023.01c.358.146.857.228 1.484.243v.925c0 .206.168.374.374.374a.375.375 0 0 0 .374-.374v-.967a2.12 2.12 0 0 0 .984-.418c.398-.317.6-.749.6-1.287 0-.294-.06-.55-.18-.762l.001.003Z"
      />
    </SvgIcon>
  )
}
export default SvgMoneyBackGuaranteeDollar
