import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgTelephone = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M16.12 20.325h-.011c-.82-.024-1.71-.218-2.724-.6-1.598-.6-3.065-1.495-4.485-2.732a19.112 19.112 0 0 1-3.733-4.453c-.64-1.047-1.051-2.037-1.258-3.025A6.507 6.507 0 0 1 3.81 7.48c.044-.42.126-.865.252-1.364.114-.457.325-.853.624-1.179.486-.527 1.128-.72 1.86-.558.52.116.953.38 1.317.618.9.59 1.635 1.327 2.18 2.192.253.401.364.789.34 1.183-.022.331-.134.611-.334.831a5.373 5.373 0 0 1-.318.316c-.095.092-.187.177-.27.268-.097.107-.22.248-.293.392-.057.112-.086.26.099.577a11.442 11.442 0 0 0 4.15 4.162c.326.192.346.203.67-.096a7.64 7.64 0 0 1 1.26-.939c.426-.254.842-.249 1.235.017 1.097.74 1.905 1.446 2.541 2.227.629.77.767 1.615.398 2.445-.248.558-.687 1-1.307 1.312-.571.289-1.217.424-2.09.437h-.006v.003ZM6.053 5.095c-.313 0-.572.12-.799.365a1.92 1.92 0 0 0-.444.843 9.243 9.243 0 0 0-.233 1.259 5.776 5.776 0 0 0 .086 1.793c.188.9.569 1.811 1.161 2.782a18.322 18.322 0 0 0 3.582 4.274c1.348 1.175 2.739 2.024 4.249 2.591.927.35 1.734.529 2.465.55.757-.014 1.28-.12 1.747-.355.462-.233.773-.54.949-.937.25-.561.155-1.1-.29-1.645-.587-.72-1.343-1.379-2.375-2.075-.125-.083-.223-.105-.41.007a6.86 6.86 0 0 0-1.134.845c-.563.518-.948.564-1.582.192a12.21 12.21 0 0 1-4.426-4.44c-.105-.18-.423-.726-.118-1.318.115-.224.28-.416.41-.559.1-.11.206-.21.308-.306.099-.094.192-.183.276-.275a.57.57 0 0 0 .135-.364c.015-.23-.056-.46-.221-.723a6.446 6.446 0 0 0-1.95-1.958c-.307-.202-.668-.423-1.062-.511a1.525 1.525 0 0 0-.327-.039l.003.004Z"
      />
    </SvgIcon>
  )
}
export default SvgTelephone
