import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgTimer2 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M19.06 12.01c0-4.086-3.324-7.41-7.41-7.41-4.087 0-7.411 3.324-7.411 7.41 0 2.555 1.3 4.812 3.271 6.145L6.174 19.75a.39.39 0 0 0 .596.5l1.416-1.69a7.361 7.361 0 0 0 3.463.862c1.25 0 2.478-.325 3.527-.894l1.443 1.722a.385.385 0 0 0 .549.048.388.388 0 0 0 .047-.548l-1.37-1.636a7.405 7.405 0 0 0 3.214-6.103h.001Zm-7.41 6.633a6.64 6.64 0 0 1-6.634-6.632 6.64 6.64 0 0 1 6.633-6.633 6.64 6.64 0 0 1 6.633 6.633 6.64 6.64 0 0 1-6.633 6.633Zm3.961-10.61a.389.389 0 0 1 0 .55l-3.687 3.687a.389.389 0 0 1-.665-.275v-5.21a.39.39 0 0 1 .778 0v4.27l3.022-3.022a.389.389 0 0 1 .55 0h.002Zm3.574-.244a.386.386 0 0 1-.536-.125 8.327 8.327 0 0 0-2.504-2.557.388.388 0 1 1 .425-.652 9.126 9.126 0 0 1 2.74 2.799.39.39 0 0 1-.125.536v-.001ZM4.34 7.813a.39.39 0 0 1-.33-.597A9.11 9.11 0 0 1 6.73 4.453a.388.388 0 1 1 .424.651A8.33 8.33 0 0 0 4.67 7.63a.39.39 0 0 1-.33.182l.001.001Z"
      />
    </SvgIcon>
  )
}
export default SvgTimer2
