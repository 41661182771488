import React from 'react'
import { StyledProductLabel, StyledProductLabelText } from './ProductLabel.style'

interface ProductLabelProps {
  label?: string
  badge?: string
}

const ProductLabel: React.FC<ProductLabelProps> = ({ label, badge }) => {
  return (
    <StyledProductLabel className={badge}>
      <StyledProductLabelText variant="body2">{label}</StyledProductLabelText>
    </StyledProductLabel>
  )
}

export default ProductLabel
