import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgUnhappySadFace = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M11.74 20.35c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Zm0-15.234c-3.99 0-7.235 3.245-7.235 7.235s3.245 7.235 7.235 7.235 7.235-3.245 7.235-7.235-3.245-7.235-7.235-7.235ZM8.764 16.83a3.454 3.454 0 0 1 5.958-.009.383.383 0 0 0 .66-.387 4.216 4.216 0 0 0-3.636-2.077c-1.492 0-2.887.8-3.642 2.087a.382.382 0 1 0 .66.386Zm.696-4.72a1.465 1.465 0 0 1-1.464-1.463c0-.807.657-1.464 1.464-1.464.807 0 1.464.657 1.464 1.464 0 .807-.657 1.464-1.464 1.464Zm0-2.162a.7.7 0 1 0 .001 1.4.7.7 0 0 0 0-1.4Zm4.588 2.163a1.465 1.465 0 0 1-1.463-1.464c0-.807.656-1.464 1.463-1.464.808 0 1.464.657 1.464 1.464 0 .807-.656 1.464-1.464 1.464Zm0-2.163a.7.7 0 1 0 .002 1.4.7.7 0 0 0-.002-1.4Z"
      />
    </SvgIcon>
  )
}
export default SvgUnhappySadFace
