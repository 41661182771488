import styled from '@mui/material/styles/styled'
import { LinkProps } from 'next/link'
import React from 'react'
import { Link } from '../../common/Link/Link'
import { cssButton, GenericButtonProps } from './legacy/Button'

export interface LinkAsButtonProps extends GenericButtonProps, LinkProps {
  dataElementId?: string
  href: LinkProps['href']
  external?: boolean
  children?: React.ReactNode
  target?: string
  isLocaleDomain: boolean
  locationOrigin: string
  locale: LinkProps['locale']
}

// __________________________________________________________________________________________________________________________________
// DO NOT USE! NEW VERSION OF LinkAsButton PLACED HERE: src\components\common\UI\LinkAsButton\index.tsx
// __________________________________________________________________________________________________________________________________

export const LinkAsButton = styled(
  ({
    external,
    href,
    startIcon,
    labelText,
    endIcon,
    children,
    dataElementId,
    locale,
    isLocaleDomain,
    locationOrigin,
    ...props
  }: LinkAsButtonProps) =>
    external ? (
      <a href={href as string} data-element-id={dataElementId} {...props}>
        {startIcon}
        {labelText || children}
        {endIcon}
      </a>
    ) : (
      <Link
        href={href}
        data-element-id={dataElementId}
        {...props}
        locale={locale}
        isLocaleDomain={isLocaleDomain}
        locationOrigin={locationOrigin}
      >
        {startIcon}
        {labelText || children}
        {endIcon}
      </Link>
    ),
  {
    shouldForwardProp: prop => prop !== 'fillType' && prop !== 'fullWidth',
  }
)(cssButton)
