import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgPuttingInLenses = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M9.934 17.144c-.331 0-.69-.107-.978-.305-.398-.273-4.372-3.024-4.412-3.052a.382.382 0 1 1 .434-.628c.04.028 4.013 2.778 4.411 3.051.22.15.547.214.729.141.072-.028.093-.068.104-.121.092-.43-.074-.941-.493-1.518-.116-.161-.303-.32-.519-.503-.399-.339-.897-.762-1.284-1.48-.31-.574.051-1.181.094-1.249a.374.374 0 0 1 .062-.075l.325-.308c.689-.657 1.104-1.053 1.77-1.053.629 0 3.16 1.154 3.92 1.508.04.019.078.045.11.077l.484.482a.365.365 0 0 1 .034.039l1.556 2.04.007.01c.674.95 1.935.97 2.498.857l-2.334-3.49-4.09-4.082-7.335-1.424a.382.382 0 0 1 .145-.75l7.45 1.447a.388.388 0 0 1 .197.104l4.199 4.19a.41.41 0 0 1 .048.059l2.57 3.843a.243.243 0 0 1 .018.028c.1.182.079.459-.206.636-.652.41-2.752.467-3.777-.97l-1.536-2.015-.419-.418c-1.468-.68-3.22-1.404-3.537-1.409-.343 0-.587.217-1.244.843l-.286.272c-.05.098-.124.307-.05.446.32.59.737.946 1.106 1.26.244.206.473.401.644.637.55.759.76 1.474.621 2.124a.905.905 0 0 1-.568.67c-.14.056-.3.084-.466.084l-.002.002Zm10.348 1.323a.381.381 0 0 0 .094-.297c-.143-1.215-1.111-2.03-2.41-2.03-1.3 0-2.269.815-2.412 2.03a.38.38 0 0 0 .38.425h4.063c.109 0 .212-.047.285-.128Zm-2.317-1.564c.74 0 1.306.353 1.544.93h-3.087c.238-.577.805-.93 1.544-.93Zm-4.92-.597 1.184-1.957a.382.382 0 0 0 .055-.197v-1.077a.382.382 0 0 0-.763 0v.97l-.983 1.624c-.239-.072-.447-.175-.55-.356-.103-.179-.1-.443.01-.783.149-.466.632-.864.636-.867a.38.38 0 0 0 .105-.46l-.751-1.585a.381.381 0 1 0-.69.327l.633 1.332c-.213.217-.52.585-.66 1.02-.177.553-.159 1.022.056 1.395.328.572 1.016.723 1.31.789a.381.381 0 0 0 .409-.175Z"
      />
    </SvgIcon>
  )
}
export default SvgPuttingInLenses
