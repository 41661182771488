import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgNonVisible = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="m16.19 8.848 4.075-4.074a.387.387 0 1 0-.548-.548l-4.321 4.321a9.077 9.077 0 0 0-2.862-.463c-4.528 0-7.801 3.34-7.939 3.481a.774.774 0 0 0 .01 1.085c1.253 1.253 2.571 2.169 3.944 2.744l-4.038 4.038a.387.387 0 0 0 .275.662c.1 0 .198-.038.274-.114l4.29-4.29a9.061 9.061 0 0 0 2.768.432c4.528 0 7.802-3.34 7.939-3.481a.774.774 0 0 0-.01-1.086c-1.226-1.225-2.515-2.13-3.855-2.707h-.002ZM5.153 12.103s3.13-3.245 7.381-3.245c.718 0 1.47.093 2.241.31l-.825.825a2.65 2.65 0 0 0-1.624-.554 2.667 2.667 0 0 0-2.664 2.664c0 .61.207 1.174.554 1.624l-1.07 1.07c-1.294-.488-2.639-1.34-3.993-2.694Zm9.063 0c0 1.042-.848 1.89-1.89 1.89-.365 0-.705-.107-.993-.286l2.597-2.598c.18.29.286.63.286.994Zm-3.78 0c0-1.042.848-1.89 1.89-1.89.397 0 .766.124 1.07.335l-.228.227a1.576 1.576 0 0 0-1.813.295 1.562 1.562 0 0 0-.295 1.813l-.29.29a1.876 1.876 0 0 1-.333-1.07Zm1.239.167a.794.794 0 0 1 .879-.88l-.878.879-.002.001Zm.443 3.079a8.232 8.232 0 0 1-2.143-.284l.799-.798c.437.315.974.5 1.552.5a2.667 2.667 0 0 0 2.664-2.663c0-.579-.186-1.115-.501-1.552l1.107-1.107c1.266.494 2.58 1.337 3.903 2.659 0 0-3.13 3.245-7.38 3.245Z"
      />
    </SvgIcon>
  )
}
export default SvgNonVisible
