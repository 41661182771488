import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCashInHandPound = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M12.911 9.407V8.85h-.185a.33.33 0 1 1 0-.661h.185v-.294c0-.404.106-.729.315-.966.217-.245.519-.37.897-.374h.025a1.314 1.314 0 0 1 1.233.93.33.33 0 1 1-.632.19.65.65 0 0 0-.613-.46c-.189 0-.325.051-.414.151-.1.113-.15.291-.15.529v.294h.729a.33.33 0 1 1 0 .66h-.73v.558c0 .17-.024.326-.074.466h1.553a.33.33 0 1 1 0 .66h-2.361a.33.33 0 0 1-.33-.33c0-.159.112-.298.268-.328a.285.285 0 0 0 .197-.125.594.594 0 0 0 .087-.343Zm6.58 5.155a.31.31 0 0 1-.055.065l-2.641 2.582a.399.399 0 0 1-.046.039c-1.018.723-2.193.854-2.908.854-.38 0-.63-.036-.658-.04-3.124-.476-5.225-.08-5.246-.076a.397.397 0 0 1-.08.006l-1.327-.02c-.194.47-.656.802-1.196.802H3.995a.386.386 0 0 1-.385-.385v-4.484c0-.213.173-.385.385-.385h1.34c.521 0 .97.309 1.174.754a4.832 4.832 0 0 1 3.017-1.35c.039-.003 1.024-.075 2.45.57.021.01.041.021.06.034.003.002.585.382 1.67.424l.836-.027h.02c.645 0 1.17.525 1.17 1.17 0 .646-.525 1.17-1.17 1.17l-.432.008c-1.691 0-3.446-.513-3.52-.535a.387.387 0 0 1-.261-.479.387.387 0 0 1 .479-.26c.018.005 1.725.502 3.296.502l.431-.007c.227 0 .406-.18.406-.4a.399.399 0 0 0-.39-.4l-.156.008-.699.021h-.025c-1.19-.043-1.896-.437-2.058-.538-1.212-.543-2.038-.494-2.046-.494-1.944.149-2.897 1.534-2.906 1.547a.42.42 0 0 1-.053.063v1.9l1.204.02c.326-.059 2.408-.388 5.469.078.018.002 1.685.24 2.978-.663l2.56-2.5c-.003-.033-.028-.075-.072-.12-.083-.06-.555-.374-.973-.081a.374.374 0 0 1-.075.04s-.204.09-.455.318c-.098.088-.23.215-.391.377l-.188.192a.386.386 0 0 1-.552-.539 15.434 15.434 0 0 1 .614-.603c.289-.261.537-.392.637-.44.917-.613 1.839.11 1.878.142a.242.242 0 0 1 .026.021c.453.436.333.92.206 1.116l-.004.003Zm-13.635.25a.522.522 0 0 0-.522-.523H4.38v3.713h.953a.522.522 0 0 0 .522-.522v-2.669h.001Zm4.369-6.115A3.811 3.811 0 0 1 14.03 4.89a3.811 3.811 0 0 1 3.807 3.807 3.811 3.811 0 0 1-3.807 3.806 3.811 3.811 0 0 1-3.806-3.806Zm.77 0a3.04 3.04 0 0 0 3.036 3.035 3.04 3.04 0 0 0 3.036-3.035A3.04 3.04 0 0 0 14.03 5.66a3.04 3.04 0 0 0-3.036 3.036Z"
      />
    </SvgIcon>
  )
}
export default SvgCashInHandPound
