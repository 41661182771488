import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSleeping = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M16.093 8.736a.392.392 0 0 1-.14-.026l-2.192-.857a.383.383 0 0 1-.217-.498l.116-.298a.385.385 0 0 1 .15-.183l2.476-1.588-1.67-.653a.384.384 0 0 1 .278-.716l2.193.857a.385.385 0 0 1 .217.498l-.097.248a.385.385 0 0 1-.15.184l-2.53 1.623 1.707.667a.384.384 0 0 1-.14.742h-.001Zm3.484 2.069a.384.384 0 0 0-.218-.499l-1.138-.446 1.725-1.027a.384.384 0 0 0 .161-.19l.07-.18a.385.385 0 0 0-.217-.498L18.258 7.3a.385.385 0 0 0-.281.716l1.111.434-1.682 1.003a.385.385 0 0 0-.161.19l-.085.216c-.077.198.021.42.218.497l1.701.667a.383.383 0 0 0 .498-.219v.002Zm.12 4.173a.384.384 0 0 0-.218-.499l-.788-.308 1.273-.76a.384.384 0 0 0 .162-.19l.057-.146a.384.384 0 0 0-.219-.497l-1.382-.542a.384.384 0 0 0-.28.715l.765.3-1.24.739a.385.385 0 0 0-.16.19l-.069.175c-.077.198.021.42.218.498l1.382.541a.384.384 0 0 0 .498-.217Zm-3.023.937v2.873a.385.385 0 0 1-.384.384h-.948v.335a.385.385 0 0 1-.769 0v-.335H6.32v.335a.385.385 0 0 1-.768 0v-.335h-.948a.385.385 0 0 1-.384-.384V15.87c0-.646.415-1.196.991-1.402v-1.767c0-.822.67-1.49 1.49-1.49h7.448c.845 0 1.533.688 1.533 1.534v1.737c.579.22.992.778.992 1.432ZM5.98 12.702v1.68h.897a.405.405 0 0 1-.012-.09v-.22c0-.296.115-1.265 1.595-1.265s1.595.968 1.595 1.265v.22c0 .03-.004.06-.012.09h.807a.404.404 0 0 1-.012-.09v-.22c0-.296.116-1.265 1.596-1.265s1.595.968 1.595 1.265v.22c0 .03-.005.06-.012.09h.897v-1.636a.767.767 0 0 0-.765-.766H6.7a.723.723 0 0 0-.721.722Zm7.28 1.59v-.22c0-.123 0-.496-.826-.496-.827 0-.827.373-.827.496v.22c0 .03-.005.06-.012.09h1.676a.404.404 0 0 1-.012-.09h.001Zm-3.973 0v-.22c0-.123 0-.496-.827-.496s-.827.373-.827.496v.22c0 .03-.004.06-.012.09h1.677a.405.405 0 0 1-.012-.09Zm6.618 1.623a.767.767 0 0 0-.765-.765H5.71a.723.723 0 0 0-.721.721v2.532h10.915v-2.488h.001Z"
      />
    </SvgIcon>
  )
}
export default SvgSleeping
