import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const Svg3 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M9.213 14.499a.46.46 0 0 1-.138-.296.419.419 0 0 1 .717-.285c.538.612 1.171.94 2.004.94.897 0 1.614-.57 1.614-1.435v-.021c0-.917-.855-1.424-2.067-1.424h-.264c-.201 0-.37-.158-.37-.359 0-.105.052-.21.169-.327l2.15-2.437H9.76c-.211 0-.39-.157-.39-.37 0-.211.18-.379.39-.379h4.028c.232 0 .4.148.4.359 0 .168-.084.284-.2.411l-2.172 2.415c1.286.105 2.436.727 2.436 2.076v.022c0 1.318-1.097 2.224-2.467 2.224-1.13 0-1.983-.453-2.573-1.118v.004ZM19.93 11.99a.389.389 0 0 0-.777 0c0 3.983-3.24 7.223-7.223 7.223-3.983 0-7.223-3.24-7.223-7.223 0-3.983 3.24-7.223 7.223-7.223a.389.389 0 0 0 0-.777c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8Z"
      />
    </SvgIcon>
  )
}
export default Svg3
