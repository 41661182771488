import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCompatibleWithCurrentLens = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M11.785 6.345c0-.212.173-.385.386-.385 2.83 0 5.115.698 6.268 1.917a.385.385 0 0 1-.281.651.387.387 0 0 1-.28-.12c-.993-1.049-3.128-1.676-5.709-1.676a.386.386 0 0 1-.385-.386Zm8.3 1.952c0 2.01-3.721 5.335-7.962 5.335-4.24 0-7.96-3.325-7.96-5.335l.001-.027c0-.03-.004-.059-.004-.09 0-2.103 3.497-3.75 7.962-3.75s7.962 1.647 7.962 3.75c0 .022-.002.043-.003.064.002.018.005.035.005.053ZM4.931 8.18c0 1.438 2.89 2.979 7.191 2.979s7.19-1.54 7.19-2.979c0-1.438-2.889-2.979-7.19-2.979s-7.19 1.541-7.19 2.98Zm7.192 4.68c2.046 0 3.85-.815 5.138-1.795-1.378.542-3.164.865-5.14.865-1.976 0-3.755-.322-5.131-.861 1.288.978 3.089 1.791 5.133 1.791Zm7.844.169a.385.385 0 1 0-.54-.55c-1.292 1.27-3.992 2.754-7.35 2.754-3.358 0-5.965-1.44-7.266-2.674a.386.386 0 0 0-.53.56c1.47 1.395 4.29 2.885 7.795 2.885s6.494-1.603 7.89-2.975h.001Zm0 2.213a.385.385 0 1 0-.54-.55c-1.292 1.27-3.992 2.754-7.35 2.754-3.282 0-5.965-1.44-7.266-2.674a.386.386 0 0 0-.53.56c1.47 1.395 4.29 2.885 7.795 2.885 3.59 0 6.494-1.603 7.89-2.975h.001Zm0 2.213a.385.385 0 1 0-.54-.55c-1.292 1.27-3.992 2.754-7.35 2.754-3.282 0-5.965-1.44-7.266-2.674a.386.386 0 0 0-.53.56c1.47 1.395 4.29 2.885 7.795 2.885 3.59 0 6.494-1.603 7.89-2.975h.001Z"
      />
    </SvgIcon>
  )
}
export default SvgCompatibleWithCurrentLens
