import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const Svg12HrsPlusNlBeNl = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M20.129 12.344a8.007 8.007 0 0 1-3.042 5.872 7.968 7.968 0 0 1-4.945 1.714 7.999 7.999 0 0 1-6.298-3.049C3.112 13.413 3.712 8.371 7.18 5.638a8.028 8.028 0 0 1 9.4-.37l-.15-.918a.388.388 0 0 1 .542-.418.42.42 0 0 1 .168.135c.037.05.061.107.072.167l.295 1.816a.446.446 0 0 1-.078.318.39.39 0 0 1-.252.156l-1.821.301a.387.387 0 0 1-.429-.252.44.44 0 0 1-.024-.11l-.003-.02a.389.389 0 0 1 .325-.424l.867-.142a7.247 7.247 0 0 0-8.43.372c-3.13 2.466-3.671 7.02-1.206 10.151 2.466 3.131 7.02 3.673 10.151 1.206a7.236 7.236 0 0 0 2.747-5.302.389.389 0 0 1 .776.04h-.001ZM9.153 11.56h.902V7.986h-.658l-.898.263.127.755.527-.136V11.56Zm1.354 0h2.453v-.786h-1.292l.593-.552c.466-.43.69-.72.69-1.196v-.015c0-.639-.487-1.06-1.177-1.06-.552 0-.927.224-1.287.644l.542.578c.253-.269.431-.4.649-.4.217 0 .36.136.36.339s-.111.36-.401.644l-1.13 1.085v.72-.002Zm3.56-.524h.743v-.745h.731V9.57h-.73v-.745h-.745v.745h-.73v.722h.73v.745Zm-4.651 4.69c.328 0 .54-.193.686-.4v.342h.85v-2.614h-.85v1.542c0 .24-.13.372-.309.372s-.304-.13-.304-.372v-1.542h-.845v1.803c0 .532.28.87.773.87h-.001Zm2.71 0c.328 0 .54-.193.686-.4v.342h.85v-2.614h-.85v1.542c0 .24-.13.372-.31.372-.178 0-.303-.13-.303-.372v-1.542h-.846v1.803c0 .532.28.87.773.87Zm1.95-.058h.851v-.956c0-.526.227-.768.638-.768h.057v-.933c-.362-.019-.57.189-.695.518v-.474h-.85v2.615-.002Z"
      />
    </SvgIcon>
  )
}
export default Svg12HrsPlusNlBeNl
