import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgGiftReward = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M19.945 9.002c0-.542-.5-.983-1.115-.983h-3.22a2.35 2.35 0 0 0 .316-.228c1.194-1.028 1.581-2.471.882-3.284-.7-.812-2.184-.644-3.379.385-.423.364-.823 1.035-1.19 1.994-.08.205-.144.384-.197.54-.212-.789-.673-2.048-1.444-2.59-1.192-.838-2.61-.807-3.226.07-.617.879-.166 2.222 1.026 3.06.024.018.05.034.077.05h-3.37c-.616 0-1.115.441-1.115.984v3.016c0 .214.174.389.388.389H5.1v6.605c0 .543.5.983 1.114.983h11.507c.614 0 1.114-.44 1.114-.983v-6.605h.751a.388.388 0 0 0 .389-.393l-.03-3.013v.003Zm-6.01-3.52c.822-.707 1.888-.926 2.284-.468.395.459.02 1.481-.8 2.19-.634.545-2.01.712-2.722.743.205-.683.679-1.984 1.238-2.465Zm-5.928-.127c.163-.232.458-.345.81-.345.411 0 .897.156 1.333.463.617.434 1.03 1.676 1.188 2.32h-.013c-.676 0-1.954-.09-2.48-.461-.808-.567-1.191-1.473-.837-1.977h-.001ZM4.768 9.002c0-.097.144-.206.338-.206h5.522l-1.1.57a.388.388 0 1 0 .36.69l1.652-.858v2.432H4.77V9.002h-.002Zm1.11 10.01v-6.605h5.661v6.812H6.215c-.193 0-.337-.109-.337-.207Zm12.568-7.382a.389.389 0 0 0-.388.388v6.994c0 .098-.144.207-.337.207h-5.406v-6.812h3.559a.389.389 0 0 0 0-.777h-3.56V9.176l1.498.719a.388.388 0 1 0 .336-.7l-.829-.399h5.513c.193 0 .337.109.337.21l.026 2.624h-.749Z"
      />
    </SvgIcon>
  )
}
export default SvgGiftReward
