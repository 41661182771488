import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgDelivery = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M19.637 10.52a.844.844 0 0 0-.012-.014l-1.735-1.9a1.628 1.628 0 0 0-1.067-.469h-.864l.11-1.296a.762.762 0 0 0-.706-.839h-.012A.435.435 0 0 0 15.306 6H6.818a.877.877 0 0 0-.851.785L5.87 7.912a.281.281 0 0 0 .56.048l.088-1.024.008-.095a.311.311 0 0 1 .297-.277h8.493l.025.001a.2.2 0 0 1 .167.223l-.137 1.607a.282.282 0 0 0 .28.306h1.15c.255.014.495.117.68.293l1.721 1.885c.16.203.242.455.232.705l-.23 3.454a.309.309 0 0 1-.294.273h-.873a1.605 1.605 0 0 0-1.55-1.095c-.859.014-1.586.599-1.806 1.402h-4.226a1.606 1.606 0 0 0-1.62-1.402 1.909 1.909 0 0 0-1.693 1.095h-1.06a.2.2 0 0 1-.192-.22l.001-.016.081-1.74a.281.281 0 0 0-.562-.027l-.08 1.733a.763.763 0 0 0 .707.83h.013l.045.003h.883l-.008.056a1.605 1.605 0 0 0 1.601 1.776h.024a1.911 1.911 0 0 0 1.837-1.524h4.185a1.605 1.605 0 0 0 1.606 1.524h.023a1.91 1.91 0 0 0 1.868-1.715c.004-.038.005-.077.006-.116h.797a.873.873 0 0 0 .85-.793l.23-3.466a1.647 1.647 0 0 0-.358-1.092l-.003-.004Zm-9.738 5.41a1.348 1.348 0 0 1-1.45 1.205 1.042 1.042 0 0 1-.922-1.148 1.346 1.346 0 0 1 1.316-1.21h.028a1.042 1.042 0 0 1 1.026 1.153H9.9Zm7.653 0a1.348 1.348 0 0 1-1.45 1.205 1.042 1.042 0 0 1-.922-1.148 1.346 1.346 0 0 1 1.316-1.21H16.524a1.042 1.042 0 0 1 1.026 1.153h.002Zm-8.815-3.583h-3.83a.282.282 0 0 1 0-.563h3.83a.282.282 0 0 1 0 .563ZM5.163 9.308c0-.155.126-.281.281-.281h4.113a.282.282 0 0 1 0 .563H5.444a.282.282 0 0 1-.281-.282Zm2.544 1.66H4.281a.282.282 0 0 1 0-.563h3.425a.282.282 0 0 1 0 .563h.001Zm8.152-.002c0 .005 0 .01-.002.016.012.018.064.06.162.07H18.407a.282.282 0 0 1 0 .563h-2.402l-.022-.002a.794.794 0 0 1-.563-.277.555.555 0 0 1-.123-.416l.08-1.258a.282.282 0 0 1 .562.036l-.08 1.268Z"
      />
    </SvgIcon>
  )
}
export default SvgDelivery
