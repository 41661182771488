import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgEyeDrops = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="m18.536 6.814-2.693-2.693a1.329 1.329 0 0 0-.932-.391c-.345 0-.632.125-.859.351l-3.044 3.045a2.594 2.594 0 0 0-.753 1.712.448.448 0 0 0-.05.042l-.94.94a.387.387 0 0 0-.113.273c0 .085.03.166.08.233l-.932.933-.027.028c-.023.03-.57.71-.492 1.573.022.24-.292.646-.51.845a.388.388 0 0 0-.014.562l1.129 1.123.01.008.004.005.02.014c.011.01.022.019.036.028l.023.012a.346.346 0 0 0 .042.02l.02.005a.387.387 0 0 0 .196.011h.001a.42.42 0 0 0 .072-.023c.007-.002.012-.007.018-.01.017-.009.034-.018.05-.029.008-.005.015-.013.024-.02.01-.008.02-.015.03-.025l.008-.008.006-.005c.199-.219.603-.534.845-.51.86.078 1.544-.47 1.572-.493a.216.216 0 0 0 .029-.027l.93-.929a.383.383 0 0 0 .514-.025l.939-.94a.308.308 0 0 0 .042-.05 2.59 2.59 0 0 0 1.713-.752l2.396-2.396.648-.648c.483-.483.464-1.286-.04-1.79l.002.001Zm-7.672 6.967c-.079.058-.508.359-1 .313-.464-.042-.912.247-1.196.484l-.598-.595c.236-.284.525-.732.482-1.194-.046-.493.257-.926.312-1l.912-.911 1.995 1.995-.908.908h.001Zm4.483-3.041-1.586-1.587 1.76-1.76 1.586 1.587-1.76 1.76Zm2.682-2.683-.375.376-1.86-1.86a.386.386 0 0 0-.548 0L12.94 8.878a.386.386 0 0 0 0 .548l1.846 1.845c-.354.262-.799.39-1.26.355l-1.437-1.437a.386.386 0 0 0-.274-.112h-.013c-.1 0-.198.039-.27.11a.399.399 0 0 0-.004.564l1.428 1.428-.392.392-2.477-2.476.401-.402a.386.386 0 0 0 .572.033c.218-.213.097-.432-.027-.582a1.853 1.853 0 0 1 .523-1.471L14.6 4.629a.436.436 0 0 1 .312-.125c.14 0 .28.06.385.165l2.694 2.692c.203.204.22.516.04.697h-.002ZM7.545 16.04A.34.34 0 0 0 7 16.037l-.927 1.276a1.544 1.544 0 0 0-.15 1.553 1.456 1.456 0 0 0 1.35.864 1.451 1.451 0 0 0 1.349-.864 1.547 1.547 0 0 0-.15-1.553l-.927-1.274Zm.465 2.546a.789.789 0 0 1-.656.469c-.054.004-.11.004-.163 0a.789.789 0 0 1-.657-.468.874.874 0 0 1 .083-.878l.655-.901.656.901a.874.874 0 0 1 .082.878Z"
      />
    </SvgIcon>
  )
}
export default SvgEyeDrops
