import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgBlog = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M18.632 18.7H5.768A1.57 1.57 0 0 1 4.2 17.134V6.608A1.57 1.57 0 0 1 5.768 5.04h12.864A1.57 1.57 0 0 1 20.2 6.608v10.525a1.57 1.57 0 0 1-1.568 1.568ZM4.998 8.14v8.994c0 .425.346.771.771.771h12.864a.772.772 0 0 0 .771-.771V8.14H4.998Zm0-.772h14.406V6.61a.772.772 0 0 0-.77-.771H5.767a.772.772 0 0 0-.771.771v.76Zm12.465 8.806H6.938a.398.398 0 0 1 0-.796h10.525a.398.398 0 0 1 0 .796Zm0-2.631h-4.678a.398.398 0 0 1 0-.796h4.678a.398.398 0 0 1 0 .796Zm0-2.632h-4.678a.398.398 0 0 1 0-.795h4.678a.398.398 0 0 1 0 .795ZM9.277 13.91h-1.17a1.57 1.57 0 0 1-1.568-1.568v-1.169a1.57 1.57 0 0 1 1.568-1.568h1.17a1.57 1.57 0 0 1 1.568 1.568v1.17a1.57 1.57 0 0 1-1.568 1.567Zm-1.17-3.532a.798.798 0 0 0-.796.796v1.17c0 .438.357.796.796.796h1.17a.798.798 0 0 0 .796-.796v-1.17a.798.798 0 0 0-.796-.796h-1.17Z"
      />
    </SvgIcon>
  )
}
export default SvgBlog
