import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgAuthorityFigure = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M12.038 19.593a.623.623 0 0 1-.301-.077c-1.729-.943-3.515-1.917-4.853-3.818C5.39 13.576 4.69 10.674 4.68 6.565a.632.632 0 0 1 .422-.593l6.682-2.334a.64.64 0 0 1 .151-.039l.037-.003a.6.6 0 0 1 .246.041l6.7 2.304a.633.633 0 0 1 .426.589c.01 4.113-.677 7.018-2.161 9.145-1.329 1.904-3.108 2.886-4.829 3.835l-.012.007a.634.634 0 0 1-.305.079l.001-.003ZM5.457 6.668c.02 3.832.694 6.64 2.062 8.582 1.217 1.73 2.82 2.618 4.52 3.546 1.698-.937 3.299-1.832 4.51-3.567 1.357-1.945 2.019-4.756 2.022-8.59l-6.565-2.257-6.548 2.287h-.001Zm4.787 5.874h-.026l-.04-.003a.576.576 0 0 1-.385-.214.556.556 0 0 1-.116-.413l.202-1.568-1.15-1.156a.55.55 0 0 1-.08-.678.577.577 0 0 1 .363-.26l.016-.004 1.674-.31.818-1.394a.572.572 0 0 1 .462-.282h.003a.571.571 0 0 1 .53.282l.819 1.389 1.675.302a.562.562 0 0 1 .467.514.557.557 0 0 1-.146.417l-.009.009-1.148 1.165.209 1.56a.543.543 0 0 1-.055.32.577.577 0 0 1-.75.265l-1.551-.673-1.544.681a.573.573 0 0 1-.239.051h.001Zm-.074-.76-.005.002.005-.002Zm3.716-.01h.003-.003ZM9.571 8.935l.991.998a.389.389 0 0 1 .11.323l-.178 1.384 1.376-.607a.381.381 0 0 1 .311 0l1.383.601-.185-1.379a.39.39 0 0 1 .109-.323l.989-1.004-1.455-.263a.387.387 0 0 1-.265-.185l-.739-1.252-.737 1.255a.383.383 0 0 1-.263.185l-1.447.269v-.002Zm2.276-2v.003-.002Zm.467 10.38c1.216-.671 2.47-1.477 3.406-2.817a.386.386 0 0 0-.096-.54.387.387 0 0 0-.54.096c-.845 1.211-2.01 1.957-3.144 2.582a.388.388 0 1 0 .375.68v-.002Z"
      />
    </SvgIcon>
  )
}
export default SvgAuthorityFigure
