import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgLunchPlate = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M12.392 16.876a4.59 4.59 0 0 1-4.586-4.586 4.59 4.59 0 0 1 4.586-4.585.386.386 0 0 1 0 .772 3.818 3.818 0 0 0-3.814 3.813 3.818 3.818 0 0 0 3.814 3.814c1.328 0 2.54-.674 3.242-1.805a.386.386 0 0 1 .656.407 4.56 4.56 0 0 1-3.898 2.17Zm2.138-8.22a.385.385 0 0 0-.184-.514 4.27 4.27 0 0 0-.437-.179.386.386 0 1 0-.255.728c.122.043.245.092.362.148a.386.386 0 0 0 .514-.184Zm5.389 9.62a1.27 1.27 0 0 1-1.268 1.322 1.268 1.268 0 0 1-1.27-1.321l.063-1.682a6.628 6.628 0 0 1-5.053 2.339 6.597 6.597 0 0 1-5.025-2.307l.041 1.725a1.3 1.3 0 0 1-.537 1.089 1.258 1.258 0 0 1-1.474 0l-.028-.021a1.301 1.301 0 0 1-.511-1.068l.186-7.607a.535.535 0 0 0-.145-.38l-.62-.652a1.301 1.301 0 0 1-.358-.899V5.13a.386.386 0 0 1 .772 0v3.685a.53.53 0 0 0 .145.367l.62.652c.235.248.365.587.358.93l-.187 7.607a.528.528 0 0 0 .218.444l.005.004a.49.49 0 0 0 .568-.005c.14-.1.221-.266.217-.443l-.186-7.606a1.309 1.309 0 0 1 .358-.931l.62-.652a.53.53 0 0 0 .145-.367V5.13a.386.386 0 0 1 .771 0v1.898a6.619 6.619 0 0 1 8.618.45 4.617 4.617 0 0 1 1.757-2.713.631.631 0 0 1 .662-.056c.216.11.35.327.35.569v8.214l.187 4.784Zm-2.423-3.091.062-1.647a.5.5 0 0 0-.127-.354l-.257-.29a1.274 1.274 0 0 1-.32-.846V8.481a5.857 5.857 0 0 0-8.509-.437v.77c0 .337-.126.657-.358.9l-.62.65a.535.535 0 0 0-.145.381l.11 4.52a5.87 5.87 0 0 0 5.06 2.897c2.154 0 4.095-1.201 5.105-2.977Zm1.651 3.12L18.96 13.5v-7.94a3.847 3.847 0 0 0-1.335 2.91v3.579c0 .123.045.242.127.335l.257.29c.219.247.332.564.32.894l-.176 4.738a.501.501 0 0 0 .212.43.495.495 0 0 0 .572 0 .503.503 0 0 0 .211-.43ZM5.473 7.915a.386.386 0 0 0 .386-.385v-2.4a.386.386 0 0 0-.772 0v2.4c0 .213.173.386.386.386Zm1.278 0a.386.386 0 0 0 .385-.385v-2.4a.386.386 0 0 0-.771 0v2.4c0 .213.173.386.386.386Z"
      />
    </SvgIcon>
  )
}
export default SvgLunchPlate
