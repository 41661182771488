import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgLensHolder2 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M6.338 16.014a.396.396 0 0 1-.384-.492c.52-2.046 2.547-4.086 5.043-5.076 1.5-.595 4.072-1.955 4.446-4.383a.395.395 0 0 1 .781.12c-.433 2.817-3.278 4.34-4.935 4.997-2.235.886-4.113 2.752-4.567 4.536a.396.396 0 0 1-.383.298ZM5.27 14.266c.454-1.784 2.333-3.648 4.567-4.536 1.658-.657 4.502-2.18 4.936-4.997a.395.395 0 0 0-.782-.12c-.373 2.429-2.946 3.788-4.446 4.383-2.496.99-4.521 3.03-5.043 5.076a.394.394 0 0 0 .384.492.395.395 0 0 0 .383-.298Zm2.871 2.871c.454-1.784 2.333-3.649 4.568-4.535 1.657-.658 4.501-2.18 4.935-4.998a.395.395 0 0 0-.781-.12c-.374 2.43-2.946 3.789-4.447 4.384-2.495.989-4.52 3.03-5.043 5.075a.394.394 0 0 0 .384.492.395.395 0 0 0 .383-.298h.001Zm1.36 1.361c.455-1.784 2.334-3.648 4.568-4.536 1.658-.657 4.502-2.18 4.936-4.997a.395.395 0 0 0-.782-.12c-.373 2.429-2.946 3.79-4.446 4.383-2.496.99-4.521 3.03-5.043 5.076a.394.394 0 0 0 .384.492.395.395 0 0 0 .383-.298Zm1.482 1.481c.454-1.784 2.333-3.648 4.568-4.535 1.657-.658 4.501-2.18 4.935-4.998a.395.395 0 0 0-.781-.12c-.374 2.43-2.946 3.79-4.447 4.384-2.495.989-4.52 3.03-5.042 5.075a.394.394 0 0 0 .383.492.395.395 0 0 0 .383-.298h.001Z"
      />
    </SvgIcon>
  )
}
export default SvgLensHolder2
