import * as Icons from '@components/UI/Icons/VD/General'
import { IconNotFound } from '@components/UI/Icons/VD/Cms'

export const getIcon = (iconName: string) => {
  const icon = Icons[iconName]

  if (!icon) {
    return IconNotFound
  }

  return icon
}
