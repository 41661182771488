import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSelectionFocus = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <circle cx={12} cy={12} r={8} fill="currentColor" />
    </SvgIcon>
  )
}
export default SvgSelectionFocus
