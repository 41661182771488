import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgChevronLeft = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M16.022 3.922a.387.387 0 0 1 .275.662l-7.338 7.338 7.338 7.338a.387.387 0 1 1-.55.548l-7.61-7.61a.388.388 0 0 1 0-.55l7.61-7.611a.388.388 0 0 1 .276-.113l-.001-.002Z"
      />
    </SvgIcon>
  )
}
export default SvgChevronLeft
