import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCalculator = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M16.026 8.486H8.343a.388.388 0 0 1-.388-.388v-1.94c0-.214.174-.388.388-.388h7.683c.214 0 .388.174.388.388v1.94a.388.388 0 0 1-.388.388ZM8.73 7.71h6.909V6.545H8.73v1.166ZM16.683 19.8H7.686c-.88 0-1.596-.719-1.596-1.602V5.401A1.6 1.6 0 0 1 7.686 3.8h8.996a1.6 1.6 0 0 1 1.596 1.601v12.797a1.6 1.6 0 0 1-1.596 1.602h.001ZM7.686 4.576a.825.825 0 0 0-.82.827V18.2c0 .455.367.826.82.826h8.996c.453 0 .82-.371.82-.826V5.4a.824.824 0 0 0-.82-.826H7.686v.001Zm8.728 12.512a.388.388 0 0 0-.388-.388H14.79a.388.388 0 0 0 0 .775h1.237a.388.388 0 0 0 .388-.387Zm-3.223 0a.388.388 0 0 0-.387-.388h-1.237a.388.388 0 0 0 0 .775h1.237a.388.388 0 0 0 .387-.387Zm-3.223 0a.388.388 0 0 0-.387-.388H8.343a.388.388 0 0 0 0 .775H9.58a.388.388 0 0 0 .387-.387Zm6.446-2.174a.388.388 0 0 0-.388-.388H14.79a.388.388 0 0 0 0 .775h1.237a.388.388 0 0 0 .388-.387Zm-3.223 0a.388.388 0 0 0-.387-.388h-1.237a.388.388 0 0 0 0 .775h1.237a.388.388 0 0 0 .387-.387Zm-3.223 0a.388.388 0 0 0-.387-.388H8.343a.388.388 0 0 0 0 .775H9.58a.388.388 0 0 0 .387-.387Zm6.446-2.174a.388.388 0 0 0-.388-.388H14.79a.388.388 0 0 0 0 .775h1.237a.388.388 0 0 0 .388-.387Zm-3.223 0a.388.388 0 0 0-.387-.388h-1.237a.388.388 0 0 0 0 .775h1.237a.388.388 0 0 0 .387-.387Zm-3.223 0a.388.388 0 0 0-.387-.388H8.343a.388.388 0 0 0 0 .775H9.58a.388.388 0 0 0 .387-.387Zm6.446-2.174a.388.388 0 0 0-.388-.388H14.79a.388.388 0 0 0 0 .775h1.237a.388.388 0 0 0 .388-.387Zm-3.223 0a.388.388 0 0 0-.387-.388h-1.237a.388.388 0 0 0 0 .775h1.237a.388.388 0 0 0 .387-.387Zm-3.223 0a.388.388 0 0 0-.387-.388H8.343a.388.388 0 0 0 0 .775H9.58a.388.388 0 0 0 .387-.387Z"
      />
    </SvgIcon>
  )
}
export default SvgCalculator
