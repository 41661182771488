import { StyledTypography } from '@components/UI/Typography'
import styled from '@mui/material/styles/styled'
import { ICMAlgolia } from '@typesApp/cmsPlacement/CMAlgolia'
import { cmsModuleLateralMargin, teaserBackGroundColorToBg } from '@utils/placements'
import React from 'react'

const WrapperAlgoliaProductsText = styled('div', {
  name: 'AlgoliaProductsText',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'backgroundColor' && prop !== 'paddingBottomEnabled' && prop !== 'marginLateral',
})<{
  backgroundColor: string
  paddingBottomEnabled?: boolean
  marginLateral: boolean
}>(({ backgroundColor, paddingBottomEnabled, marginLateral, theme }) => ({
  ...teaserBackGroundColorToBg(backgroundColor, theme),
  ...cmsModuleLateralMargin(marginLateral, theme),

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(10, 0, paddingBottomEnabled ? 6 : 0),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(16, 0, paddingBottomEnabled ? 6 : 0),
  },
  [theme.breakpoints.up(1280)]: {
    padding: theme.spacing(16, 44, paddingBottomEnabled ? 6 : 0),
  },
}))

const AlgoliaProductsPretitle = styled('h3', {
  name: 'AlgoliaProductsPretitle',
  slot: 'Pretitle',
})(() => ({
  fontSize: '16px',
  fontWeight: 'bold',
}))

const AlgoliaProductsTitle = styled(StyledTypography, {
  name: 'AlgoliaProductsTitle',
  slot: 'Title',
})(() => ({
  fontSize: '24px',
  fontWeight: 'bold',
}))

const AlgoliaProductsSubTitle = styled('h2', {
  name: 'AlgoliaProductsSubTitle',
  slot: 'SubTitle',
})(() => ({
  fontSize: '16px',
  fontWeight: 400,
}))

const AlgoliaProductsContentText: React.FC<{
  item: ICMAlgolia
  backgroundColor: string
  marginLateral: boolean
}> = ({ item, backgroundColor, marginLateral }) => {
  if (!item) return null

  const AlgoliaPretitle = item.teaserPreTitle || ''
  const AlgoliaTitle = item.teaserTitle1 || ''
  const AlgoliaSubTitle = item.teaserText1?.replace('<div><p>', '').replace('</p></div>', '') || ''
  const paddingBottomEnabled = !!(AlgoliaProductsTitle || AlgoliaProductsSubTitle)

  return (
    <WrapperAlgoliaProductsText
      backgroundColor={backgroundColor}
      paddingBottomEnabled={paddingBottomEnabled}
      marginLateral={marginLateral}
    >
      {AlgoliaProductsPretitle && <AlgoliaProductsPretitle>{AlgoliaPretitle}</AlgoliaProductsPretitle>}
      {AlgoliaProductsTitle && <AlgoliaProductsTitle>{AlgoliaTitle}</AlgoliaProductsTitle>}
      {AlgoliaProductsSubTitle && <AlgoliaProductsSubTitle>{AlgoliaSubTitle}</AlgoliaProductsSubTitle>}
    </WrapperAlgoliaProductsText>
  )
}

export default AlgoliaProductsContentText
