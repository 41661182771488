import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgLensCare = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M15.576 11.548a.327.327 0 0 0-.073.65c2.935.34 3.846.96 3.846 1.15 0 .11-.367.506-1.998.857-1.428.307-3.327.477-5.35.477-2.021 0-3.92-.17-5.348-.477-1.631-.35-1.998-.747-1.998-.858 0-.243.94-.85 3.846-1.168a.328.328 0 0 0 .286-.36.328.328 0 0 0-.36-.29C4.768 11.93 4 12.74 4 13.348c0 1.32.856 2.55 2.409 3.46 1.5.882 3.487 1.367 5.591 1.367s4.09-.485 5.591-1.366c1.554-.912 2.409-2.141 2.409-3.46 0-.587-.768-1.375-4.427-1.8h.003Zm1.683 4.699c-1.402.82-3.268 1.273-5.255 1.273-1.988 0-3.855-.452-5.256-1.273-.935-.547-1.578-1.211-1.885-1.927.403.194.948.367 1.643.518 1.474.321 3.427.498 5.498.498 2.07 0 4.024-.177 5.498-.498.694-.151 1.239-.323 1.642-.518-.307.716-.95 1.38-1.885 1.927Zm-5.539-3.174a3.724 3.724 0 0 0 .567 0c1.084-.088 2.007-.742 2.471-1.75a3.176 3.176 0 0 0-.307-3.19l-2.179-3a.332.332 0 0 0-.096-.087.348.348 0 0 0-.157-.045.317.317 0 0 0-.28.129L9.557 8.132a3.175 3.175 0 0 0-.308 3.19c.464 1.008 1.387 1.663 2.47 1.751Zm-1.634-4.556 1.918-2.639 1.917 2.64c.531.73.624 1.701.243 2.532-.364.79-1.084 1.303-1.93 1.372-.151.012-.31.012-.46 0-.845-.069-1.567-.58-1.93-1.372a2.522 2.522 0 0 1 .242-2.533Zm1.591 3.127c0-.18.147-.326.327-.326.673 0 1.223-.548 1.223-1.222a.326.326 0 1 1 .653 0 1.878 1.878 0 0 1-1.876 1.876.327.327 0 0 1-.327-.327Z"
      />
    </SvgIcon>
  )
}
export default SvgLensCare
