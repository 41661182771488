import React from 'react'
import type { CustomSVGIconProps } from '../icon.type'
import IconWrapper from '../IconWrapper'

export const InfoIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, viewBox, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={viewBox ? viewBox : `0 0 ${width} 17`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M8 16.593c4.411 0 8-3.589 8-8 0-4.412-3.589-8-8-8s-8 3.588-8 8c0 4.411 3.589 8 8 8zM8 1.926a6.674 6.674 0 0 1 6.667 6.667A6.674 6.674 0 0 1 8 15.259a6.674 6.674 0 0 1-6.667-6.666A6.674 6.674 0 0 1 8 1.926z"
        fill="#00303C"
      />
      <path d="M7.333 9.926h1.334V4.592H7.333v5.334zM7.333 12.593h1.334v-1.334H7.333v1.334z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const InfoOutlinedIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
        fill={htmlColor}
      />
      <path d="M8.667 6.667H7.334V12h1.333V6.667zM8.667 4H7.334v1.333h1.333V4z" fill={htmlColor} />
    </IconWrapper>
  )
}
