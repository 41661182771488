export type SourceProps = {
  type?: string
  src?: string
  media?: string
  srcSet?: string | undefined
  sizes?: string
  lazy?: boolean
  width?: number
  height?: number
}

const Source: React.FC<SourceProps> = (props): JSX.Element => {
  return <source {...props} />
}

export default Source
