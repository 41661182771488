import { usePageType } from '@foundation/hooks/usePageType'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { plpBadgesSelector } from '@redux/selectors/site'
import { stringToBoolean } from '@utils/common'
import { pickBy } from '@utils/helpers'
import {
  PRODUCT_ATTRIBUTE_TRUE_VALUE,
  getAnnualSupplyBadge,
  getBadge,
  getIsAvantPremiere,
  getIsCustomizable,
  getIsExclusive,
  getIsLimitedEdition,
  getIsMostPopular,
  getIsOnlineExclusive,
  getIsPolarized,
  getIsRoxable,
  getIsSustainable,
  getLensesTreatment,
  getNew,
} from '@utils/productAttributesAlgolia'
import { TProduct } from '@utils/productNew'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentSku, getPriorityBadgeText } from '../helpers'
import { TCurrentBadges } from '../types'

export const useTileDataElementId = (tileIndex: number): string => {
  const { pageType } = usePageType()

  const getDataElementIdByPageType = useCallback(
    (pageType = '') => {
      const elemIds = {
        search: `X_X_SearchPanel_Content_Tile${tileIndex}`,
        pdp: `X_X_AlsoLike_Tile${tileIndex}`,
      }
      return elemIds[pageType] ?? `Tiles_Tile${tileIndex}_Img`
    },
    [tileIndex]
  )

  return useMemo(() => getDataElementIdByPageType(pageType), [pageType, getDataElementIdByPageType])
}

export const useBadges = (product: TProduct, selectedViewCluster: TProduct, clusterLength: number) => {
  const { isRXEnabled } = useStoreIdentity()
  const badgeSelector = useSelector(plpBadgesSelector)
  const { t } = useTranslation()

  const getCurrentBadges = useCallback(
    (currentSku: TProduct): TCurrentBadges => {
      const currentPolarized = getIsPolarized(currentSku)
      const currentRoxable = isRXEnabled && getIsRoxable(currentSku)
      const currentSustainable = getIsSustainable(currentSku)
      const currentCustomizable = getIsCustomizable(currentSku)
      const currentOnlineExclusive = getIsOnlineExclusive(currentSku)
      const currentExclusive = getIsExclusive(currentSku)
      const currentAvantPremiere = getIsAvantPremiere(currentSku)
      const currentLimitedEdition = getIsLimitedEdition(currentSku)
      const currentNew = getNew(currentSku)
      const currentIsMostPopular = getIsMostPopular(currentSku)
      const currentBadge = getBadge(currentSku)

      return {
        isBadge: badgeSelector?.BADGE === stringToBoolean(currentBadge),
        isNew: badgeSelector?.IS_NEW === stringToBoolean(currentNew),
        isLimitedEdition: badgeSelector?.LIMITED_EDITION === stringToBoolean(currentLimitedEdition),
        isOnlineExclusive: badgeSelector?.SPECIAL_PROJECT_FLAG === stringToBoolean(currentOnlineExclusive),
        isExclusive: badgeSelector?.EXCLUSIVE === stringToBoolean(currentExclusive),
        isAvantPremiere: badgeSelector?.AVANT_PREMIERE === stringToBoolean(currentAvantPremiere),
        isRoxable: currentRoxable && badgeSelector?.ROXABLE === stringToBoolean(PRODUCT_ATTRIBUTE_TRUE_VALUE),
        isCustomizable: badgeSelector?.CUSTOMIZABLE === stringToBoolean(currentCustomizable),
        isPolarized: badgeSelector?.POLARIZED === stringToBoolean(currentPolarized),
        isSustainable: badgeSelector?.SUSTAINABILITY_CLAIM === stringToBoolean(currentSustainable),
        isMostPopular: badgeSelector?.LX_IS_MOST_POPULAR === stringToBoolean(currentIsMostPopular),
      }
    },
    [badgeSelector, isRXEnabled]
  )

  const currentSku = getCurrentSku(product, selectedViewCluster, clusterLength)
  const badges = getCurrentBadges(currentSku)

  const currentPolarized = getIsPolarized(currentSku)
  const currentSustainable = getIsSustainable(currentSku)
  const currentLensTreatment = getLensesTreatment(currentSku)
  const activeBadgesTemp = {
    Polarized: badgeSelector?.POLARIZED === stringToBoolean(currentPolarized),
    Sustainable: badgeSelector?.SUSTAINABILITY_CLAIM === stringToBoolean(currentSustainable),
    isLensTreatment: currentLensTreatment === 'Photochromic' || currentLensTreatment === 'Transitions',
  }

  const activeBadges = pickBy(activeBadgesTemp, cb => cb)

  const annualSupplyBadges: string = getAnnualSupplyBadge(product) || 100

  return {
    priorityBadgeText: getPriorityBadgeText(badges, t),
    chainedBadges: Object.keys(activeBadges).join(' | '),
    annualSupplyBadges,
  }
}
