import { useFrameGenius } from '@hooks/useFrameGenius'

export const useExternalsActions = (): {
  [action: string]: () => void
} => {
  const { openFrameAdvisor, openSizeAdvisor } = useFrameGenius()

  const mappedActions = {
    'frame-advisor': openFrameAdvisor,
    'size-advisor': openSizeAdvisor,
  }

  return mappedActions
}
