import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgTiredEye = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M19.81 11.595C17.491 9.276 14.947 8.1 12.248 8.1c-4.558 0-7.853 3.362-7.991 3.505a.78.78 0 0 0 .01 1.092c2.319 2.319 4.863 3.495 7.561 3.495 4.559 0 7.854-3.362 7.992-3.505a.78.78 0 0 0-.01-1.092Zm-1.455-.282c-3.502-1.013-6.874-.917-9.2-.625-1.414.177-2.59.439-3.428.663 1.233-.967 3.616-2.471 6.52-2.471 1.876 0 3.968.628 6.106 2.434l.002-.001Zm-4.564.092a1.904 1.904 0 0 1-1.753 2.643 1.904 1.904 0 0 1-1.762-2.62.495.495 0 0 0 .02-.065c1.068-.09 2.24-.12 3.471-.043a.367.367 0 0 0 .025.088l-.001-.003Zm-8.75.96c.732-.23 2.327-.674 4.411-.918-.061.227-.095.46-.095.699a2.684 2.684 0 0 0 2.682 2.681 2.684 2.684 0 0 0 2.572-3.44c1.435.145 2.935.443 4.436.964-.773.714-3.59 3.062-7.217 3.062-2.075 0-4.415-.768-6.788-3.048h-.001Zm7.388.75a.39.39 0 0 1-.39.39c-.75 0-1.36-.61-1.36-1.36a.39.39 0 0 1 .78 0c0 .32.26.581.58.581a.39.39 0 0 1 .39.39Z"
      />
    </SvgIcon>
  )
}
export default SvgTiredEye
