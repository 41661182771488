import React from 'react'

function useIsInViewport<T extends Element>(ref: React.RefObject<T>, options?: IntersectionObserverInit): boolean {
  const [isIntersecting, setIntersecting] = React.useState<boolean>(false)

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
    }, options)
    if (ref.current) observer.observe(ref.current)

    return () => {
      if (ref.current) observer.unobserve(ref.current)
    }
  }, [])
  return isIntersecting
}

export default useIsInViewport
