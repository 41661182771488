import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgDeliveryReduced = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.553 10.93c-.047-.087-1.17-2.12-1.624-2.7a1.709 1.709 0 0 0-1.261-.646h-.876l.165-1.646a.806.806 0 0 0-.178-.605.918.918 0 0 0-.658-.331h-.014C15.091 5 15.075 5 15.056 5H6.65c-.56.01-.956.31-1.012.77L5.437 7.9a.347.347 0 0 0 .69.065l.202-2.119c.016-.14.301-.15.328-.15h8.416l.026.001a.222.222 0 0 1 .14.074.12.12 0 0 1 .028.094l-.204 2.033a.347.347 0 0 0 .346.382h1.237c.284.014.566.158.736.378.4.51 1.441 2.387 1.55 2.583l.01.017c.092.178.37.72.366 1.229l-.262 2.986c-.015.134-.157.24-.317.244H17.3a1.723 1.723 0 0 0-1.652-1.14 2.04 2.04 0 0 0-1.92 1.458h-3.51a1.723 1.723 0 0 0-1.734-1.458 2.039 2.039 0 0 0-1.793 1.139H5.343l-.012-.001-.012-.001a.153.153 0 0 1-.126-.168l.184-1.81a.347.347 0 0 0-.69-.07l-.184 1.81a.848.848 0 0 0 .754.93h.004l.03.002h.01a.428.428 0 0 0 .05.002h1.138a1.725 1.725 0 0 0 1.719 1.904h.025a2.042 2.042 0 0 0 1.952-1.585h3.465a1.724 1.724 0 0 0 1.721 1.585h.025a2.041 2.041 0 0 0 1.997-1.872v-.017l.001-.014h1.34c.515-.01.944-.379 1-.868l.265-3.008v-.025c.01-.615-.248-1.19-.447-1.577l.001-.002ZM9.54 16.408a1.347 1.347 0 0 1-1.447 1.204 1.029 1.029 0 0 1-.91-1.134 1.346 1.346 0 0 1 1.316-1.209h.028a1.03 1.03 0 0 1 1.013 1.139Zm7.163 0a1.347 1.347 0 0 1-1.447 1.204 1.029 1.029 0 0 1-.91-1.134 1.346 1.346 0 0 1 1.316-1.209h.029a1.03 1.03 0 0 1 1.013 1.139Zm-11.11-4.546a.348.348 0 0 0 0 .694h3.983a.347.347 0 0 0 0-.694H5.592Zm4.736-2.866a.347.347 0 0 1 0 .694H6.052a.347.347 0 0 1 0-.694h4.276Zm-2.072 1.78a.347.347 0 0 0-.348-.347h-3.56a.347.347 0 0 0 0 .694h3.56a.347.347 0 0 0 .347-.347Zm6.513-1.378a.348.348 0 0 1 .384-.306l.001.002c.19.022.328.193.306.384l-.263 2.348a.229.229 0 0 0 .102.023h2.763a.348.348 0 0 1 0 .694H15.31c-.015 0-.029 0-.043-.002h-.039a.84.84 0 0 1-.594-.294.61.61 0 0 1-.133-.464l.267-2.385Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  )
}
export default SvgDeliveryReduced
