import { ProductImageProps } from '@components/ProductImage/ProductImage'
import { ProductMedia } from '@components/common/Media/ProductMedia'
import { LinkStyled } from '@components/common/UI/LinkStyled'
import { Carousel } from '@components/common/components/Carousel'
import { StyledProductTileImageWrapper } from '../SuggestedProductTile.style'
import config from '@configs/index'
import { productsMobileListingLayoutSelector } from '@features/ui/selector'
import { retrieveProductImages } from '@hooks/useProductImages/useProductImages'
import { pdpFrameImageOrderSelector } from '@redux/selectors/site'
import { ProductImageUsage } from '@typesApp/product'
import { sortImageByConf } from '@utils/attachmentsUtils'
import { isContactLenses } from '@utils/product'
import { TProduct } from '@utils/productNew'
import clsx from 'clsx'
import { shallowEqual, useSelector } from 'react-redux'
import { getAttachments, getProductInfo } from '../helpers'
import styles from '../styles/index.module.scss'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { useTranslation } from 'next-i18next'
import { badgeLabelsMap, updateAttributes } from '@components/UI/ProductLabel/helper'
import { StyledProductLabelWrap } from '@components/ProductGrid/components/ProductGridView/ProductGridView.style'
import ProductLabel from '@components/UI/ProductLabel'

const commonGalleryProductImageProps: ProductImageProps = {
  sequence: '2.0',
  usage: 'PLP',
}
const siteName = config.name

type TProductTileImages = {
  clusters: TProduct[]
  product: TProduct
  selectedViewCluster: TProduct
  tileDataElementId: string
  priorityBadgeText: string
  selectedClusterIndex: number
  isImgWithShadow: boolean | undefined
  mocoLink: string
  onProductChange: (ind: number) => void
  onImageClick: () => void
  variant?: string
  backgroundColor?: string
  lazy?: boolean
}

export const ProductTileImages: React.FC<TProductTileImages> = ({
  clusters,
  product,
  selectedViewCluster,
  tileDataElementId,
  priorityBadgeText,
  selectedClusterIndex,
  isImgWithShadow,
  mocoLink,
  onImageClick,
  onProductChange,
  lazy,
}) => {
  const { t } = useTranslation()
  const productsMobileListingLayout = useSelector(productsMobileListingLayoutSelector)
  const pdpFrameImageOrder = useSelector(pdpFrameImageOrderSelector, shallowEqual)
  const currentImages = retrieveProductImages(product)
  const sortedImageByConf = sortImageByConf(currentImages, pdpFrameImageOrder)
  const [, _second] = sortedImageByConf
  const { productType, name, modelCode } = getProductInfo(product)
  const isGroupingPLP = !!product?.cluster && !product?.items
  const { isMobile } = useBreakpoint()
  const usage = isImgWithShadow ? _second?.usage : 'PLP'
  const width = !isMobile ? 364 : 398

  if (productsMobileListingLayout === 'full' && clusters && !isContactLenses(productType)) {
    const currentProduct = clusters[selectedClusterIndex]
    const { name, modelCode } = getProductInfo(currentProduct)
    return (
      <div className={clsx(styles['image-gallery-wrapper'])}>
        <Carousel
          beforeSlide={(_, endSlideIndex) => {
            onProductChange && onProductChange(endSlideIndex)
          }}
          slideIndex={selectedClusterIndex}
          animation="fade"
          speed={2500}
          loop
        >
          {clusters.map(c => (
            <LinkStyled
              className={clsx(styles['product-anchor'], 'product-anchor')}
              key={`${name}_${modelCode}-${c.partnumberId}`}
              aria-label={priorityBadgeText || `${name}_${modelCode}`}
              href={mocoLink}
              data-element-id={tileDataElementId}
              data-description={`${siteName}_${name}_${modelCode}`}
            >
              <ProductMedia attachments={c.attachments} {...commonGalleryProductImageProps} lazy={lazy} />
            </LinkStyled>
          ))}
        </Carousel>
      </div>
    )
  }

  const updatedAttributes = updateAttributes(product['attributes'])
  const matchedBadgeKey = Object.keys(badgeLabelsMap).find(badgeKey => badgeKey in updatedAttributes)

  return (
    <StyledProductTileImageWrapper
      aria-label={priorityBadgeText || `${name}_${modelCode}`}
      data-element-id={tileDataElementId}
      data-description={`${siteName}_${name}_${modelCode}`}
    >
      <ProductMedia
        attachments={
          isGroupingPLP && selectedViewCluster
            ? getAttachments(selectedViewCluster)
            : !isGroupingPLP && product
              ? getAttachments(product)
              : undefined
        }
        usage={usage as ProductImageUsage}
        lazy={lazy}
        alt={name}
        onClick={onImageClick}
        width={width}
        srcSetMap={{
          796: '860w', // mobile
          728: '1023w',
        }}
      />
      <StyledProductLabelWrap>
        {matchedBadgeKey && <ProductLabel badge={matchedBadgeKey} label={t(`ProductLabel.${matchedBadgeKey}`)} />}
      </StyledProductLabelWrap>
    </StyledProductTileImageWrapper>
  )
}
