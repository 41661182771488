import { useState, useEffect, ScriptHTMLAttributes } from 'react'
import { fetchJsFromCDN } from '../../utils/fetchFromCdn'

type UseExternalScript = (
  src: string,
  windowKey: string,
  attributes: ScriptHTMLAttributes<HTMLScriptElement> & {
    id: string
    [key: `data-${string}`]: string
  }
) => [isLoaded: boolean, scriptObject: any]

const useExternalScript: UseExternalScript = (src, key, attributes) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [windowKey, setWindowKey] = useState()

  const handleScriptLoad = async () => {
    const isAlreadyLoaded = !!document.getElementById(attributes.id)

    if (!isAlreadyLoaded) {
      await fetchJsFromCDN(src, key, {
        id: attributes.id,
        type: attributes.type,
      })
    }
    setIsLoaded(true)
  }

  useEffect(() => {
    setWindowKey(window[key])
  }, [])

  useEffect(() => {
    void handleScriptLoad()
  }, [])

  return [isLoaded, windowKey]
}

export default useExternalScript
