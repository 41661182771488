import { Accordion, AccordionSummary } from '@components/UI/Accordion'

import styled from '@mui/material/styles/styled'
import CmsTeaserDetailText from '../../../CmsComponents/CmsTeaserDetailText'
import CmsTeaserTitle from '../../../CmsComponents/CmsTeaserTitle'

export const StyledWrapperFaqs = styled('div', {
  name: 'Faqs',
  slot: 'Wrapper',
})(({ theme }) => ({
  ul: {
    paddingLeft: theme.spacing(5), // see: https://abstractsrl.atlassian.net/browse/DCW-4527
    ['& ::marker']: {
      fontSize: 8,
    },
  },
}))

export const StyledTitleFaqs = styled(CmsTeaserTitle, {
  name: 'Faqs',
  slot: 'Title',
})(() => ({
  textAlign: 'left',
}))

export const StyledContentFaqs = styled('div', {
  name: 'Faqs',
  slot: 'Content',
})(() => ({}))

export const StyledAccordion = styled(Accordion, {
  name: 'Faqs',
  slot: 'Accordion',
})(({ theme }) => ({
  '&.MuiAccordion-root': {
    borderBottom: `1px solid ${theme.palette.background.neutral}`,
  },
  marginBottom: theme.spacing(16),
}))

export const StyledAccordionSummary = styled(AccordionSummary, {
  name: 'Faqs',
  slot: 'AccordionSummary',
})(({ theme }) => ({
  borderBottom: 'none',
  fontSize: 16,
  fontWeight: 'bold',
  lineHeight: 1.5,
  minHeight: 0,
  margin: `${theme.spacing(4)} 0`,

  '& .MuiAccordionSummary-content': {
    padding: `${theme.spacing(1)} 0`,
  },
}))

export const StyledAccordionDetails = styled(CmsTeaserDetailText, {
  name: 'Faqs',
  slot: 'AccordionDetails',
})(({ theme }) => ({
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    marginTop: 0,
  },
}))
