import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgLiveChat = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M11.76 4.67c-4.412 0-8 2.773-8 6.182 0 3.408 3.588 6.182 7.997 6.182h.234l1.107 1.478a.384.384 0 0 0 .614 0l1.593-2.123.03-.008a.442.442 0 0 0 .05-.015c2.699-1.059 4.375-3.172 4.375-5.514 0-3.409-3.588-6.182-8-6.182Zm3.367 10.972-.139.034c-.008.002-.015.005-.023.009l-.025.006-.015.008a.692.692 0 0 0-.066.037l-.01.007a.36.36 0 0 0-.06.058l-.015.018-1.369 1.825-.915-1.221-.012-.013a.485.485 0 0 0-.064-.063.318.318 0 0 0-.048-.03c-.008-.005-.015-.01-.024-.014a.391.391 0 0 0-.079-.026h-.015a.373.373 0 0 0-.061-.008l-.425-.002c-3.99 0-7.233-2.43-7.233-5.415 0-2.986 3.244-5.416 7.233-5.416 3.988 0 7.233 2.43 7.233 5.416 0 2.013-1.48 3.847-3.865 4.791h-.003Zm-6.564-3.834a.896.896 0 0 1 0-1.79c.493 0 .895.402.895.895a.896.896 0 0 1-.895.895Zm0-1.242a.347.347 0 1 0 0 .695.347.347 0 0 0 0-.695Zm3.251 1.242a.895.895 0 0 1 0-1.79c.493 0 .895.402.895.895a.896.896 0 0 1-.895.895Zm0-1.242a.347.347 0 1 0 0 .695.347.347 0 0 0 0-.695Zm3.251 1.242a.895.895 0 0 1 0-1.79c.493 0 .895.402.895.895a.896.896 0 0 1-.895.895Zm0-1.242a.347.347 0 1 0 0 .695.347.347 0 0 0 0-.695Zm-.271 4.244a.383.383 0 0 1-.162-.731c1.305-.604 2.206-1.469 2.535-2.437a.382.382 0 0 1 .486-.238c.2.068.308.286.24.486-.4 1.168-1.443 2.192-2.94 2.885a.386.386 0 0 1-.16.035Z"
      />
    </SvgIcon>
  )
}
export default SvgLiveChat
