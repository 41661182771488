import type { CustomSVGIconProps } from '../icon.type'
import IconWrapper from '../IconWrapper'
import React from 'react'

export const CloseCircleIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
        fill={htmlColor}
      />
      <path
        d="M10.195 4.862 8 7.057 5.805 4.862l-.943.942L7.057 8l-2.195 2.195.943.943L8 8.942l2.195 2.196.943-.943L8.943 8l2.195-2.196-.943-.942z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CloseIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="m15.8 1.133-.933-.934L8 7.066 1.134.199.2 1.133l6.867 6.866L.2 14.866l.934.933L8 8.933l6.867 6.866.933-.933-6.866-6.867L15.8 1.133z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CloseFilledIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#767676',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm3.805 10.862-.943.943L8 8.943l-2.862 2.862-.943-.943L7.057 8 4.195 5.138l.943-.943L8 7.057l2.862-2.862.943.943L8.943 8l2.862 2.862z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CloseIconSearch: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm3.805 10.862-.943.943L8 8.943l-2.862 2.862-.943-.943L7.057 8 4.195 5.138l.943-.943L8 7.057l2.862-2.862.943.943L8.943 8l2.862 2.862z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}
