import { IAlgoliaHit } from '@typesApp/product'

export const badgeLabelsMap: { [key: string]: string } = {
  CL_BADGE_MULTIFOCAL: 'Multifocal',
  CL_BADGE_ASTIGMATISM: 'Astigmatism',
  CL_BADGE_BEST_FOR_COMFORT: 'Best for comfort',
  CL_BADGE_BEST_FOR_VALUE: 'Best for value',
  CL_BADGE_LETTERBOX_FRIENDLY: 'Letterbox friendly',
  CL_BADGE_TRIAL_PACK: 'Trial pack',
  CL_BADGE_FREE_LENS_CASE: 'Free Lens Case',
}

const getProductAttributeName = (attributeName: string) => {
  return `CL_BADGE_${attributeName.toUpperCase().split(' ').join('_')}`
}

export const transformProductsData = (products: any) => {
  return products.map(product => {
    const newProduct: { [key: string]: string | undefined } = { image: product.image }

    if (product.CL_CORRECTION_TYPE) {
      let correctionType = product.CL_CORRECTION_TYPE
      if (correctionType === 'Toric') {
        correctionType = 'Astigmatism'
      }
      const transformedKey = getProductAttributeName(correctionType)
      newProduct[transformedKey] = correctionType
    }

    if (product.CL_BADGE) {
      const transformedKey = getProductAttributeName(product.CL_BADGE)
      newProduct[transformedKey] = product.CL_BADGE
    }

    return newProduct
  })
}

export function updateAttributes(attributes: unknown[] | IAlgoliaHit | Record<string, string>) {
  const updatedAttributes = { ...attributes }

  if (updatedAttributes.hasOwnProperty('CL_CORRECTION_TYPE')) {
    let correctionType = updatedAttributes['CL_CORRECTION_TYPE']
    if (correctionType === 'Toric') {
      correctionType = 'Astigmatism'
    }
    updatedAttributes[getProductAttributeName(correctionType)] = correctionType
    delete updatedAttributes['CL_CORRECTION_TYPE']
  }

  if (updatedAttributes.hasOwnProperty('CL_BADGE')) {
    const badgeValue = updatedAttributes['CL_BADGE']
    updatedAttributes[getProductAttributeName(badgeValue)] = badgeValue
    delete updatedAttributes['CL_BADGE']
  }

  return updatedAttributes
}
