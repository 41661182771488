import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgChevronDown = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M11.643 16.62a.39.39 0 0 1-.276-.113l-7.61-7.612a.388.388 0 1 1 .548-.549l7.338 7.338 7.337-7.338a.388.388 0 1 1 .549.55l-7.611 7.61a.388.388 0 0 1-.275.113v.002Z"
      />
    </SvgIcon>
  )
}
export default SvgChevronDown
