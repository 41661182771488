import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const Svg12HrsPlusFr = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M20.129 12.344a8.007 8.007 0 0 1-3.042 5.872 7.968 7.968 0 0 1-4.945 1.714 7.999 7.999 0 0 1-6.298-3.049C3.112 13.413 3.712 8.371 7.18 5.638a8.028 8.028 0 0 1 9.4-.37l-.15-.918a.388.388 0 0 1 .542-.418.42.42 0 0 1 .168.135c.037.05.061.107.072.167l.295 1.816a.446.446 0 0 1-.078.318.39.39 0 0 1-.252.156l-1.821.301a.387.387 0 0 1-.429-.252.44.44 0 0 1-.024-.11l-.003-.02a.389.389 0 0 1 .325-.424l.867-.142a7.247 7.247 0 0 0-8.43.372c-3.13 2.466-3.671 7.02-1.206 10.151 2.466 3.131 7.02 3.673 10.151 1.206a7.236 7.236 0 0 0 2.747-5.302.388.388 0 1 1 .776.04h-.001Zm-12.638.642h.745v-.746h.726v-.722h-.726v-.74h-.745v.74h-.73v.722h.73v.745Zm2.388.522h.901V9.94h-.658l-.897.26.126.754.528-.132v2.687Zm1.348 0h2.457v-.78h-1.292l.593-.558c.466-.43.69-.72.69-1.196v-.01c0-.644-.487-1.064-1.177-1.064-.551 0-.927.224-1.287.644l.542.583c.253-.268.431-.405.649-.405.218 0 .36.137.36.34s-.111.36-.401.649l-1.135 1.085v.715-.002Zm3.709 0h.814v-1.474c0-.231.124-.357.301-.357s.287.125.287.357v1.474h.814v-1.724c0-.509-.274-.828-.74-.828-.319 0-.523.18-.662.38v-1.175h-.814v3.349-.002Z"
      />
    </SvgIcon>
  )
}
export default Svg12HrsPlusFr
