import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgEyeVitamins = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M15.094 10.953a.405.405 0 0 0 .015-.105V9.227a.386.386 0 0 0-.387-.387H14.7V7.15c0-.013 0-.025-.002-.038-.005-.043-.117-1.073-.901-1.725-.277-.23-.335-.935-.32-1.24a.386.386 0 0 0-.387-.407h-.001l-1.946.006H11.125c-.008 0-.015.003-.023.004-.016.002-.033.003-.048.008a.25.25 0 0 0-.027.009.318.318 0 0 0-.045.016l-.024.013a.357.357 0 0 0-.04.025c-.008.005-.013.01-.021.017l-.036.032-.016.021a.301.301 0 0 0-.028.038l-.013.024-.02.041a.59.59 0 0 0-.02.072c-.002.012-.002.024-.003.037l-.003.03v.02c.015.304-.042 1.01-.32 1.24-.785.653-.897 1.681-.9 1.724l-.003.039V8.84h-.036a.387.387 0 0 0-.386.387v1.621c0 .037.005.072.014.105a3.08 3.08 0 0 0-.837 2.104v5.257c0 .786.671 1.426 1.495 1.426h4.65c.406 0 .8-.161 1.082-.442.266-.267.413-.616.413-.984v-5.257c0-.78-.3-1.524-.837-2.104h.001Zm-4.783-3.772c.015-.111.13-.782.624-1.192.44-.366.561-1.034.592-1.47l1.185-.003c.031.436.153 1.102.591 1.467.496.412.61 1.085.625 1.193V8.84h-3.616V7.18h-.001Zm-1.245 6.417h2.91v3.21h-2.91v-3.21Zm6.091 4.716a.616.616 0 0 1-.186.437.759.759 0 0 1-.535.215h-4.65c-.397 0-.72-.293-.72-.652v-.733h3.297a.387.387 0 0 0 .387-.387v-3.983a.387.387 0 0 0-.387-.387H9.078c.054-.526.292-1.02.683-1.412.054-.053.11-.105.169-.154h2.509c.103 0 .201-.04.274-.112l.011-.011a.383.383 0 0 0 .113-.271.402.402 0 0 0-.118-.286.411.411 0 0 0-.281-.115h-2.55v-.848h4.446v.867c-.027 0-.053-.002-.073-.003a.383.383 0 0 0-.45.378c-.003.32.25.382.476.397.554.46.87 1.114.87 1.804v5.257-.001Zm-.822-7.816Z"
      />
    </SvgIcon>
  )
}
export default SvgEyeVitamins
