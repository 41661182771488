import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCarouselArrow = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon viewBox="0 0 24 24" {...other}>
      <circle
        cx={12}
        cy={12}
        r={11.5}
        fill="none"
        stroke="currentColor"
        className="carouselArrow_svg__arrow-slider-circle"
      />
      <path
        fill="currentColor"
        d="m10.509 12 3.308-3.025a.537.537 0 0 0 0-.808.667.667 0 0 0-.884 0l-3.75 3.429a.537.537 0 0 0 0 .808l3.75 3.429c.244.223.64.223.884 0a.537.537 0 0 0 0-.808L10.509 12z"
        className="carouselArrow_svg__arrow-slider-shape"
      />
    </SvgIcon>
  )
}
export default SvgCarouselArrow
