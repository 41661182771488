import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgClockSpeedy = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M14.525 15.01a.386.386 0 0 1-.275-.114l-2.491-2.492a.387.387 0 0 1-.113-.274V8.61a.388.388 0 0 1 .775 0v3.36l2.378 2.377a.387.387 0 0 1-.275.663h.001Zm5.182-2.965a.387.387 0 0 0-.408.367 7.217 7.217 0 0 1-2.741 5.292A7.222 7.222 0 0 1 6.426 16.5C3.965 13.376 4.504 8.83 7.63 6.37a7.235 7.235 0 0 1 8.414-.371l-.867.141a.389.389 0 0 0-.324.423l.002.02a.388.388 0 0 0 .452.36l1.818-.3c.1-.016.189-.07.249-.151a.447.447 0 0 0 .08-.323l-.294-1.81a.382.382 0 0 0-.066-.16.427.427 0 0 0-.17-.14.388.388 0 0 0-.543.416l.15.916a8.012 8.012 0 0 0-9.383.37c-3.462 2.726-4.059 7.759-1.334 11.22a7.98 7.98 0 0 0 6.286 3.043 7.95 7.95 0 0 0 4.935-1.71 7.991 7.991 0 0 0 3.036-5.861.388.388 0 0 0-.367-.408h.003Z"
      />
    </SvgIcon>
  )
}
export default SvgClockSpeedy
