import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgStayHydrated = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.45 14.517c-.12 0-.242-.005-.362-.014-1.387-.113-2.567-.95-3.16-2.238a4.06 4.06 0 0 1 .393-4.08l2.804-3.859a.384.384 0 0 1 .343-.157.391.391 0 0 1 .31.16l2.8 3.855a4.06 4.06 0 0 1 .393 4.08c-.592 1.288-1.773 2.125-3.16 2.237-.12.01-.242.015-.362.015h.001Zm0-9.278L6.97 8.655a3.262 3.262 0 0 0-.314 3.275c.47 1.023 1.404 1.686 2.498 1.775.194.016.4.016.595 0 1.093-.09 2.027-.752 2.497-1.775a3.262 3.262 0 0 0-.314-3.275l-2.48-3.416Zm6.406 11.393a2.174 2.174 0 0 1-2.02-1.294 2.328 2.328 0 0 1 .225-2.337l1.522-2.094a.328.328 0 0 1 .292-.136c.06.004.116.02.165.05a.356.356 0 0 1 .094.088L17.654 13c.49.676.577 1.57.226 2.337a2.177 2.177 0 0 1-2.021 1.294h-.003ZM14.608 13.4l1.248-1.718 1.248 1.718c.348.478.409 1.112.159 1.656-.237.515-.706.85-1.256.894-.099.007-.202.007-.303 0a1.505 1.505 0 0 1-1.255-.893 1.65 1.65 0 0 1 .159-1.657Zm-2.761 6.76c.058.004.116.006.175.006l-.001.002a1.856 1.856 0 0 0 1.72-1.1c.297-.652.225-1.411-.193-1.985l-1.252-1.725a.335.335 0 0 0-.256-.138.332.332 0 0 0-.291.136l-1.255 1.727a1.975 1.975 0 0 0-.191 1.984 1.85 1.85 0 0 0 1.544 1.093Zm-.808-2.682.983-1.352.982 1.352c.274.378.322.878.125 1.307a1.185 1.185 0 0 1-.987.703c-.079.007-.161.007-.24 0a1.185 1.185 0 0 1-.987-.703c-.197-.429-.15-.929.124-1.307Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  )
}
export default SvgStayHydrated
