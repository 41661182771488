import { IBasePlacementItem, IPlacement, IPlacementItem } from './cmsPlacement/Placement'
import { IViewType } from './cmsPlacement/ViewType'
import { ILXTeaser } from './cmsPlacement/LXTeaser'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ICMExternalProduct } from './cmsPlacement/CMExternalProduct'
import { ICMProductTeaser } from './cmsPlacement/CMProductTeaser'
import { ICMArticle } from './cmsPlacement/CMArticle'
import { ICMChannel } from './cmsPlacement/CMChannel'
import { IPictureMedia, IVideoMedia, TMedia } from './cmsPlacement/Media'
import { LXDynamicContent } from './cmsPlacement/LXDynamicContent'
import { ICMExternalPage } from './cmsPlacement/CMExternalPage'
import { ICMExternalChannel } from './cmsPlacement/CMExternalChannel'
import { ICMProductList } from './cmsPlacement/CMProductList'
import { ICMQueryList } from './cmsPlacement/CMQueryList'
import { ICMPlaceholder } from './cmsPlacement/CMPlaceholder'
import { ICMExternalLink } from './cmsPlacement/CMExternalLink'
import { ICMHtml } from './cmsPlacement/CMHtml'
import { ICMAlgolia } from './cmsPlacement/CMAlgolia'

export type IPLPBoxOrientation = 'left' | 'right'

export interface DashButtonProps {
  formattedUrl: string
  name: string
  teaserIcon: string
}

export interface AnchorData {
  title: string
  placement: string
}

export const isLXTeaser = (item: IPlacementItem): item is ILXTeaser => (item as ILXTeaser).type === 'LXTeaser'

export const isCMCollection = (item: IPlacementItem): item is ICMCollection => item.type === 'CMCollection'

export const isCMExternalProduct = (item: IPlacementItem): item is ICMExternalProduct =>
  item.type === 'CMExternalProduct'

export const isCMProductTeaser = (item: IPlacementItem): item is ICMProductTeaser => item.type === 'CMProductTeaser'

export const isCMArticle = (item: IPlacementItem): item is ICMArticle => item.type === 'CMArticle'

export const isCMChannel = (item: IPlacementItem): item is ICMChannel => item.type === 'CMChannel'

export const isAlgolia = (item: IPlacementItem): item is ICMAlgolia => (item as ICMAlgolia).type === 'Algolia'

export const isPictureMedia = (item: TMedia | undefined): item is IPictureMedia => item?.type === 'CMPicture' ?? false

export const isVideoMedia = (item?: TMedia): item is IVideoMedia => item?.type === 'CMVideo' ?? false

export const isDynamicContent = (item: IPlacementItem): item is LXDynamicContent =>
  (item as LXDynamicContent).type === 'LXDynamicContent'

export const isCMExternalPage = (item: IPlacementItem): item is ICMExternalPage => item.type === 'CMExternalPage'

export const isICMHtml = (item: IPlacementItem): item is ICMHtml => item.type === 'CMHTML'

export const isCMExternalChannel = (item: IPlacementItem): item is ICMExternalChannel =>
  item.type === 'CMExternalChannel'

export const isCMProductList = (item: IPlacementItem): item is ICMProductList => item.type === 'CMProductList'

export const isCMQueryList = (item: IPlacementItem): item is ICMQueryList => item.type === 'CMQueryList'

export const isCMPlaceholder = (item: IPlacementItem): item is ICMPlaceholder => item.type === 'CMPlaceholder'

export const isCMExternalLink = (item: IPlacementItem): item is ICMExternalLink => item.type === 'CMExternalLink'

export interface ITextModuleTeaser {
  item: ILXTeaser
  viewType: IViewType
  placementHasBackGroundColor?: boolean
  teaserIndex?: number
  placementCenter?: boolean
  placement?: IPlacement | ICMCollection
}

export interface ICMAnchor extends IBasePlacementItem {
  type: 'ICMAnchor'
  placementReflect: boolean
  marginLateral: boolean
  marginVertical: string
  placementCenter: boolean
  backgroundColor: string
  clusterTile: boolean
  placementAnimation: string
  items: ILXTeaser[]
}

export interface ICMDownload extends IBasePlacementItem {
  formattedUrl: string
  url: string
  fullyQualifiedUrl: string
  title: string
  type: 'CMDownload'
}

export interface IAction extends IBasePlacementItem {
  idAction: string
  formattedUrl: string
  url: string
  title: string
  type: 'Action'
}
export interface ICallToAction {
  callToActionEnabled?: boolean
  callToActionText?: string
  callToActionHash?: string
  style: 'cta-primary' | 'cta-secondary' | 'cta-tertiary' | 'cta-primary-outlined'
  target:
    | ICMChannel
    | ICMExternalPage
    | ICMProductTeaser
    | ICMExternalChannel
    | ICMExternalProduct
    | ICMDownload
    | ICMExternalLink
    | ICMArticle
    | IAction
}
