import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSun = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M11.74 16.574a4.199 4.199 0 0 1-4.194-4.193 4.198 4.198 0 0 1 4.195-4.194 4.197 4.197 0 0 1 4.193 4.194 4.198 4.198 0 0 1-4.193 4.193Zm0-7.618a3.428 3.428 0 0 0-3.424 3.424 3.427 3.427 0 0 0 3.425 3.422 3.426 3.426 0 0 0 3.423-3.422 3.427 3.427 0 0 0-3.423-3.424Zm.386-2.576V4.765a.386.386 0 0 0-.77 0V6.38a.386.386 0 0 0 .77 0Zm4.13 2.03 1.14-1.142a.385.385 0 1 0-.544-.545L15.71 7.865a.385.385 0 1 0 .544.543l.001.001Zm3.484 3.97a.386.386 0 0 0-.385-.386H17.74a.386.386 0 0 0 0 .77h1.615a.386.386 0 0 0 .385-.384Zm-2.343 5.657a.385.385 0 0 0 0-.545l-1.142-1.141a.385.385 0 1 0-.544.544l1.14 1.142a.385.385 0 0 0 .545 0Zm-5.271 1.958V18.38a.386.386 0 0 0-.77 0v1.615a.386.386 0 0 0 .77 0Zm-5.498-1.958 1.141-1.142a.385.385 0 1 0-.544-.544l-1.142 1.14a.385.385 0 1 0 .544.546h.001Zm-.503-5.657a.386.386 0 0 0-.385-.386H4.125a.386.386 0 0 0 0 .77H5.74a.386.386 0 0 0 .385-.384ZM7.77 8.41a.385.385 0 0 0 0-.545L6.63 6.723a.385.385 0 1 0-.546.545L7.225 8.41a.385.385 0 0 0 .543.002l.001-.002Z"
      />
    </SvgIcon>
  )
}
export default SvgSun
