import CmsIcon from '@components/Cms/CmsComponents/CmsIcon/CmsIcon'
import Countdown from '@components/common/UI/Countdown'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ILXTeaser, ITeaserOverlaySettings, ITeaserOverlayStyle } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import clsx from 'clsx'
import CmsCta from '@components/Cms/CmsComponents/CmsCta'
import styles from './styles/index.module.scss'
import { PropsWithChildren } from 'react'
import { Typography } from '@mui/material'
import { StyledBoxBannerTextContainer, StyledBoxBannerTitle } from './TextModule.style'
import useReplaceLocaleInCMSUrl from '@hooks/useReplaceLocaleInCMSUrl'

type TextModule = {
  placement?: IPlacement | ICMCollection
  teaser?: ILXTeaser
  teaserIndex?: number
  resetGap?: boolean
  layout?: 'column-1' | 'column-2'
  overrideTeaserOverlaySettings?: ITeaserOverlaySettings
  overrideTeaserOverlayStyle?: ITeaserOverlayStyle
  overrideTeaserOverlayTextAlign?: 'left' | 'center' | 'right'
  showCtas?: boolean
}

export const TextModule: React.FC<PropsWithChildren<TextModule>> = ({
  placement,
  teaser,
  layout = 'column-1',
  resetGap,
  overrideTeaserOverlaySettings,
  overrideTeaserOverlayStyle,
  overrideTeaserOverlayTextAlign,
  showCtas = true,
  children,
}) => {
  const replaceLocaleInCmsUrls = useReplaceLocaleInCMSUrl()
  const item = placement
    ? 'items' in placement
      ? placement?.items?.find(isLXTeaser)
      : placement?.teasableItems?.find(isLXTeaser)
    : teaser
  const {
    teaserPreTitle,
    teaserTitle3,
    teaserTitle4,
    teaserText2,
    teaserOverlay2Settings,
    teaserOverlay2Style,
    teaserOverlay2TextAlign,
    teaserLXCallToActionSettings,
    teaserIcon,
    teaserHideExpiredCountdown,
    teaserCountdownStart,
    teaserCountdownUntil,
    teaserText1,
    teaserTitle1,
    promoteToH1,
  } = item || {}

  return (
    <section
      className={clsx(
        'cms-section',
        'text-module',
        styles['text-module'],
        overrideTeaserOverlaySettings ?? teaserOverlay2Settings,
        overrideTeaserOverlayStyle ?? teaserOverlay2Style,
        overrideTeaserOverlayTextAlign ?? teaserOverlay2TextAlign,
        {
          'reset-gap': resetGap,
          'column-2': layout === 'column-2',
        }
      )}
    >
      <div className={styles['text-module-inner']}>
        {teaserIcon && <CmsIcon teaserIcon={teaserIcon} />}
        {teaserCountdownUntil && (
          <Countdown
            startDateInSeconds={teaserCountdownStart}
            endDateInSeconds={teaserCountdownUntil}
            hideOnExpire={teaserHideExpiredCountdown}
          />
        )}

        {teaserPreTitle && <Typography variant="body2">{teaserPreTitle}</Typography>}
        {teaserTitle3 && !teaserTitle4 && <Typography variant="h6">{teaserTitle3}</Typography>}
        {teaserTitle4 && <Typography variant={promoteToH1 ? 'h1' : 'h6'}>{teaserTitle4}</Typography>}
        {teaserText2 && !teaserText1 && (
          <div
            className={styles['teaser-text']}
            dangerouslySetInnerHTML={{ __html: replaceLocaleInCmsUrls(teaserText2) }}
          />
        )}
        {teaserTitle1 && !teaserTitle4 && <StyledBoxBannerTitle variant="h6">{teaserTitle1}</StyledBoxBannerTitle>}
        {teaserText1 && (
          <StyledBoxBannerTextContainer dangerouslySetInnerHTML={{ __html: replaceLocaleInCmsUrls(teaserText1) }} />
        )}
      </div>
      {children}
      {showCtas && Array.isArray(teaserLXCallToActionSettings) && teaserLXCallToActionSettings.length > 0 && (
        <div className="cta-container">
          {teaserLXCallToActionSettings.map((target, i) => {
            if (i > 1 || !target.callToActionEnabled) return null
            return (
              <CmsCta
                key={target.callToActionText}
                actionSettings={target}
                data-element-id={`X_X_${i}Placement_LandscapeBanner_CTA`}
              />
            )
          })}
        </div>
      )}
    </section>
  )
}
