import { CartIcon } from '@components/UI/Icons/Icons'
import { ProductMedia } from '@components/common/Media/ProductMedia'
import { LinkStyled } from '@components/common/UI/LinkStyled'
import { ProductPrice } from '@components/common/UI/ProductPrice'
import { TProductPrice } from '@components/common/UI/ProductPrice/index.types'
import { NOT_FOUND } from '@constants/routes'
import { bffProductApi } from '@features/bffProduct/query'
import { IHotzone, IHotzoneSettings } from '@typesApp/cmsPlacement/LXTeaser'
import { TLightProduct } from '@utils/productNew'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo } from 'react'
import { useInView } from 'react-hook-inview'
import styles from './styles/index.module.scss'

type ShoppableTeaser = {
  hotZones?: IHotzone[]
  hotZonesSettings?: IHotzoneSettings
  customerSegments?: string[]
}

export const ShoppableTeaser: React.FC<ShoppableTeaser> = ({ hotZones, hotZonesSettings, customerSegments }) => {
  const { t } = useTranslation()
  const [ref, inView] = useInView({
    unobserveOnEnter: true,
  })
  const productPartnumberList = useMemo(
    () =>
      hotZones?.reduce<string[]>((curr, hotzone) => {
        const partnumber = hotzone.linkedContent?.externalId?.split('/').pop()
        if (partnumber) curr.push(partnumber)
        return curr
      }, []) || [],
    [hotZones]
  )
  const [getproducts, result] = bffProductApi.endpoints.getProductsByPartnumber.useLazyQuery()

  useEffect(() => {
    if (productPartnumberList.length > 0 && inView) {
      getproducts({
        partnumber: productPartnumberList,
        profile: 'light_product',
      })
    }
  }, [getproducts, inView, productPartnumberList])

  if (result.isError) {
    console.error('get Shoppable hotZones ==>', { error: result.error, originalArgs: result.originalArgs })
    // Log.error(window?.location.href, 'get Shoppable hotZones')
    return null
  }

  return (
    <div
      ref={ref}
      className={clsx('shoppable-teaser', styles['shoppable-teaser'], {
        'light-contrast': hotZonesSettings?.lightContrast,
      })}
    >
      <button className={clsx('shoppable-teaser-icon', styles['shoppable-teaser-icon'])}>
        <CartIcon />
      </button>
      <div className={styles['shoppable-teaser-products']}>
        {productPartnumberList?.map(partnumber => {
          const productData: TLightProduct | undefined = result.data?.[partnumber]
          if (!productData) return null
          return (
            <LinkStyled href={productData.url || NOT_FOUND} key={productData.partnumberId}>
              <ProductMedia
                attachments={productData.attachments}
                sequence={'1.0'}
                usage={'PLP'}
                width={200}
                lazy={true}
              />
              {productData.prices && (
                <ProductPrice
                  prices={productData.prices as TProductPrice['prices']}
                  customerSegments={customerSegments || []}
                  priceSetting={{
                    pattern: '$##.##',
                  }}
                  discountSetting={{
                    visible: false,
                    offLabelKey: '',
                    pattern: '',
                    type: 'percentage',
                  }}
                  strikethrough={true}
                  prefixLabel={t('ProductTile.Labels.from')}
                />
              )}
            </LinkStyled>
          )
        })}
      </div>
    </div>
  )
}
