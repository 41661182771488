import styled from '@mui/material/styles/styled'
import React from 'react'
import IconWrapper from './IconWrapper'
import type { CustomSVGIconProps } from './icon.type'

const PaymentIconWrapper = styled(IconWrapper)`
  width: initial;
  height: initial;
`

// A

export const AddIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 24, height = 24, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7.5H11V11.5L7 11.5V13.5H11V17.5H13V13.5H17V11.5L13 11.5V7.5Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}
export const PlusIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 24, height = 24, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M14.7188 7.5C14.7188 7.86923 14.4184 8.16958 14.0492 8.16958H8.38833V13.8304C8.38833 14.1996 8.08798 14.5 7.71875 14.5C7.34952 14.5 7.04917 14.1996 7.04917 13.8304L7.04917 8.16958H1.38833C1.0191 8.16958 0.71875 7.86923 0.71875 7.5C0.71875 7.13077 1.0191 6.83042 1.38833 6.83042H7.04917V1.16958C7.04917 0.800355 7.34952 0.5 7.71875 0.5C8.08798 0.5 8.38833 0.800355 8.38833 1.16958V6.83042L14.0492 6.83042C14.4184 6.83042 14.7188 7.13077 14.7188 7.5Z"
        fill="#000F42"
      />
    </IconWrapper>
  )
}

export const AddressIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 48, height = 48, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24 0C14.079 0 6.036 8.043 6.036 17.964c0 10.414 12.116 24.619 16.447 29.365a2.049 2.049 0 0 0 3.034 0c4.33-4.746 16.447-18.951 16.447-29.365C41.964 8.043 33.92 0 24 0zm.039 45.981h-.08c-3.735-4.093-15.924-18.21-15.924-28.017C8.035 9.161 15.197 2 24 2s15.964 7.161 15.964 15.964c0 9.807-12.19 23.924-15.925 28.017z M24 10c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const AddressHome: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 48, height = 48, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M17.891 20.05H13.87a.388.388 0 0 1-.387-.387v-3.366c0-.808-.657-1.464-1.464-1.464-.807 0-1.465.657-1.465 1.464v3.366a.388.388 0 0 1-.387.387H5.983a.388.388 0 0 1-.388-.387v-8.035a.388.388 0 0 1 .775 0v7.647H9.78v-2.978a2.241 2.241 0 0 1 2.239-2.239 2.242 2.242 0 0 1 2.239 2.24v2.977h3.247v-7.647a.388.388 0 0 1 .774 0v8.035a.388.388 0 0 1-.387.387Zm1.934-8.972a.387.387 0 0 1-.546.044l-7.255-6.174-7.428 6.363a.388.388 0 0 1-.505-.589l1.503-1.288V6.405c0-.213.174-.387.388-.387h3.481c.036 0 .069.006.102.015l2.206-1.89a.387.387 0 0 1 .503 0l7.508 6.388a.387.387 0 0 1 .044.546h-.001ZM6.369 8.77l2.309-1.977H6.369V8.77Z"
        fill="currentColor"
      />
    </IconWrapper>
  )
}

export const AfterCareIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M47.84 24.866a3.952 3.952 0 0 0-2.642-2.687 4.023 4.023 0 0 0-3.515.566l-3.033 2.159-1.563-15.11A1.995 1.995 0 0 0 35.098 8H31V7c0-3.86-3.14-7-7-7s-7 3.14-7 7v1h-4.098a1.995 1.995 0 0 0-1.989 1.794L9.236 26H8a.988.988 0 0 0-.481.124l-7 3.847a.998.998 0 0 0-.519.876V47a1.001 1.001 0 0 0 1.496.868L8.266 44H29a.997.997 0 0 0 .656-.246l16.832-14.639c1.284-1.027 1.803-2.655 1.352-4.249zM19 7c0-2.757 2.243-5 5-5s5 2.243 5 5v1H19V7zm-6.098 3H17v4h2v-4h10v4h2v-4h4.098l1.68 16.238L28.681 32h-3.234a3.846 3.846 0 0 0 .547-1.787 3.969 3.969 0 0 0-1.092-2.966A4.02 4.02 0 0 0 22 26H11.247l1.655-16zm32.305 17.58L28.626 42H8a.993.993 0 0 0-.496.132L2 45.277V31.438L8.257 28H22c.554 0 1.07.222 1.451.624s.575.929.546 1.485C23.942 31.152 22.97 32 21.83 32H16v2h13c.208 0 .411-.065.58-.185l13.265-9.44a1.997 1.997 0 0 1 1.778-.28c.612.184 1.12.7 1.293 1.314v.001c.231.819-.022 1.62-.709 2.17z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const AddCircleIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 32,
  height = 32,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M16 0C7.178 0 0 7.178 0 16s7.178 16 16 16 16-7.178 16-16S24.822 0 16 0zm0 30.667c-8.087 0-14.667-6.58-14.667-14.667S7.913 1.333 16 1.333 30.667 7.913 30.667 16 24.087 30.667 16 30.667z"
        fill={htmlColor}
      />
      <path d="M16.665 9.333h-1.333v6h-6v1.334h6v6h1.333v-6h6v-1.334h-6v-6z" fill={htmlColor} />
    </IconWrapper>
  )
}

// B
export const BabySmileIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M20 21c0-2.757-2.243-5-5-5s-5 2.243-5 5h2c0-1.654 1.346-3 3-3s3 1.346 3 3h2zM33 16c-2.757 0-5 2.243-5 5h2c0-1.654 1.346-3 3-3s3 1.346 3 3h2c0-2.757-2.243-5-5-5zM18 30a6 6 0 1 0 12 0v-2H18v2zm10 0c0 2.206-1.794 4-4 4s-4-1.794-4-4h8z"
        fill={htmlColor}
      />
      <path
        d="M24 0C10.767 0 0 10.767 0 24s10.767 24 24 24 24-10.767 24-24S37.233 0 24 0zm0 46C11.869 46 2 36.131 2 24S11.869 2 24 2c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4h-2c0 3.309 2.691 6 6 6s6-2.691 6-6a5.952 5.952 0 0 0-1.093-3.441C38.683 4.795 46 13.556 46 24c0 12.131-9.869 22-22 22z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const BagIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M41.0764 12.829C40.9864 11.786 40.1294 11 39.0834 11H33.0014V9C33.0014 4.038 28.9634 0 24.0014 0C19.0394 0 15.0014 4.038 15.0014 9V11H8.91944C7.87344 11 7.01644 11.786 6.92644 12.829L4.19044 44.744C4.11944 45.579 4.40244 46.412 4.96944 47.029C5.53644 47.646 6.34244 48 7.18044 48H40.8234C41.6614 48 42.4674 47.646 43.0334 47.029C43.6004 46.412 43.8844 45.579 43.8124 44.744L41.0764 12.829ZM17.0014 9C17.0014 5.14 20.1414 2 24.0014 2C27.8614 2 31.0014 5.14 31.0014 9V11H17.0014V9ZM8.91944 13H15.0014V18H17.0014V13H31.0014V18H33.0014V13H39.0834L41.3114 39H6.69144L8.91944 13ZM41.5594 45.676C41.3674 45.885 41.1054 46 40.8224 46H7.18044C6.89744 46 6.63544 45.885 6.44344 45.676C6.25244 45.467 6.15944 45.197 6.18444 44.914L6.51944 41H41.4834L41.8194 44.915C41.8434 45.197 41.7514 45.467 41.5594 45.676Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const BestSellingIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M47.58 20.523c-.916-3.487-3.239-5.759-6.394-6.235A33.544 33.544 0 0 0 37 14.007V14H11v.007c-1.453.007-2.892.11-4.206.284-3.136.474-5.459 2.746-6.374 6.233-1.179 4.495.24 9.686 3.297 12.073 1.19.928 2.626 1.398 4.196 1.398.936 0 1.918-.167 2.924-.505 4.595-1.542 8.975-6.338 10.74-11.652 1.394-1.056 3.448-1.057 4.844-.001 1.752 5.3 6.14 10.109 10.741 11.653 1.006.337 1.988.505 2.924.505 1.57 0 3.007-.471 4.196-1.398 3.06-2.388 4.476-7.578 3.298-12.074zm-27.646-.156c-1.353 5.066-5.446 9.789-9.733 11.228-1.44.483-3.52.775-5.253-.574-2.426-1.895-3.565-6.282-2.593-9.99.413-1.574 1.577-4.285 4.72-4.76A31.574 31.574 0 0 1 11 15.998V16h.416c3.732.034 6.777.81 8.002 2.165.56.619.721 1.328.516 2.202zm2.061-1.013a4.163 4.163 0 0 0-1.095-2.53 5.59 5.59 0 0 0-.962-.823h8.12a5.583 5.583 0 0 0-.96.823 4.161 4.161 0 0 0-1.093 2.531A5.97 5.97 0 0 0 24 19c-.69 0-1.365.127-2.004.354zm21.056 11.667c-1.733 1.35-3.813 1.058-5.253.574-4.287-1.439-8.38-6.161-9.727-11.199-.212-.903-.05-1.612.51-2.231 1.227-1.356 4.278-2.132 8.008-2.165H37v-.001c1.247.007 2.561.091 3.907.269 3.161.478 4.325 3.189 4.738 4.763.972 3.707-.167 8.095-2.593 9.99z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// C
export const CalendarIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M46 4H37V0H35V4H25V0H23V4H13V0H11V4H2C0.897 4 0 4.897 0 6V46C0 47.103 0.897 48 2 48H46C47.103 48 48 47.103 48 46V6C48 4.897 47.103 4 46 4ZM46.001 46H2V16H46L46.001 46ZM2 14V6H11V10H13V6H23V10H25V6H35V10H37V6H46V14H2Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CalendarIconSmall: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M11.833 1.333v.5H15c.275 0 .5.225.5.5V15c0 .275-.225.5-.5.5H1a.501.501 0 0 1-.5-.5V2.333c0-.275.225-.5.5-.5h3.167V.5H4.5v1.333h3.333V.5h.334v1.333H11.5V.5h.333v.833zM4.167 2.667v-.5H.833v3.666h14.334V2.167h-3.334V3.5H11.5V2.167H8.167V3.5h-.334V2.167H4.5V3.5h-.333v-.833zm-3.334 12v.5h14.334v-9H.833v8.5z"
        fill={htmlColor}
        stroke={htmlColor}
      />
    </IconWrapper>
  )
}

export const CartIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M14.054 5.209A1.327 1.327 0 0 0 12.726 4h-1.393v-.667A3.337 3.337 0 0 0 8 0a3.337 3.337 0 0 0-3.333 3.333V4H3.274c-.693 0-1.263.52-1.328 1.209l-.84 8.969A1.67 1.67 0 0 0 2.764 16h10.47a1.67 1.67 0 0 0 1.66-1.822l-.841-8.969zM6 3.333c0-1.103.897-2 2-2s2 .897 2 2V4H6v-.667zm-1.333 2v1.334H6V5.333h4v1.334h1.333V5.333h1.393l.563 6H2.71l.563-6h1.393zm8.815 9.225a.33.33 0 0 1-.247.109H2.765a.33.33 0 0 1-.247-.11.33.33 0 0 1-.085-.255l.153-1.635h10.828l.153 1.635a.33.33 0 0 1-.085.256z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ChatIcon: React.FC<CustomSVGIconProps> = ({ width = 48, height = 48, htmlColor, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M46.271 4H1.729C.774 4 0 4.774 0 5.729v30.546c0 .955.774 1.729 1.729 1.729h28.305v4.174c0 1.042.853 1.732 1.741 1.732.425 0 .858-.158 1.21-.51l5.396-5.396h7.889c.955 0 1.729-.774 1.729-1.729V5.729A1.727 1.727 0 0 0 46.271 4zM46 36.004h-8.446l-.586.586-2.087 2.087-2.847 2.847v-5.519H2V6h44v30.004z"
        fill={htmlColor}
      />

      <path d="M26 19h-4v4h4v-4zM16 19h-4v4h4v-4zM36 19h-4v4h4v-4z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const ChevronLeftIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path d="M10.862 15.805 3.057 8 10.862.195l.943.943L4.943 8l6.862 6.862-.943.943z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const ChevronRightIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path d="m5.138 15.805-.943-.943L11.057 8 4.195 1.138l.943-.943L12.943 8l-7.805 7.805z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const ChooseIcon: React.FC<CustomSVGIconProps> = ({ width = 48, height = 48, htmlColor, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m6.766 17.722 1.922.553c.786-2.732 2.942-7.444 8.548-8.302l-.303-1.977c-4.9.75-8.604 4.294-10.167 9.726z"
        fill={htmlColor}
      />
      <path
        d="M16.55 43.834c-2.786 0-5.25-.828-7.312-2.455C3.228 36.593.43 25.682 2.867 16.54c1.03-3.867 3.919-10.53 11.65-11.715a74.334 74.334 0 0 1 9.806-.66c9.939 0 17.565 2.131 20.399 5.701 1.208 1.522 1.555 3.31 1.06 5.464a39.84 39.84 0 0 1-.821 2.693 7.428 7.428 0 0 1 1.975.391c.289-.859.556-1.726.787-2.603 2.28-9.799-9.914-13.646-23.401-13.646-3.345 0-6.766.236-10.07.677-17.474 2.675-18.043 30.717-6.26 40.103 2.52 1.988 5.45 2.891 8.558 2.891 1.137 0 2.298-.12 3.473-.354l-1.2-1.827c-.772.116-1.532.18-2.273.18z"
        fill={htmlColor}
      />

      <path
        d="M44.501 22c-.539 0-1.044.133-1.5.351V21.5c0-1.93-1.57-3.5-3.5-3.5-.98 0-1.864.407-2.5 1.058a3.485 3.485 0 0 0-2.5-1.058c-.539 0-1.044.133-1.5.351V11.5c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5v20.247l-1.42-1.286-2.878-2.604c-1.38-1.249-3.334-1.277-4.75-.064-1.415 1.211-1.689 3.145-.667 4.702L26.461 48H43.62l4.382-8.764V25.5c0-1.93-1.57-3.5-3.5-3.5zm1.5 16.764L42.383 46H27.541l-9.583-14.603c-.568-.866-.196-1.665.296-2.085.493-.422 1.34-.665 2.107.029l2.877 2.604 3.092 2.798a1.008 1.008 0 0 0 1.077.171 1 1 0 0 0 .594-.914V11.5c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5V28h2v-6.5c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5V28h2v-6.5c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5V28h2v-2.5c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5v13.264z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ClockIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 48, height = 48, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24.334 0c-13.233 0-24 10.767-24 24s10.767 24 24 24c13.232 0 24-10.767 24-24s-10.768-24-24-24zm0 46c-12.131 0-22-9.869-22-22s9.869-22 22-22c12.13 0 22 9.869 22 22s-9.87 22-22 22z"
        fill={htmlColor}
      />
      <path d="M25.334 7h-2v17.414l12.293 12.293 1.413-1.414-11.706-11.707V7z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const CopyIcon: React.FC<CustomSVGIconProps> = ({ width = 24, height = 24, htmlColor, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M 22.207 7 L 15.5 0.293 C 15.311 0.104 15.06 0 14.793 0 H 7.5 c -0.5515 0 -1 0.4485 -1 1 v 18 c 0 0.5515 0.4485 1 1 1 h 14 c 0.5515 0 1 -0.4485 1 -1 V 7.707 C 22.5 7.44 22.396 7.189 22.207 7 z M 15.5 1.707 L 20.793 7 L 15.5 7 V 1.707 z M 7.5 19 V 1 h 7 v 6.5 c 0 0.1325 0.0525 0.26 0.1465 0.3535 S 14.8675 8 15 8 l 6.5 0 l 0.0005 11 H 7.5 z M 16.5 23 H 2.5 V 5 h 2.5 V 4 H 2.5 c -0.5515 0 -1 0.4485 -1 1 v 18 c 0 0.5515 0.4485 1 1 1 h 14 c 0.5515 0 1 -0.4485 1 -1 v -1.5 h -1 V 23 z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CreditCardIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44 6H4a4 4 0 0 0-4 4v28a4 4 0 0 0 4 4h40a4 4 0 0 0 4-4V10a4 4 0 0 0-4-4zm2 32c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2V20h44v18zm0-20H2v-4h44v4zm0-6H2v-2c0-1.103.897-2 2-2h40c1.103 0 2 .897 2 2v2z"
        fill={htmlColor}
      />
      <path
        d="M11 37a4.963 4.963 0 0 0 3-1.018A4.963 4.963 0 0 0 17 37c2.757 0 5-2.243 5-5s-2.243-5-5-5a4.963 4.963 0 0 0-3 1.018A4.963 4.963 0 0 0 11 27c-2.757 0-5 2.243-5 5s2.243 5 5 5zm0-8c.864 0 1.662.369 2.248 1.038h1.505A2.955 2.955 0 0 1 17 29c1.654 0 3 1.346 3 3s-1.346 3-3 3a2.959 2.959 0 0 1-2.248-1.038h-1.505A2.955 2.955 0 0 1 11 35c-1.654 0-3-1.346-3-3s1.346-3 3-3z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const DisplayIcon: React.FC<CustomSVGIconProps> = ({ width = 48, height = 48, viewBox, htmlColor, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M45,18H33c-1.654,0-3,1.346-3,3v7.444V30H2V2h38v14h2V2c0-1.105-0.895-2-2-2H2C0.895,0,0,0.895,0,2v28c0,1.105,0.895,2,2,2
        h14v6h-2v2h10h2h2v-2h-2v-6h4v13c0,1.654,1.346,3,3,3h12c1.654,0,3-1.346,3-3V28.444V21C48,19.346,46.654,18,45,18z M24,38h-6v-6h6
        V38z M46,45c0,0.551-0.449,1-1,1H33c-0.551,0-1-0.449-1-1V21c0-0.551,0.449-1,1-1h12c0.551,0,1,0.449,1,1V45z"
        fill={htmlColor}
      />
      <path d="M40,41h-2c-0.552,0-1,0.448-1,1s0.448,1,1,1h2c0.552,0,1-0.448,1-1S40.552,41,40,41z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const DotIcon: React.FC<CustomSVGIconProps> = ({ width = 16, height = 16, viewBox, htmlColor, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
        fill={htmlColor}
      />
      <path d="M11.334 7.333H4.667v1.334h6.667V7.334z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const DropIcon: React.FC<CustomSVGIconProps> = ({ width = 48, height = 48, htmlColor, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M25.83 1.054A2.134 2.134 0 0 0 24 .027c-.751 0-1.436.383-1.83 1.026C17.91 8.006 8 24.938 8 32c0 8.822 7.178 16 16 16s16-7.178 16-16c0-7.062-9.91-23.994-14.17-30.946zM24 46V2.027c.081 0 .114.054.125.071C30.833 13.044 38 26.648 38 32c0 7.72-6.28 14-14 14z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// F

export const FacebookIcon: React.FC<CustomSVGIconProps> = ({ width = 24, height = 24, viewBox, ...rest }) => {
  return (
    <IconWrapper viewBox={viewBox ? viewBox : '0 0 24 24'} width={width} height={height} fill="none" {...rest}>
      <path
        className="st0"
        fill="#FFFFFF"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7,12.1c0-6.5-5.3-11.7-11.7-11.7S0.3,5.6,0.3,12.1c0,5.9,4.3,10.7,9.9,11.6v-8.2h-3v-3.4h3V9.5
  c0-2.9,1.8-4.6,4.4-4.6c1.3,0,2.6,0.2,2.6,0.2V8h-1.5c-1.5,0-1.9,0.9-1.9,1.8v2.2h3.3l-0.5,3.4h-2.7v8.2
  C19.4,22.8,23.7,17.9,23.7,12.1L23.7,12.1z"
      />
    </IconWrapper>
  )
}

export const FiltersIcon: React.FC<CustomSVGIconProps> = ({
  width = 16,
  height = 16,
  htmlColor = '#ffffff',
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M6 1.667v-1A.666.666 0 0 0 5.333 0H2.667A.666.666 0 0 0 2 .667v1H0v.666h2v1c0 .369.298.667.667.667h2.666A.666.666 0 0 0 6 3.333v-1h10v-.666H6zm-.667 1.666H2.667V.667h2.666v2.666zM10 6.667A.666.666 0 0 0 9.333 6H6.667A.666.666 0 0 0 6 6.667v1H0v.666h6v1c0 .369.298.667.667.667h2.666A.666.666 0 0 0 10 9.333v-1h6v-.666h-6v-1zm-.667 2.666H6.667V6.667h2.666v2.666zM14 12.667a.666.666 0 0 0-.667-.667h-2.666a.666.666 0 0 0-.667.667v1H0v.666h10v1c0 .369.298.667.667.667h2.666a.666.666 0 0 0 .667-.667v-1h2v-.666h-2v-1zm-.667 2.666h-2.666v-2.666h2.666v2.666z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FreeReturnIcon: React.FC<CustomSVGIconProps> = ({
  width = 32,
  height = 32,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m30.729 5.714-7.633-2.987-.006-.015-.016.006L16.728.235a2.003 2.003 0 0 0-1.457 0l-14 5.478A1.99 1.99 0 0 0 0 7.576v16.847c0 .83.499 1.56 1.271 1.863l14 5.478a2.006 2.006 0 0 0 1.458 0l2.147-.841a8.706 8.706 0 0 1-.675-1.167l-1.534.6V13.121l14-5.478v11.065c.478.308.925.66 1.333 1.052V7.576c0-.828-.499-1.56-1.271-1.862zM15.757 1.478a.663.663 0 0 1 .486 0l5.002 1.957L7.757 8.713l.006.015-5.267-2.062 13.26-5.188zm-.424 28.878L1.757 25.044a.663.663 0 0 1-.424-.621V7.643l14 5.478v17.235zM16 11.95 9.57 9.434 23.074 4.15l6.43 2.516L16 11.95z"
        fill={htmlColor}
      />
      <path
        d="M28 23.35v-.017h-5.24l2.195-2.195-.943-.943L20.207 24l3.805 3.805.943-.943-2.195-2.195h4.907c1.654 0 3 1.346 3 3s-1.346 3-3 3h-2.334V32h2.334A4.338 4.338 0 0 0 32 27.667a4.336 4.336 0 0 0-4-4.317z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// G
export const GbFlag: React.FC<CustomSVGIconProps> = ({ width = 30, height = 20, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g clipPath="url(#82igts38ba)">
        <mask id="0grl9v8h7b" maskUnits="userSpaceOnUse" x="0" y="0" width={width} height={height}>
          <path
            d="M27.222 0H3.612C2.076 0 .832 1.194.832 2.667v14.666C.833 18.806 2.077 20 3.611 20h23.611C28.756 20 30 18.806 30 17.333V2.667C30 1.194 28.756 0 27.222 0z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#0grl9v8h7b)">
          <path
            d="M27.222 0H3.612C2.076 0 .832 1.194.832 2.667v14.666C.833 18.806 2.077 20 3.611 20h23.611C28.756 20 30 18.806 30 17.333V2.667C30 1.194 28.756 0 27.222 0z"
            fill="#22438B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m5 1.333-2.814.035L2.222 4l23.586 14.704 2.83-.05-.053-2.616L5 1.333z"
            fill="#fff"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.611 1.333 2.222 2.666l25 16 1.39-1.333-25-16z"
            fill="#C7152A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.833 1.333h2.778V4S12.291 13.86 5.025 18.704c-.088.058-2.775.004-2.775.004l-.215-2.535 23.798-14.84z"
            fill="#fff"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m27.282 1.296 1.33 1.37-25 16-1.39-1.333 25.06-16.037z"
            fill="#C7152A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.944 1.333h6.945v5.333h9.722v6.667H18.89v5.333h-6.945v-5.333H2.222V6.666h9.722V1.333z"
            fill="#fff"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.333 1.333H17.5V8H28.61v4H17.5v6.666h-4.167V12H2.223V8h11.11V1.333z"
            fill="#C7152A"
          />
          <path
            d="M27.222.667H3.612c-1.151 0-2.084.895-2.084 2v14.667c0 1.104.933 2 2.083 2h23.611c1.15 0 2.084-.896 2.084-2V2.667c0-1.105-.933-2-2.084-2z"
            stroke="#000"
            strokeOpacity=".1"
          />
        </g>
      </g>
      <defs>
        <clipPath id="82igts38ba">
          <path fill="#fff" transform="translate(.833)" d="M0 0h29.167v20H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const GlobalCareIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M47.84 24.866A3.95 3.95 0 0 0 45.2 22.18a4.09 4.09 0 0 0-1.311-.15c.068-.674.112-1.351.112-2.03C44 8.972 35.028 0 24 0S4 8.972 4 20c0 2.525.487 4.968 1.399 7.288L.519 29.97a1 1 0 0 0-.519.877V47a1 1 0 0 0 1.496.868L8.266 44H29a1 1 0 0 0 .656-.246l16.832-14.639c1.285-1.027 1.803-2.655 1.352-4.249zm-6.156-2.12-7.63 5.43c.547-2.252.86-4.68.926-7.176h6.977a18.01 18.01 0 0 1-.17 1.69c-.033.021-.07.033-.103.056zM25 27.374V21h7.961c-.091 3.24-.608 6.345-1.54 9.05L28.681 32h-3.234c.318-.53.513-1.136.547-1.787a3.958 3.958 0 0 0-.994-2.84zM15.536 26a33.25 33.25 0 0 1-.511-5H23v5.144A3.956 3.956 0 0 0 22 26h-6.464zm-9.485-7A17.898 17.898 0 0 1 9.994 8.72c1.365.303 2.919.559 4.617.759-.93 2.777-1.493 6.015-1.584 9.521H6.05zm28.922 0c-.091-3.506-.654-6.744-1.584-9.521 1.698-.2 3.252-.456 4.617-.76A17.899 17.899 0 0 1 41.949 19h-6.976V19zM25 2.125c2.144.5 4.082 2.582 5.516 5.625-1.755.137-3.61.217-5.516.239V2.125zm-2 5.864a83.385 83.385 0 0 1-5.516-.239C18.918 4.707 20.856 2.625 23 2.125v5.864zm2 2c2.19-.025 4.32-.126 6.315-.3.96 2.684 1.559 5.885 1.659 9.31H25V9.99zm7.658-2.44c-.78-1.805-1.731-3.342-2.812-4.555a18.006 18.006 0 0 1 6.576 4.011 50.72 50.72 0 0 1-3.764.544zm-17.316 0a50.623 50.623 0 0 1-3.764-.544 18.005 18.005 0 0 1 6.576-4.011c-1.08 1.213-2.031 2.75-2.812 4.555zm1.343 2.14c1.996.174 4.125.275 6.315.3V19h-7.974c.1-3.426.7-6.627 1.659-9.31zM6.052 21h6.973c.045 1.709.204 3.382.48 5H8a1 1 0 0 0-.481.123l-.354.195A17.796 17.796 0 0 1 6.052 21zm39.155 6.58L28.626 42H8a1 1 0 0 0-.496.132L2 45.277V31.438L8.257 28H22c.554 0 1.07.222 1.451.623.382.402.575.93.546 1.486C23.942 31.152 22.97 32 21.83 32H16v2h13a1 1 0 0 0 .58-.185l13.265-9.44a1.998 1.998 0 0 1 1.778-.28c.612.184 1.12.7 1.293 1.314v.001c.231.819-.022 1.62-.709 2.17z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const GoogleIcon: React.FC<CustomSVGIconProps> = ({ width = 48, height = 48, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <defs>
        <path
          id="a"
          d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
        />
      </defs>
      <clipPath id="b">
        <use xlinkHref="#a" overflow="visible" />
      </clipPath>
      <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
      <path clipPath="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" />
      <path clipPath="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" />
      <path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
    </IconWrapper>
  )
}

export const GreenShippingIcon: React.FC<CustomSVGIconProps> = ({
  width = 32,
  height = 32,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g>
        <path
          d="m32.269 17.566-3.13-4.87A1.537 1.537 0 0 0 27.846 12h-4.68V6.063c0-.737-.596-1.334-1.333-1.334h-8v1.334h8V24H8.405a2.664 2.664 0 0 0-2.572-2 2.664 2.664 0 0 0-2.572 2H1.833v-6.666a6.1 6.1 0 0 1 .506-2.575 4.653 4.653 0 0 0 2.828.958c1.194 0 2.39-.454 3.3-1.364 2.989-2.99 3.024-8.715 3.024-8.957a.665.665 0 0 0-.667-.667c-.242 0-5.967.035-8.957 3.024a4.67 4.67 0 0 0-.523 5.97C.502 15.384.5 16.644.5 17.332V24c0 .737.597 1.334 1.333 1.334h1.428a2.664 2.664 0 0 0 2.572 2 2.664 2.664 0 0 0 2.572-2h16.19a2.664 2.664 0 0 0 2.572 2 2.664 2.664 0 0 0 2.572-2h1.237c.842 0 1.524-.664 1.524-1.482v-5.501c0-.277-.08-.55-.231-.785zm-29.46-8.87c2.036-2.036 5.772-2.498 7.318-2.603-.104 1.545-.564 5.277-2.604 7.317-1.245 1.245-3.232 1.284-4.54.142.456-.712 1.086-1.496 1.975-2.346l-.921-.964c-.806.77-1.42 1.497-1.9 2.177a3.334 3.334 0 0 1 .672-3.724zm4.358 15.97a1.335 1.335 0 0 1-2.667 0c0-.735.598-1.332 1.333-1.332.736 0 1.334.598 1.334 1.333zm20 1.334a1.335 1.335 0 0 1-1.334-1.333 1.335 1.335 0 0 1 2.667 0c0 .735-.598 1.333-1.333 1.333zm4-2.148c0 .072-.077.148-.191.148h-1.237a2.664 2.664 0 0 0-2.572-2 2.664 2.664 0 0 0-2.572 2h-1.428V13.334h4.679c.07 0 .138.032.17.084l3.13 4.869c.014.02.02.042.02.064v5.501z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath>
          <path fill="#fff" transform="translate(.5)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const GreenShippingIconDescription: React.FC<CustomSVGIconProps> = ({
  width,
  height,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m9.65 10.821.272-.272a2.992 2.992 0 0 0 1.869.654 2.99 2.99 0 0 0 2.121-.878c1.97-1.97 1.757-5.528 1.747-5.679a.333.333 0 0 0-.31-.31c-.15-.01-3.71-.223-5.68 1.747a3.004 3.004 0 0 0-.22 3.995l-.271.272c-.34.34-.623.721-.852 1.129a9.369 9.369 0 0 0-1.273-4.457 3.31 3.31 0 0 0 .961-2.341c0-.89-.346-1.727-.976-2.357C4.836.123.825.331.655.342a.333.333 0 0 0-.313.313c-.01.17-.22 4.181 1.982 6.383.63.63 1.467.976 2.357.976a3.31 3.31 0 0 0 1.851-.56 8.725 8.725 0 0 1 1.134 4.317v1.902c-3.864.069-6.989 1.297-7.124 1.35l.248.62c.033-.013 3.319-1.31 7.21-1.31 3.884 0 7.176 1.297 7.21 1.31l.247-.619c-.135-.054-3.25-1.278-7.108-1.35a4.46 4.46 0 0 1 1.3-2.853zm.491-4.267c1.424-1.424 3.915-1.561 4.821-1.561h.04c.004.872-.116 3.416-1.561 4.86-.83.83-2.13.9-3.043.22l1.17-1.171-.47-.471L9.925 9.6a2.335 2.335 0 0 1 .215-3.047zm-7.346.013C1.136 4.908 1 1.955 1 1.001h.022C2 1 4.917 1.147 6.566 2.796a2.668 2.668 0 0 1 .116 3.643 9.536 9.536 0 0 0-1.114-1.341l-.471.471c.407.408.769.848 1.084 1.316a2.67 2.67 0 0 1-3.386-.318z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// H
export const HeartIconSlim: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.565 6.85a13.395 13.395 0 0 0-9.5-3.936 13.394 13.394 0 0 0-9.5 3.935L24.5 7.886 23.435 6.85a13.394 13.394 0 0 0-9.5-3.935 13.394 13.394 0 0 0-9.5 3.935c-5.247 5.247-5.247 13.753 0 19L5.5 26.914 23.086 44.5c.391.391.902.586 1.414.586a1.99 1.99 0 0 0 1.414-.586L43.5 26.914l1.065-1.065c5.247-5.246 5.247-13.753 0-19zm-1.414 17.585L42.086 25.5 24.5 43.086 6.914 25.5 5.85 24.435A11.359 11.359 0 0 1 2.5 16.35a11.36 11.36 0 0 1 3.35-8.086 11.359 11.359 0 0 1 8.085-3.349c3.054 0 5.926 1.19 8.105 3.368l1.065 1.037 1.395 1.358 1.395-1.358 1.084-1.056a11.36 11.36 0 0 1 8.086-3.349 11.36 11.36 0 0 1 8.086 3.35 11.36 11.36 0 0 1 3.35 8.085c0 3.055-1.19 5.926-3.35 8.086z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const HeartIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      {/* <path
          d="M44.565 6.85a13.395 13.395 0 0 0-9.5-3.936 13.394 13.394 0 0 0-9.5 3.935L24.5 7.886 23.435 6.85a13.394 13.394 0 0 0-9.5-3.935 13.394 13.394 0 0 0-9.5 3.935c-5.247 5.247-5.247 13.753 0 19L5.5 26.914 23.086 44.5c.391.391.902.586 1.414.586a1.99 1.99 0 0 0 1.414-.586L43.5 26.914l1.065-1.065c5.247-5.246 5.247-13.753 0-19zm-1.414 17.585L42.086 25.5 24.5 43.086 6.914 25.5 5.85 24.435A11.359 11.359 0 0 1 2.5 16.35a11.36 11.36 0 0 1 3.35-8.086 11.359 11.359 0 0 1 8.085-3.349c3.054 0 5.926 1.19 8.105 3.368l1.065 1.037 1.395 1.358 1.395-1.358 1.084-1.056a11.36 11.36 0 0 1 8.086-3.349 11.36 11.36 0 0 1 8.086 3.35 11.36 11.36 0 0 1 3.35 8.085c0 3.055-1.19 5.926-3.35 8.086z"
          fill={htmlColor} />
        <path d="M25.055 7.342a.79.79 0 0 1-1.113-.003l-.182.505.666.24.416-.213.213-.53z"/> */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.983 3.227 8 4.225l-.983-.998-.004-.005a3.091 3.091 0 0 0-2.24-.972c-.806 0-1.616.32-2.242.972-1.263 1.318-1.263 3.47 0 4.789l5.47 5.706 5.469-5.706c1.263-1.318 1.263-3.47 0-4.789a3.091 3.091 0 0 0-2.242-.972c-.804 0-1.615.32-2.24.972l-.005.005zm-.657-.639L8 2.918l-.325-.33a4.223 4.223 0 0 0-.291-.277 3.99 3.99 0 0 0-2.612-.978c-1.05 0-2.101.419-2.903 1.255-1.603 1.673-1.603 4.384 0 6.057l5.7 5.946a.596.596 0 0 0 .863 0l5.7-5.946c1.603-1.672 1.603-4.384 0-6.057a4.007 4.007 0 0 0-2.903-1.255 3.99 3.99 0 0 0-2.612.978c-.1.087-.197.18-.291.277z"
        fill={htmlColor}
      />{' '}
    </IconWrapper>
  )
}

export const HeartFilledIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  viewBox,
  fill,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`} width={width} height={height} {...rest}>
      <path
        d="M 11.3563 2.185 z m 0 -1.2663 c -1.0763 0 -2.185 0.4113 -3.0077 1.235 L 8 2.47 l -0.3486 -0.3164 a 4.2683 4.2683 90 0 0 -3.0077 -1.235 a 4.3234 4.3234 90 0 0 -3.0086 1.235 C -0.0113 3.8 -0.0113 6.5236 1.635 8.17 l 0.3486 0.3486 l 5.5727 5.5727 c 0.1273 0.1263 0.285 0.19 0.4436 0.19 a 0.6251 0.6251 90 0 0 0.4436 -0.19 l 5.5727 -5.5727 l 0.3486 -0.3486 c 1.6463 -1.6463 1.6463 -4.37 0 -6.0164 a 4.3234 4.3234 90 0 0 -3.0086 -1.235 z"
        fill={fill}
        stroke={htmlColor}
        fillOpacity={'0.8'}
      />
    </IconWrapper>
  )
}

export const HomeIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 16,
  height = 16,
  ...rest
}: CustomSVGIconProps) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M15.725 5.61 8.391.284a.667.667 0 0 0-.783 0L.275 5.601a.666.666 0 0 0-.275.54v9.192c0 .368.298.666.667.666h14.666a.666.666 0 0 0 .667-.666V6.149a.667.667 0 0 0-.275-.54zM6 15.333v-4.667h4v4.667H6zm9.333 0h-4.666v-4.667A.667.667 0 0 0 10 10H6a.667.667 0 0 0-.667.667v4.667H.667V6.142L8 .824l7.333 5.325v9.185z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const HamburgerIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 17,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path d="M.667 4.348h14.666a.667.667 0 0 0 0-1.333H.667a.667.667 0 0 0 0 1.333z" fill={htmlColor} />
      <path
        d="M15.333 7.348H.667a.667.667 0 0 0 0 1.334h14.666a.667.667 0 0 0 0-1.334zM15.333 11.682H.667a.667.667 0 0 0 0 1.333h14.666a.667.667 0 0 0 0-1.333z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// I
export const IconMyDetails: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M11.81 4.776a2.863 2.863 0 0 1 2.86 2.86 2.863 2.863 0 0 1-2.86 2.86 2.863 2.863 0 0 1-2.86-2.86 2.863 2.863 0 0 1 2.86-2.86Zm0-.776a3.637 3.637 0 1 0 .002 7.274A3.637 3.637 0 0 0 11.81 4Zm7.81 15.612c0-4.307-3.504-7.81-7.81-7.81-4.306 0-7.81 3.503-7.81 7.81a.388.388 0 0 0 .776 0 7.042 7.042 0 0 1 7.034-7.034 7.042 7.042 0 0 1 7.035 7.034.388.388 0 0 0 .776 0ZM7.12 17.778a4.396 4.396 0 0 1 2.62-2.822.388.388 0 1 0-.278-.724 5.178 5.178 0 0 0-3.086 3.323.389.389 0 0 0 .744.224Z"
        fill="currentColor"
      />
    </IconWrapper>
  )
}

export const InfoIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, viewBox, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={viewBox ? viewBox : `0 0 ${width} 17`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M8 16.593c4.411 0 8-3.589 8-8 0-4.412-3.589-8-8-8s-8 3.588-8 8c0 4.411 3.589 8 8 8zM8 1.926a6.674 6.674 0 0 1 6.667 6.667A6.674 6.674 0 0 1 8 15.259a6.674 6.674 0 0 1-6.667-6.666A6.674 6.674 0 0 1 8 1.926z"
        fill="#00303C"
      />
      <path d="M7.333 9.926h1.334V4.592H7.333v5.334zM7.333 12.593h1.334v-1.334H7.333v1.334z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const InfoOutlinedIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
        fill={htmlColor}
      />
      <path d="M8.667 6.667H7.334V12h1.333V6.667zM8.667 4H7.334v1.333h1.333V4z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const InfoDniIcon: React.FC<CustomSVGIconProps> = ({ width = 21, height = 21, viewBox, ...rest }) => {
  return (
    <IconWrapper viewBox={viewBox ? viewBox : `0 0 ${width} 17`} width={width} height={height} fill="none" {...rest}>
      <svg viewBox="0 0 17 17" fill="#0137EF" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.95 16.08C4.53815 16.08 0.949997 12.4908 0.949997 8.08002C0.949997 3.66928 4.53815 0.0800171 8.95 0.0800171C13.3618 0.0800171 16.95 3.66928 16.95 8.08002C16.95 12.4908 13.3607 16.08 8.95 16.08ZM8.95 0.853889C4.9655 0.853889 1.72498 4.09552 1.72498 8.07891C1.72498 12.0623 4.9666 15.3039 8.95 15.3039C12.9334 15.3039 16.175 12.0623 16.175 8.07891C16.175 4.09552 12.9334 0.853889 8.95 0.853889ZM8.80607 11.7313C8.5614 11.7313 8.33333 11.7202 8.10859 11.7346C7.87278 11.749 7.73106 11.8907 7.72774 12.0833C7.72331 12.2859 7.86835 12.4564 8.10416 12.4609C8.83486 12.4741 9.56555 12.4797 10.2962 12.4631C10.604 12.4564 10.7723 12.1298 10.593 11.9018C10.5188 11.8066 10.3605 11.749 10.232 11.7324C10.0195 11.7047 9.80026 11.7246 9.56998 11.7246C9.56998 11.5951 9.56998 11.5032 9.56998 11.4113C9.56998 9.91229 9.56998 8.41326 9.56998 6.91423C9.56998 6.83673 9.56998 6.75812 9.56223 6.68062C9.53123 6.40606 9.41166 6.29314 9.13046 6.28871C8.80386 6.28317 8.47726 6.28317 8.15177 6.28871C7.89492 6.29314 7.72664 6.45035 7.72774 6.66955C7.72996 6.88433 7.88385 7.01497 8.14734 7.0194C8.36544 7.02272 8.58354 7.0194 8.80829 7.0194V11.7302L8.80607 11.7313ZM8.16063 4.38115C8.16505 4.76643 8.48833 5.09192 8.86143 5.08749C9.24338 5.08306 9.55337 4.76311 9.55227 4.3734C9.55116 3.99588 9.24117 3.68588 8.86475 3.68588C8.47394 3.68588 8.1562 4.0003 8.16173 4.38115H8.16063Z"
          fill="#0137EF"
          stroke="Solid"
        />
      </svg>
    </IconWrapper>
  )
}

export const LocationIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24 11h-2.05c-.471-4.717-4.233-8.479-8.95-8.95V0h-2v2.05C6.283 2.522 2.521 6.284 2.05 11H0v2h2.05c.472 4.717 4.233 8.479 8.95 8.95V24h2v-2.05c4.717-.471 8.479-4.233 8.95-8.95H24v-2zm-11 8.93V17h-2v2.93A8.007 8.007 0 0 1 4.07 13H7v-2H4.07A8.007 8.007 0 0 1 11 4.07V7h2V4.07A8.007 8.007 0 0 1 19.93 11H17v2h2.93A8.007 8.007 0 0 1 13 19.93z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LookIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, viewBox, width = 24, height = 24, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.105 22.165c-.618-.721-1.624-1.089-3.132-1.152l.233-1.744c.475-3.562-.045-6.98-1.505-9.886a17.212 17.212 0 0 0-2.542-3.734A7.975 7.975 0 0 0 39.5 0h-18a5.01 5.01 0 0 0-4.908 4.041C11.502 4.501 7.5 8.792 7.5 14l.484 4 .363 3.004c-1.688.022-2.79.39-3.453 1.16-.907 1.057-.685 2.509-.469 3.914l.084.562C4.813 28.775 5.272 32 8.5 32c.4 0 .786-.057 1.158-.155l1.21 9.998A7.007 7.007 0 0 0 17.815 48h13.431c3.494 0 6.477-2.612 6.938-6.075l1.338-10.032c.316.069.643.107.977.107 3.228 0 3.687-3.225 3.99-5.359l.084-.562c.216-1.405.44-2.857-.469-3.914zM31.247 46h-13.43a5.004 5.004 0 0 1-4.964-4.398l-2.89-23.85c4.981-.305 8.046-2.183 9.11-5.639C20.595 13.575 23.595 15 29.5 15c5.148 0 8.784 1.068 9.837 2.864-.025.377-.062.757-.113 1.14l-3.02 22.657A5.015 5.015 0 0 1 31.246 46zM37.15 2c-.826 2.327-3.043 4-5.65 4v2a7.952 7.952 0 0 0 3.06-.611c.352-.147.69-.323 1.015-.517a15.155 15.155 0 0 1 2.34 3.408c.731 1.457 1.182 3.071 1.364 4.775C36.592 13.316 32.265 13 29.5 13c-9.697 0-9.999-3.982-10-4h-2c0 2.769-.846 6.396-7.778 6.777L9.5 13.941C9.532 9.557 13.108 6 17.5 6h1V5c0-1.654 1.346-3 3-3h15.65zM8.5 30c-1.157 0-1.608-.816-2.01-3.641l-.088-.585c-.154-1.001-.299-1.948.01-2.307.15-.175.62-.467 2.088-.467h.09l.824 6.817A2.594 2.594 0 0 1 8.5 30zm34.098-4.226-.088.585C42.108 29.184 41.657 30 40.5 30a2.55 2.55 0 0 1-.71-.11l.918-6.885c1.296.028 1.737.296 1.88.462.308.36.163 1.306.01 2.307z"
        fill={htmlColor}
      />
      <path d="M26.513 36h-4.027v2h4.027v-2zM24.934 31h-2.448v2h4.448v-9.943h-2V31z" fill={htmlColor} />
    </IconWrapper>
  )
}

//M

export const MapMarkIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, viewBox, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 0a5.988 5.988 0 0 0-5.988 5.988c0 3.471 4.038 8.206 5.482 9.788a.683.683 0 0 0 1.011 0c1.444-1.582 5.483-6.317 5.483-9.788A5.988 5.988 0 0 0 8 0zm0 14.342C5.668 11.68 3.345 8.24 3.345 5.988A4.66 4.66 0 0 1 8 1.333a4.66 4.66 0 0 1 4.654 4.655c0 2.252-2.322 5.693-4.654 8.354z"
        fill={htmlColor}
      />
      <path
        d="M8 3.333a2.667 2.667 0 1 0 0 5.334 2.667 2.667 0 0 0 0-5.333zm0 4A1.335 1.335 0 0 1 6.666 6a1.335 1.335 0 0 1 2.667 0c0 .736-.598 1.333-1.333 1.333z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const MaskIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, viewBox, width = 48, height = 48, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44,15h-6.135C33.849,11.289,25.437,9,24,9c-1.513,0-9.886,2.289-13.879,6.001c0,0,0,0,0.001-0.001H4c-2.2,0-4,1.8-4,4v4
    c0,2.2,1.8,4,4,4h5.055c0-0.001,0-0.001,0-0.002C11.19,34.419,16.482,39,24,39s12.81-4.581,14.946-12.002c0,0.001,0,0.001,0,0.002
    H44c2.2,0,4-1.8,4-4v-4C48,16.8,46.2,15,44,15z M38.356,15.486c-0.003-0.003-0.006-0.007-0.009-0.01
    C38.35,15.479,38.353,15.482,38.356,15.486z M9.643,15.475c-0.004,0.004-0.007,0.008-0.011,0.012
    C9.636,15.483,9.639,15.479,9.643,15.475z M8.573,25H4c-1.103,0-2-0.897-2-2v-4c0-1.103,0.897-2,2-2h4.53
    C8.195,17.638,8,18.305,8,19C8,21.137,8.201,23.14,8.573,25C8.573,25,8.573,25,8.573,25z M24,37c-8.635,0-14-6.897-14-18
    c0-1.609,2.246-3.626,6.008-5.397c3.672-1.728,7.345-2.576,7.99-2.603C26.072,11.095,38,15.028,38,19C38,30.103,32.635,37,24,37z
     M46,23c0,1.103-0.897,2-2,2h-4.573c0,0,0,0,0,0C39.799,23.14,40,21.137,40,19c0-0.695-0.196-1.362-0.533-2H44c1.103,0,2,0.897,2,2
    V23z M15.884,19.745l0.231,1.986c2.397-0.279,5.097-0.303,7.884-0.303s5.487,0.023,7.884,0.303l0.231-1.986
    c-2.503-0.292-5.267-0.316-8.116-0.316S18.387,19.453,15.884,19.745z"
        fill={htmlColor}
      />
      <path
        d="M24,25.583c-2.787,0-5.487-0.023-7.884-0.303l-0.231,1.986c2.503,0.292,5.267,0.316,8.116,0.316s5.613-0.024,8.116-0.316
    l-0.231-1.986C29.487,25.56,26.787,25.583,24,25.583z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const OfferIcon: React.FC<CustomSVGIconProps> = ({ width = 48, height = 48, htmlColor, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M47.429,23.009l-5.243-3.045c-0.545-0.317-0.732-1.015-0.418-1.562l3.018-5.258c0.438-0.763-0.111-1.715-0.991-1.717
    l-6.063-0.016c-0.631-0.002-1.142-0.513-1.143-1.143l-0.016-6.063c-0.002-0.668-0.55-1.145-1.148-1.145
    c-0.19,0-0.385,0.048-0.569,0.154l-5.258,3.018c-0.18,0.103-0.376,0.152-0.57,0.152c-0.395,0-0.78-0.205-0.992-0.571l-3.045-5.243
    C24.77,0.19,24.385,0,24,0s-0.77,0.19-0.991,0.571l-3.045,5.243c-0.213,0.366-0.597,0.571-0.992,0.571
    c-0.194,0-0.39-0.049-0.57-0.152l-5.258-3.018C12.96,3.108,12.765,3.06,12.575,3.06c-0.598,0-1.146,0.477-1.148,1.145l-0.016,6.063
    c-0.002,0.631-0.513,1.142-1.143,1.143l-6.063,0.016c-0.88,0.002-1.429,0.954-0.991,1.717l3.018,5.258
    c0.314,0.547,0.127,1.245-0.418,1.562l-5.243,3.045c-0.761,0.442-0.761,1.541,0,1.982l5.243,3.045
    c0.545,0.317,0.732,1.015,0.418,1.562l-3.018,5.258c-0.438,0.763,0.111,1.715,0.991,1.717l6.063,0.016
    c0.631,0.002,1.142,0.513,1.143,1.143l0.016,6.063c0.002,0.668,0.55,1.145,1.148,1.145c0.19,0,0.385-0.048,0.569-0.154l5.258-3.018
    c0.18-0.103,0.376-0.152,0.57-0.152c0.395,0,0.78,0.205,0.992,0.571l3.045,5.243C23.23,47.81,23.615,48,24,48
    s0.77-0.19,0.991-0.571l3.045-5.243c0.213-0.366,0.597-0.571,0.992-0.571c0.194,0,0.39,0.049,0.57,0.152l5.258,3.018
    c0.184,0.106,0.379,0.154,0.569,0.154c0.598,0,1.146-0.477,1.148-1.145l0.016-6.063c0.002-0.631,0.513-1.142,1.143-1.143
    l6.063-0.016c0.88-0.002,1.429-0.954,0.991-1.717l-3.018-5.258c-0.314-0.547-0.127-1.245,0.418-1.562l5.243-3.045
    C48.19,24.549,48.19,23.451,47.429,23.009z M41.182,26.307c-1.493,0.867-2.008,2.79-1.149,4.287l2.286,3.984l-4.593,0.012
    c-1.726,0.004-3.134,1.412-3.138,3.138l-0.012,4.593l-3.984-2.286c-0.476-0.273-1.017-0.418-1.565-0.418
    c-1.118,0-2.161,0.6-2.722,1.566L24,45.154l-2.307-3.972c-0.561-0.966-1.604-1.566-2.722-1.566c-0.548,0-1.089,0.144-1.565,0.418
    l-3.983,2.286l-0.012-4.593c-0.004-1.726-1.412-3.134-3.138-3.138L5.68,34.577l2.286-3.983c0.859-1.497,0.344-3.42-1.149-4.287
    L2.846,24l3.972-2.307c1.493-0.867,2.008-2.79,1.149-4.287L5.68,13.423l4.593-0.012c1.726-0.004,3.134-1.412,3.138-3.138
    l0.012-4.593l3.984,2.286c0.476,0.273,1.017,0.418,1.565,0.418c1.118,0,2.161-0.6,2.722-1.566L24,2.846l2.307,3.972
    c0.561,0.966,1.604,1.566,2.722,1.566c0.548,0,1.089-0.144,1.565-0.418l3.983-2.286l0.012,4.593
    c0.004,1.726,1.412,3.134,3.138,3.138l4.593,0.012l-2.286,3.983c-0.859,1.497-0.344,3.42,1.149,4.287L45.154,24L41.182,26.307z M22,18c0-2.209-1.791-4-4-4s-4,1.791-4,4c0,2.209,1.791,4,4,4S22,20.209,22,18z M16,18c0-1.103,0.897-2,2-2s2,0.897,2,2
    s-0.897,2-2,2S16,19.103,16,18z M30,26c-2.209,0-4,1.791-4,4c0,2.209,1.791,4,4,4s4-1.791,4-4C34,27.791,32.209,26,30,26z M30,32c-1.103,0-2-0.897-2-2
    c0-1.103,0.897-2,2-2s2,0.897,2,2C32,31.103,31.103,32,30,32z M15.29,31.29l16,-16l1.41,1.41l-16,16Z"
        fill={htmlColor}
      />

      <path
        d="m31.293 15.293-16 16 1.414 1.414 16-16-1.415-1.414zM30 26a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM22 18a4 4 0 1 0-8 0 4 4 0 0 0 8 0zm-6 0c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const OpticianIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M12.333 5.8c-1.414 0-2.64.481-3.25 1.185a1.993 1.993 0 0 0-2.165 0C6.307 6.281 5.082 5.8 3.667 5.8 1.642 5.8 0 6.785 0 8c0 1.215 1.642 2.2 3.667 2.2S7.333 9.215 7.333 8c0-.151-.025-.299-.074-.441a1.321 1.321 0 0 1 1.481.001 1.367 1.367 0 0 0-.073.44c0 1.215 1.641 2.2 3.666 2.2S16 9.215 16 8c0-1.215-1.642-2.2-3.667-2.2zM3.667 9.533c-1.768 0-3-.808-3-1.533 0-.726 1.232-1.534 3-1.534s3 .808 3 1.534c0 .725-1.232 1.533-3 1.533zm8.666 0c-1.768 0-3-.808-3-1.533 0-.726 1.232-1.534 3-1.534s3 .808 3 1.534c0 .725-1.232 1.533-3 1.533z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const OptometristIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24.334 24c6.617 0 12-5.383 12-12s-5.384-12-12-12c-6.617 0-12 5.383-12 12s5.383 12 12 12zm0-22c5.514 0 10 4.486 10 10s-4.487 10-10 10c-5.514 0-10-4.486-10-10s4.486-10 10-10zM34.334 26.067H32.05l-7.718 7.718-7.717-7.718h-2.282c-8.014 0-14 9.433-14 17.866V48h2v-4.066c0-6.205 3.754-13.242 9-15.272v5.44a4.999 4.999 0 0 0 1 9.898 5 5 0 0 0 1-9.9v-5.954a7.9 7.9 0 0 1 1-.078h1.454l8.546 8.545 8.546-8.546h1.453c.339 0 .67.037 1 .078v5.96a4.864 4.864 0 0 0-3.856 4.752V44h2v-5.143A2.86 2.86 0 0 1 36.334 36a2.86 2.86 0 0 1 2.856 2.857V44h2v-5.143a4.864 4.864 0 0 0-3.856-4.752V28.66c5.244 2.03 9 9.067 9 15.272V48h2v-4.066c0-8.434-5.988-17.867-14-17.867zM15.334 39c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

// P
export const PadlockIcon: React.FC<CustomSVGIconProps> = ({ width = 48, height = 48, htmlColor, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M42 16h-8v-6c0-5.794-4.206-10-10-10S14 4.206 14 10v6H6a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V18a2 2 0 0 0-2-2zm-26-6c0-4.71 3.29-8 8-8s8 3.29 8 8v6H16v-6zm26 36H6V18h36v28z"
        fill={htmlColor}
      />

      <path
        d="M24 36c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const PrescriptionIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox = '3.997 -0.00000342882 23.98 32',
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M11.992 16h1.332v-2.666h2.665V12h-2.665V9.334h-1.332V12H9.327v1.334h2.665V16zM9.327 20h13.324v1.333H9.327zM9.327 25.334h9.327v1.333H9.327z"
        fill={htmlColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.59 9.333 18.654.391A1.323 1.323 0 0 0 17.712 0H5.33c-.735 0-1.333.598-1.333 1.333v29.334c0 .735.598 1.333 1.333 1.333h21.318c.735 0 1.333-.598 1.333-1.333V10.276c0-.356-.139-.69-.39-.943zm-8.936-7.057 7.052 7.057h-7.052V2.276zM5.33 1.333v29.334h21.319v-20h-8.661A.668.668 0 0 1 17.32 10V1.333H5.33z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const PlpFullWidthListingIcon = ({
  htmlColor = '#D8D8D8',
  width = 24,
  height = 24,
  viewBox,
  ...rest
}: CustomSVGIconProps) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path d="M23.938 0v15H0V0h23.938zM0 19.2h24V24H0v-4.8z" fill={`${htmlColor}`} />
    </IconWrapper>
  )
}

export const PlpCompactWidthListingIcon = ({
  htmlColor = '#00303C',
  width = 24,
  height = 24,
  viewBox,
  ...rest
}: CustomSVGIconProps) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M9.6 0v9.6H0V0h9.6zM24 0v9.545h-9.6V0H24zM0 14.4h9.545V24H0v-9.6zM14.455 14.438h9.504v9.5h-9.504v-9.5z"
        fill={`${htmlColor}`}
      />
    </IconWrapper>
  )
}

export const PhoneIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 18, height = 17, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M13.564 16.5C12.6574 16.4746 11.7357 16.2352 10.8348 15.8963C9.1278 15.2545 7.61744 14.2946 6.24701 13.1C4.72509 11.7735 3.45527 10.2354 2.40519 8.51568C1.82579 7.56736 1.3632 6.56354 1.13422 5.46489C0.995443 4.79991 0.966531 4.12684 1.03708 3.45493C1.08681 2.99118 1.17817 2.52975 1.2915 2.07756C1.3898 1.68436 1.57253 1.31891 1.85239 1.01476C2.28029 0.549854 2.81111 0.414547 3.41942 0.549855C3.88086 0.652781 4.281 0.888703 4.66957 1.14197C5.54503 1.71558 6.27823 2.43491 6.83797 3.32193C7.02879 3.62493 7.156 3.95221 7.13287 4.32344C7.11784 4.56168 7.04382 4.77794 6.88654 4.95141C6.69225 5.16536 6.46674 5.35039 6.27245 5.56319C6.13252 5.71584 5.99605 5.88122 5.90238 6.06279C5.72313 6.40973 5.8353 6.74742 6.01571 7.05851C7.12131 8.95861 8.62473 10.4643 10.5202 11.5769C11.0094 11.8637 11.2164 11.8718 11.7033 11.4254C12.0918 11.0692 12.5082 10.7581 12.9604 10.4886C13.2576 10.3117 13.5317 10.2967 13.8243 10.4944C14.7807 11.1386 15.6746 11.8579 16.4067 12.7542C16.9282 13.3937 17.1191 14.1107 16.7675 14.9017C16.5269 15.4441 16.1048 15.8188 15.5833 16.0825C14.9692 16.3948 14.3065 16.4884 13.564 16.5Z"
        stroke={htmlColor}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </IconWrapper>
  )
}

//Q
export const QuestionMarkIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = 'none',
  width = 16,
  height = 16,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 0a7.98 7.98 0 0 0-8 8c0 4.433 3.567 8 8 8s8-3.567 8-8-3.567-8-8-8zm0 14.667c-3.667 0-6.667-3-6.667-6.667s3-6.667 6.667-6.667 6.667 3 6.667 6.667-3 6.667-6.667 6.667z"
        fill={htmlColor}
      />
      <path
        d="M8.666 11.666H7.333V13h1.333v-1.334zM8.667 3.067c-1-.2-2 .066-2.767.7a3.237 3.237 0 0 0-1.233 2.566H6c0-.6.267-1.166.734-1.533A1.884 1.884 0 0 1 8.4 4.4a2.04 2.04 0 0 1 1.567 1.567c.2.933-.3 1.866-1.167 2.233-.9.367-1.466 1.2-1.466 2.133h1.333c0-.4.233-.733.667-.933A3.345 3.345 0 0 0 11.3 5.667c-.3-1.3-1.333-2.334-2.633-2.6z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const RetinalScreeningIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.435 22.989c-.508-.782-1.557-1.689-3.685-1.927l.242-1.939v-.124H41c0-.031-.007-.057-.007-.087L41 17c0-4.206-1.544-8.053-4.084-11.024A6.978 6.978 0 0 0 39 1V0H21a5.006 5.006 0 0 0-4.904 4.046C11.005 4.505 7 8.791 7 14v3H4v-3H2v3H0v19h2v12h2V36h6.108a14.023 14.023 0 0 0 1.956 2.732l6.17 6.731A7.845 7.845 0 0 0 24 48a7.844 7.844 0 0 0 5.765-2.536l6.17-6.731a13.906 13.906 0 0 0 3.411-6.749c.1.006.198.016.299.016 2.687 0 3.745-2.423 4.679-4.561l.235-.533c.625-1.407.58-2.834-.124-3.917zM34.46 37.381l-6.17 6.731A5.835 5.835 0 0 1 24 46a5.835 5.835 0 0 1-4.29-1.888l-6.17-6.731c-.4-.436-.761-.899-1.089-1.381H20c3.309 0 6-2.691 6-6v-7c0-3.309-2.691-6-6-6h-5.702c2.799-1.274 3.966-3.547 4.424-5.519C20.522 13.08 24.116 15 31 15c7.029 0 7.982 2.1 7.998 3.951l-1.476 11.81a11.932 11.932 0 0 1-3.062 6.62zM2 19h18c2.206 0 4 1.794 4 4v7c0 2.206-1.794 4-4 4H2V19zM36.899 2c-.035.17-.095.33-.146.494-.16.509-.397.979-.701 1.403C35.144 5.163 33.673 6 32 6v2a6.953 6.953 0 0 0 3.302-.831 14.93 14.93 0 0 1 3.5 7.517C36.381 13.011 32.616 13 31 13c-10.052 0-12.058-4.341-12.071-4.371L17 9c0 3.193-1.388 7-8 7v-2c0-4.411 3.589-8 8-8h1V5c0-1.654 1.346-3 3-3h15.899zm5.832 24.094-.24.545C41.581 28.723 40.956 30 39.645 30l-.012-.001.869-6.951c1.102.119 1.893.472 2.257 1.031.448.691.175 1.559-.028 2.015z"
        fill={htmlColor}
      />

      <path
        d="M19 26a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM16 31a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM13 26a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ReplaceIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, viewBox, width = 48, height = 48, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M32 37h9.741C37.602 42.664 31.1 46 24 46 11.869 46 2 36.131 2 24H0c0 13.233 10.767 24 24 24 7.547 0 14.473-3.456 19-9.348V48h2V35H32v2zM24 0C16.453 0 9.528 3.456 5 9.348V0H3v13h13v-2H6.258C10.398 5.336 16.9 2 24 2c12.131 0 22 9.869 22 22h2C48 10.767 37.233 0 24 0z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const RemoveCircleIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 32,
  height = 32,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M16 0C7.178 0 0 7.178 0 16s7.178 16 16 16 16-7.178 16-16S24.822 0 16 0zm0 30.667c-8.087 0-14.667-6.58-14.667-14.667S7.913 1.333 16 1.333 30.667 7.913 30.667 16 24.087 30.667 16 30.667z"
        fill={htmlColor}
      />
      <path d="M22.665 15.333H9.332v1.334h13.333v-1.334z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const RemoveIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, viewBox, width = 16, height = 16 }) => {
  return (
    <IconWrapper viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`} width={width} height={height} fill="none">
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.849609 1.50001C0.849609 1.14102 1.14062 0.850006 1.49961 0.850006H13.9996C14.3586 0.850006 14.6496 1.14102 14.6496 1.50001C14.6496 1.85899 14.3586 2.15001 13.9996 2.15001H1.49961C1.14062 2.15001 0.849609 1.85899 0.849609 1.50001Z"
        fill="#000F42"
      />
    </IconWrapper>
  )
}

// S
export const ScrollArrowUpIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, viewBox, width = 16, height = 16 }) => {
  return (
    <IconWrapper viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`} width={width} height={height} fill="none">
      <path
        d="M14.862 11.8063L7.99998 4.94459L1.13798 11.8063L0.195312 10.8636L7.99998 3.05859L15.8046 10.8636L14.862 11.8063Z"
        fill={`${htmlColor} !important`}
      />
    </IconWrapper>
  )
}

export const SearchIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, viewBox, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m15.805 14.862-3.977-3.977a6.666 6.666 0 1 0-.943.943l3.977 3.977.943-.943zM6.667 12a5.34 5.34 0 0 1-5.334-5.333 5.34 5.34 0 0 1 5.334-5.334A5.34 5.34 0 0 1 12 6.667 5.34 5.34 0 0 1 6.667 12z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ShoppingIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox = '3.4489 0 26.41 32',
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M28.03 8.553a1.326 1.326 0 0 0-1.328-1.22H22.65V6c0-3.308-2.69-6-5.995-6a6.005 6.005 0 0 0-5.996 6v1.333H6.607c-.697 0-1.268.524-1.328 1.22L3.456 29.829a2.006 2.006 0 0 0 .52 1.524c.377.411.914.647 1.472.647h22.413a2.003 2.003 0 0 0 1.992-2.17L28.03 8.552zM11.991 6a4.67 4.67 0 0 1 4.663-4.667A4.67 4.67 0 0 1 21.319 6v1.333h-9.327V6zM6.607 8.667h4.052V12h1.332V8.667h9.327V12h1.332V8.667h4.052L28.186 26H5.123L6.607 8.667zM28.352 30.45a.66.66 0 0 1-.491.216H5.448a.66.66 0 0 1-.49-.216.66.66 0 0 1-.173-.508l.223-2.61h23.293l.224 2.61a.659.659 0 0 1-.173.508z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}
export const SlashSlimIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#000000',
  width = 24,
  height = 24,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper {...rest} viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`} width={width} height={height}>
      <path fill={htmlColor} fillRule="evenodd" d="m 14 10 L 10 16 L 9 16 l 4 -6 Z" clipRule="evenodd" />
    </IconWrapper>
  )
}

export const SocialDistanceIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 10.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0zm-1 0C6.5 9.121 5.378 8 4 8a2.503 2.503 0 0 0-2.5 2.5C1.5 11.879 2.622 13 4 13s2.5-1.121 2.5-2.5zM0 18.6C0 16.612 1.791 15 4 15s4 1.612 4 3.6v4.5c0 .497-.447.9-1 .9H1c-.552 0-1-.403-1-.9v-4.5zM6 23h1v-4.4C7 17.166 5.654 16 4 16s-3 1.166-3 2.6V23h5zM20 14a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7zm0-1c1.378 0 2.5-1.121 2.5-2.5S21.378 8 20 8a2.503 2.503 0 0 0-2.5 2.5c0 1.379 1.122 2.5 2.5 2.5zM16 18.6c0-1.988 1.791-3.6 4-3.6s4 1.612 4 3.6v4.5c0 .497-.448.9-1 .9h-6c-.552 0-1-.403-1-.9v-4.5zm6 4.4h1v-4.4c0-1.434-1.346-2.6-3-2.6s-3 1.166-3 2.6V23h5z"
        fill={htmlColor}
      />
      <path
        d="M6.414 4.268 5.146 3h13.707l-1.267 1.268.707.707L20.767 2.5 18.293.025l-.707.707L18.853 2H5.146L6.414.732 5.707.025 3.232 2.5l2.475 2.475.707-.707z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const StoreManagerIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 10H32a6 6 0 0 0-6-6h-4a6 6 0 0 0-6 6H2a2 2 0 0 0-2 2v30a2 2 0 0 0 2 2h44a2 2 0 0 0 2-2V12a2 2 0 0 0-2-2zM22 6h4c2.206 0 4 1.794 4 4H18c0-2.206 1.794-4 4-4zm-4 6h28c0 7.72-6.28 14-14 14h-4v-2a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2h-4C8.28 26 2 19.72 2 12h16zm8 12v6h-4v-6h4zM2 42V19.74C4.728 24.664 9.972 28 16 28h4v2a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-2h4c6.028 0 11.272-3.336 14-8.26V42H2z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const StoresIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, viewBox, width = 48, height = 48, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.5 16c1.103 0 2-.897 2-2v-4c0-1.103-.897-2-2-2h-2.8l-2.697-6.743A2 2 0 0 0 39.146 0H9.854a2 2 0 0 0-1.857 1.257L5.3 8H2.5c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h2v30h-4v2h48v-2h-4V16h2zM9.854 2h29.292l2.4 6H7.454l2.4-6zM2.5 14v-4h44l.001 4H2.5zm10 32V32h8v14h-8zm16 0V32h8v14h-8zm14 0h-4V32c0-1.103-.897-2-2-2h-8c-1.103 0-2 .897-2 2v14h-4V32c0-1.103-.897-2-2-2h-8c-1.103 0-2 .897-2 2v14h-4V16h36v30z"
        fill={htmlColor}
      />
      <path d="M38.5 24h-28v2h28v-2z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const SeparatorIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 1, height = 17, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <rect y="0.5" width="1" height="16" fill={htmlColor} />
    </IconWrapper>
  )
}

export const TempleSizeIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M2 27v-4h4v-2H0v8h6v-2H2zM47.085 32.556 33.181 22.515A8.001 8.001 0 0 0 28.497 21H18v2h10.497c1.269 0 2.484.393 3.513 1.136l13.904 10.042c.012.009.037.027.041.073a.09.09 0 0 1-.029.079l-2.042 2.042a2.976 2.976 0 0 1-2.117.879 2.967 2.967 0 0 1-2.462-1.28l-.019-.027-.019-.026-4.401-5.873a10.047 10.047 0 0 0-8.002-4.003 10 10 0 0 0-2.41.295l-2.67.663H18v2h4.028l2.907-.722a8.002 8.002 0 0 1 8.33 2.967l4.401 5.873a4.983 4.983 0 0 0 4.1 2.133c1.27 0 2.546-.479 3.531-1.465l2.042-2.042a2.1 2.1 0 0 0-.254-3.188zM11 21v8H9v2h6v-2h-2v-8h2v-2H9v2h2z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const TestIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, viewBox, width = 48, height = 48, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44 0H4a2 2 0 0 0-2 2v44a2 2 0 0 0 2 2h40a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 46H4V2h40v44z"
        fill={htmlColor}
      />
      <path
        d="M20 40h2v-6h4v6h2v-8h-8v8zM18 38h-6v-4h6v-2h-8v8h8v-2zM30 34h6v4h-6v2h8v-8h-8v2zM21 26h10v-5h-2v3h-8v-6h5v-2h-5v-6h8v3h2V8H16v2h3v14h-3v2h5z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const TriangleDangerIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={viewBox || `0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M25 16h-2v18h2V16zM25 38h-2v2h2v-2z M46.778 43.941 25.89 2.168A2.088 2.088 0 0 0 24 1c-.75 0-1.501.389-1.89 1.168L1.224 43.941C.522 45.346 1.543 47 3.114 47h41.774c1.57 0 2.593-1.654 1.89-3.059zm-1.794 1.005c-.033.054-.07.054-.097.054H3.115c-.027 0-.063 0-.097-.054-.033-.054-.017-.087-.005-.11L23.899 3.063C23.916 3.031 23.93 3 24 3c.07 0 .085.031.102.063l20.886 41.773c.012.023.028.056-.005.11z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// Z
export const ZoomIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#00303C',
  width = 32,
  height = 32,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g filter="url(#8afjeh34ua)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.436 19.73a6.5 6.5 0 1 0-.707.707l4.418 4.417.707-.707-4.418-4.418z"
          fill="#fff"
        />
      </g>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.436 19.73a6.5 6.5 0 1 0-.707.707l4.418 4.417.707-.707-4.418-4.418zM15.5 21a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zM12 15.002v1h3V19h1v-2.998h3v-1h-3V12h-1v3.002h-3z"
        fill={htmlColor}
      />
      <defs>
        <filter
          id="8afjeh34ua"
          x="6"
          y="7"
          width="21.855"
          height="21.854"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1587_230429" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1587_230429" result="shape" />
        </filter>
      </defs>
    </IconWrapper>
  )
}

export const GeopositionIcon: React.FC<CustomSVGIconProps> = ({ fill = '#000', width = 16, height = 16, viewBox }) => {
  return (
    <IconWrapper width={width} height={height} viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`} fill="none">
      <path
        d="M16 7.333h-1.367a6.678 6.678 0 0 0-5.966-5.966V0H7.333v1.367a6.678 6.678 0 0 0-5.966 5.966H0v1.334h1.367a6.679 6.679 0 0 0 5.966 5.966V16h1.334v-1.367a6.679 6.679 0 0 0 5.966-5.966H16V7.333zm-7.333 5.954v-1.954H7.333v1.954a5.338 5.338 0 0 1-4.62-4.62h1.954V7.333H2.713a5.338 5.338 0 0 1 4.62-4.62v1.954h1.334V2.713a5.338 5.338 0 0 1 4.62 4.62h-1.954v1.334h1.954a5.338 5.338 0 0 1-4.62 4.62z"
        fill={fill}
      />
    </IconWrapper>
  )
}

export const CameraIcon: React.FC<CustomSVGIconProps> = ({ width = 48, height = 48, htmlColor, viewBox, ...rest }) => {
  return (
    <IconWrapper viewBox={viewBox ? viewBox : '14 6 20 20'} width={width} height={height} fill="none" {...rest}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M31 11.9999H28.138L26.569 10.4309C26.5067 10.3683 26.4217 10.3333 26.3333 10.3333H21.6667C21.5783 10.3333 21.4933 10.3683 21.431 10.4309L19.862 11.9999H17C16.4487 11.9999 16 12.4486 16 12.9999V20.3333C16 20.8846 16.4487 21.3333 17 21.3333H31C31.5513 21.3333 32 20.8846 32 20.3333V12.9999C32 12.4486 31.5513 11.9999 31 11.9999ZM31.3333 20.3333C31.3333 20.5173 31.1837 20.6666 31 20.6666H17C16.8163 20.6666 16.6667 20.5173 16.6667 20.3333V12.9999C16.6667 12.8159 16.8163 12.6666 17 12.6666H20C20.0853 12.6666 20.1707 12.6339 20.2357 12.5689L21.8047 10.9999H26.1953L27.7643 12.5689C27.8293 12.6339 27.9147 12.6666 28 12.6666H31C31.1837 12.6666 31.3333 12.8159 31.3333 12.9999V20.3333Z"
        fill={htmlColor}
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M24 13.3333C22.346 13.3333 21 14.6789 21 16.3333C21 17.9876 22.346 19.3333 24 19.3333C25.654 19.3333 27 17.9876 27 16.3333C27 14.6789 25.654 13.3333 24 13.3333ZM24 18.6666C22.7133 18.6666 21.6667 17.6196 21.6667 16.3333C21.6667 15.0469 22.7133 13.9999 24 13.9999C25.2867 13.9999 26.3333 15.0469 26.3333 16.3333C26.3333 17.6196 25.2867 18.6666 24 18.6666Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const MobileIcon: React.FC<CustomSVGIconProps> = ({ width = 48, height = 48, htmlColor, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M34 0H14C11.243 0 9 2.243 9 5V43C9 45.757 11.243 48 14 48H34C36.757 48 39 45.757 39 43V5C39 2.243 36.757 0 34 0ZM37 43C37 44.654 35.654 46 34 46H14C12.346 46 11 44.654 11 43V5C11 3.346 12.346 2 14 2H34C35.654 2 37 3.346 37 5V43Z"
        fill={htmlColor}
      />
      <path d="M28 6H20V8H28V6Z" fill={htmlColor} />
      <path
        d="M24 42C25.1046 42 26 41.1046 26 40C26 38.8954 25.1046 38 24 38C22.8954 38 22 38.8954 22 40C22 41.1046 22.8954 42 24 42Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const AntiStaticCoatingIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g clipPath="url(#clip0_4653_366)">
        <path
          d="M6.764 17.723L8.686 18.276C9.472 15.544 11.628 10.832 17.234 9.974L16.931 7.997C12.032 8.746 8.327 12.291 6.764 17.723Z"
          fill={htmlColor}
        />
        <path
          d="M16.55 44C13.766 44 11.306 43.166 9.243 41.528C3.204 36.681 0.411001 25.645 2.886 16.404C3.924 12.529 6.82 5.852 14.52 4.664C17.798 4.224 21.096 4 24.323 4C34.233 4 41.85 6.138 44.698 9.72C45.926 11.264 46.281 13.081 45.781 15.272C44.807 18.979 43.268 22.605 41.27 26H43.568C45.353 22.792 46.784 19.34 47.724 15.749C50.004 5.876 37.809 2 24.323 2C20.978 2 17.556 2.238 14.253 2.682C-3.222 5.376 -3.791 33.631 7.992 43.087C10.512 45.09 13.441 46 16.55 46C19.597 46 22.818 45.124 26 43.551V41.302C22.749 43.054 19.507 44 16.55 44Z"
          fill={htmlColor}
        />
        <path
          d="M32 34C33.1046 34 34 33.1046 34 32C34 30.8954 33.1046 30 32 30C30.8954 30 30 30.8954 30 32C30 33.1046 30.8954 34 32 34Z"
          fill={htmlColor}
        />
        <path
          d="M46 48C47.1046 48 48 47.1046 48 46C48 44.8954 47.1046 44 46 44C44.8954 44 44 44.8954 44 46C44 47.1046 44.8954 48 46 48Z"
          fill={htmlColor}
        />
        <path
          d="M38 48C39.1046 48 40 47.1046 40 46C40 44.8954 39.1046 44 38 44C36.8954 44 36 44.8954 36 46C36 47.1046 36.8954 48 38 48Z"
          fill={htmlColor}
        />
        <path
          d="M32 42C33.1046 42 34 41.1046 34 40C34 38.8954 33.1046 38 32 38C30.8954 38 30 38.8954 30 40C30 41.1046 30.8954 42 32 42Z"
          fill={htmlColor}
        />
        <path
          d="M46 40C47.1046 40 48 39.1046 48 38C48 36.8954 47.1046 36 46 36C44.8954 36 44 36.8954 44 38C44 39.1046 44.8954 40 46 40Z"
          fill={htmlColor}
        />
        <path
          d="M40 34C41.1046 34 42 33.1046 42 32C42 30.8954 41.1046 30 40 30C38.8954 30 38 30.8954 38 32C38 33.1046 38.8954 34 40 34Z"
          fill={htmlColor}
        />
        <path
          d="M39 41C40.1046 41 41 40.1046 41 39C41 37.8954 40.1046 37 39 37C37.8954 37 37 37.8954 37 39C37 40.1046 37.8954 41 39 41Z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4653_366">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const UVProtectionIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g clipPath="url(#clip0_4653_162)">
        <path
          d="M6.76401 17.723L8.68601 18.276C9.47201 15.544 11.628 10.832 17.234 9.974L16.931 7.997C12.032 8.746 8.32701 12.291 6.76401 17.723Z"
          fill={htmlColor}
        />
        <path
          d="M16.55 44C13.766 44 11.306 43.166 9.24301 41.528C3.20401 36.681 0.411009 25.645 2.88601 16.404C3.92401 12.529 6.82001 5.852 14.52 4.664C17.798 4.224 21.096 4 24.323 4C34.233 4 41.85 6.138 44.698 9.72C45.926 11.264 46.281 13.081 45.781 15.272C44.789 19.049 43.216 22.745 41.163 26.195C41.851 26.31 42.521 26.476 43.168 26.696C45.138 23.293 46.715 19.603 47.724 15.751C50.004 5.876 37.809 2 24.323 2C20.978 2 17.557 2.238 14.253 2.682C-3.22199 5.376 -3.79099 33.631 7.99201 43.087C10.512 45.09 13.441 46 16.55 46C19.821 46 23.293 44.987 26.703 43.188C26.484 42.544 26.316 41.878 26.2 41.194C22.881 43.015 19.567 44 16.55 44Z"
          fill={htmlColor}
        />
        <path d="M40 30H38V32H40V30Z" fill={htmlColor} />
        <path d="M33.3428 31.9289L31.9286 33.3431L33.3428 34.7573L34.757 33.3431L33.3428 31.9289Z" fill={htmlColor} />
        <path d="M32 38H30V40H32V38Z" fill={htmlColor} />
        <path d="M33.3424 43.2429L31.9282 44.6571L33.3424 46.0713L34.7566 44.6571L33.3424 43.2429Z" fill={htmlColor} />
        <path d="M40 46H38V48H40V46Z" fill={htmlColor} />
        <path d="M44.6561 43.2427L43.2419 44.6569L44.6561 46.0711L46.0703 44.6569L44.6561 43.2427Z" fill={htmlColor} />
        <path d="M48 38H46V40H48V38Z" fill={htmlColor} />
        <path d="M44.6566 31.9287L43.2424 33.3429L44.6566 34.7571L46.0708 33.3429L44.6566 31.9287Z" fill={htmlColor} />
        <path
          d="M39 34C36.239 34 34 36.239 34 39C34 41.761 36.239 44 39 44C41.761 44 44 41.761 44 39C44 36.239 41.761 34 39 34ZM39 42C37.346 42 36 40.654 36 39C36 37.346 37.346 36 39 36C40.654 36 42 37.346 42 39C42 40.654 40.654 42 39 42Z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4653_162">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const BetterClarityIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g clipPath="url(#clip0_4653_187)">
        <path
          d="M47.781 23.083C43.352 14.395 34.848 8.42 25 8.037V0H23V8.037C13.152 8.42 4.648 14.395 0.219 23.083C-0.073 23.655 -0.073 24.346 0.219 24.918C4.648 33.605 13.152 39.58 23 39.963V48H25V39.963C34.848 39.58 43.352 33.605 47.781 24.917C48.073 24.345 48.073 23.655 47.781 23.083ZM23 31.931C19.06 31.436 16 28.072 16 24C16 19.928 19.06 16.564 23 16.069V31.931ZM25 16.069C28.94 16.564 32 19.928 32 24C32 28.072 28.94 31.436 25 31.931V16.069ZM2.001 23.991C6.239 15.676 14.221 10.411 23 10.036V14.05C17.954 14.553 14 18.823 14 23.999C14 29.175 17.954 33.445 23 33.948V37.962C14.221 37.589 6.239 32.323 2.001 23.991ZM25 37.964V33.95C30.046 33.447 34 29.177 34 24.001C34 18.825 30.046 14.555 25 14.052V10.038C33.779 10.413 41.761 15.679 45.999 24.011C41.761 32.324 33.779 37.589 25 37.964Z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4653_187">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const FramesIcon: React.FC<CustomSVGIconProps> = ({ width = 28, height = 20, htmlColor, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height / 2}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24.6651 3.2615C24.2076 1.518 23.0461 0.382 21.4686 0.144C20.8156 0.0580001 20.0986 0.00649998 19.3751 0.00349998V0H6.37512V0.00349998C5.64862 0.00699998 4.92912 0.0585002 4.27212 0.1455C2.70412 0.3825 1.54262 1.5185 1.08512 3.262C0.495616 5.5095 1.20462 8.105 2.73362 9.2985C3.32812 9.7625 4.04662 9.9975 4.83162 9.9975C5.29962 9.9975 5.79062 9.914 6.29362 9.745C8.59112 8.974 10.7811 6.576 11.6631 3.919C12.3606 3.391 13.3876 3.3905 14.0856 3.9185C14.9616 6.5685 17.1551 8.973 19.4561 9.745C19.9591 9.9135 20.4501 9.9975 20.9181 9.9975C21.7031 9.9975 22.4216 9.762 23.0161 9.2985C24.5456 8.1045 25.2541 5.5095 24.6651 3.2615ZM10.8426 3.1835C10.1661 5.7165 8.11962 8.078 5.97612 8.7975C5.25562 9.039 4.21612 9.185 3.34962 8.5105C2.13662 7.563 1.56712 5.3695 2.05312 3.5155C2.25962 2.7285 2.84162 1.373 4.41312 1.1355C5.08962 1.0465 5.75012 1.0025 6.37562 0.999V1H6.58362C8.44962 1.017 9.97212 1.405 10.5846 2.0825C10.8641 2.392 10.9451 2.7465 10.8426 3.1835ZM11.8731 2.677C11.8381 2.2065 11.6561 1.777 11.3256 1.412C11.1876 1.2595 11.0236 1.125 10.8446 1.0005H14.9046C14.7261 1.125 14.5616 1.2595 14.4241 1.412C14.0951 1.776 13.9131 2.2045 13.8781 2.6775C13.5586 2.5635 13.2201 2.5 12.8751 2.5C12.5301 2.5 12.1926 2.5635 11.8731 2.677ZM22.4011 8.5105C21.5346 9.1855 20.4946 9.0395 19.7746 8.7975C17.6311 8.078 15.5841 5.717 14.9111 3.198C14.8051 2.7465 14.8861 2.392 15.1661 2.0825C15.7796 1.4045 17.3051 1.0165 19.1701 1H19.3751V0.9995C19.9986 1.003 20.6556 1.045 21.3286 1.134C22.9091 1.373 23.4911 2.7285 23.6976 3.5155C24.1836 5.369 23.6141 7.563 22.4011 8.5105Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FrameAccessoriesIcon: React.FC<CustomSVGIconProps> = ({
  width = 28,
  height = 20,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height / 2}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M12.375 9C12.9273 9 13.375 8.55228 13.375 8C13.375 7.44772 12.9273 7 12.375 7C11.8227 7 11.375 7.44772 11.375 8C11.375 8.55228 11.8227 9 12.375 9Z"
        fill={htmlColor}
      />
      <path
        d="M23.375 0H1.375C0.8225 0 0.375 0.4475 0.375 1V11C0.375 11.5525 0.8225 12 1.375 12H23.375C23.9275 12 24.375 11.5525 24.375 11V1C24.375 0.4475 23.9275 0 23.375 0ZM12.375 11C9.6485 11 9.1295 9.553 8.344 7.363C7.7265 5.6415 7.0265 3.6895 5.0135 2.14C4.4545 1.7095 3.7525 1.4865 3.074 1.2715C2.795 1.183 2.522 1.0965 2.279 1.0005H22.47C22.2275 1.0965 21.9555 1.183 21.677 1.2715C20.998 1.487 20.296 1.7095 19.7365 2.14C17.724 3.69 17.024 5.6415 16.406 7.363C15.6205 9.553 15.1015 11 12.375 11ZM1.375 11V1.673C1.7895 1.91 2.2835 2.07 2.771 2.2245C3.367 2.4135 3.9835 2.609 4.403 2.9315C6.1835 4.303 6.831 6.108 7.4025 7.7005C7.855 8.9625 8.296 10.1835 9.227 11H1.375ZM15.523 11C16.454 10.1835 16.895 8.963 17.3475 7.7005C17.919 6.1075 18.5665 4.303 20.347 2.9315C20.7665 2.6085 21.383 2.413 21.9795 2.224C22.4665 2.0695 22.961 1.9105 23.3755 1.6745V11H15.523Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const DownloadIcon: React.FC<CustomSVGIconProps> = ({
  width = 28,
  height = 20,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M220-160q-24 0-42-18t-18-42v-143h60v143h520v-143h60v143q0 24-18 42t-42 18H220Zm260-153L287-506l43-43 120 120v-371h60v371l120-120 43 43-193 193Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const TickIcon: React.FC<CustomSVGIconProps> = ({ width = 24, height = 24, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.435 14.126a.362.362 0 0 1-.256-.106l-3.016-3.016a.36.36 0 1 1 .51-.51l2.76 2.76 7.95-7.949a.36.36 0 1 1 .511.51l-8.203 8.204a.36.36 0 0 1-.256.105v.002Zm7.433-1.691a.361.361 0 0 0-.722 0 6.72 6.72 0 0 1-6.712 6.712 6.72 6.72 0 0 1-6.712-6.712 6.72 6.72 0 0 1 6.712-6.713.361.361 0 0 0 0-.722C7.334 5 4 8.335 4 12.434s3.335 7.434 7.434 7.434 7.434-3.335 7.434-7.434Zm-7.025 5.22c0-.199-.162-.36-.36-.36a4.858 4.858 0 0 1-4.585-3.255.36.36 0 1 0-.681.238 5.58 5.58 0 0 0 5.265 3.74c.2 0 .361-.163.361-.362Z"
        fill="currentColor"
      />
    </IconWrapper>
  )
}

export const TrashBinIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 18, height = 18, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M16 3.16667H11.6667V1.22367C11.6667 0.824 11.3427 0.5 10.943 0.5H5.057C4.65733 0.5 4.33333 0.824 4.33333 1.22367V3.16667H0V4.5H1.55667L2.89 14.7577C3.01967 15.7543 3.86867 16.5 4.87333 16.5H11.1267C12.1317 16.5 12.9803 15.7543 13.11 14.7577L14.4433 4.5H16V3.16667ZM5.66667 1.83333H10.3333V3.16667H5.66667V1.83333ZM11.7877 14.586C11.7447 14.917 11.4603 15.1667 11.1267 15.1667H4.87333C4.53933 15.1667 4.25533 14.917 4.21233 14.586L2.90133 4.5H13.099L11.7877 14.586Z"
        fill={`${htmlColor} !important`}
      />
      <path
        d="M9.66825 5.77875L9.00195 13.7783L10.3306 13.889L10.9969 5.88942L9.66825 5.77875Z"
        fill={`${htmlColor} !important`}
      />
      <path
        d="M6.33062 5.77801L5.00195 5.88867L5.66825 13.8882L6.99692 13.7776L6.33062 5.77801Z"
        fill={`${htmlColor} !important`}
      />
    </IconWrapper>
  )
}

export const EditIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 24, height = 24, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path d="M16 15.833H0V16.4997H16V15.833Z" fill={`${htmlColor} !important`} />
      <path
        d="M1.99767 14.5C2.04133 14.5 2.086 14.4957 2.131 14.4867L4.46867 13.873C4.59767 13.8473 4.71633 13.7837 4.80933 13.6907L15.1377 3.362C15.398 3.10167 15.398 2.67967 15.1377 2.41933L13.4137 0.695333C13.2837 0.565 13.113 0.5 12.9423 0.5C12.7717 0.5 12.601 0.565 12.471 0.695333L2.14233 11.024C2.04933 11.117 1.98567 11.2357 1.96 11.3647L1.34633 13.7023C1.262 14.1237 1.58967 14.5 1.99767 14.5ZM2.605 11.534L2.61 11.515L2.614 11.4957L12.942 1.16667C12.942 1.16667 12.9423 1.16667 12.9427 1.16667L14.6663 2.89067L4.33767 13.2193L4.31833 13.2233L4.29933 13.2283L2.002 13.8317L2.605 11.534Z"
        fill={`${htmlColor} !important`}
      />
    </IconWrapper>
  )
}
