import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgLensHolder = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.766 6.243c-.016.003-.031.003-.045.003v.002a.384.384 0 0 1-.044-.765c.309-.036.629-.052.943-.045a.383.383 0 0 1-.016.766 6.095 6.095 0 0 0-.838.04Zm4.393 2.092c0-.88-.969-1.685-2.412-2h-.001a.384.384 0 0 1 .164-.75c1.804.394 3.016 1.499 3.016 2.75a.384.384 0 0 1-.767 0Zm-6.286-1.55c.06 0 .12-.013.175-.042.208-.106.433-.2.672-.277a.383.383 0 1 0-.234-.73c-.28.09-.545.2-.79.325a.383.383 0 0 0 .177.726v-.001Zm10.017 3.562a6.6 6.6 0 0 1 .974.114l.005-.003c1.89.366 3.161 1.482 3.161 2.779v3.227c0 1.626-1.955 2.9-4.45 2.9-1.451 0-2.717-.43-3.524-1.11l-6.312-4.378c-.014-.01-.026-.022-.038-.033l-.002-.001c-1.025-.527-1.674-1.346-1.674-2.283 0-.025.003-.05.008-.072L4.03 8.333c0-.553.233-1.09.675-1.55a.384.384 0 0 1 .553.532c-.3.313-.46.666-.46 1.018 0 1.156 1.686 2.134 3.682 2.134.906 0 1.78-.199 2.461-.56a.384.384 0 0 1 .359.68c-.692.364-1.545.586-2.437.636v1.138a.384.384 0 0 1-.768 0v-1.138a6.515 6.515 0 0 1-1.373-.216v.747a.384.384 0 0 1-.767 0v-1.018a3.993 3.993 0 0 1-1.155-.75l.005 1.704c.12 1.099 1.754 2.004 3.675 2.004.298 0 .596-.022.884-.064a.384.384 0 1 1 .111.76 6.969 6.969 0 0 1-1.579.047l3.247 2.253a1.898 1.898 0 0 1-.014-.224c0-.024.003-.048.007-.072l-.007-3.154c0-.553.232-1.09.674-1.55.242-.252.542-.477.893-.67.309-.17.653-.311 1.022-.42a6.42 6.42 0 0 1 1.059-.214 7.125 7.125 0 0 1 1.113-.039Zm2.148 4.468c.776-.411 1.22-.984 1.22-1.575 0-.903-1.044-1.737-2.54-2.026a6.011 6.011 0 0 0-.862-.1 6.359 6.359 0 0 0-.99.034c-.323.037-.636.101-.93.188-.318.093-.61.213-.872.357-.282.154-.52.333-.71.53-.301.314-.46.665-.46 1.017 0 1.156 1.686 2.134 3.683 2.134.906 0 1.78-.199 2.46-.56ZM11.9 16.597c.12 1.099 1.753 2.004 3.674 2.004 1.996 0 3.683-.978 3.683-2.134v-1.586a3.81 3.81 0 0 1-.863.612c-.095.05-.194.099-.296.144v1.08a.384.384 0 0 1-.767 0v-.81a6.56 6.56 0 0 1-1.373.223v1.138a.384.384 0 0 1-.767 0V16.13a6.503 6.503 0 0 1-1.373-.216v.747a.384.384 0 0 1-.767 0v-1.018a3.994 3.994 0 0 1-1.155-.75l.004 1.704Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  )
}
export default SvgLensHolder
