import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMoneyBackGuaranteePoundPriceMatch = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M10.395 13.675v-1.277h-.58a.388.388 0 0 1 0-.775h.58v-.783c0-.7.18-1.257.533-1.658.36-.408.868-.617 1.51-.624h.045a2.23 2.23 0 0 1 2.093 1.579.388.388 0 0 1-.743.223 1.453 1.453 0 0 0-1.373-1.027c-.425.002-.737.12-.95.362-.224.255-.338.64-.338 1.145v.783h1.6a.388.388 0 0 1 0 .775h-1.6v1.277c0 .427-.091.798-.272 1.107h3.276a.388.388 0 0 1 0 .776h-4.43a.388.388 0 0 1-.387-.388c0-.186.132-.354.315-.389a.767.767 0 0 0 .517-.329c.136-.197.205-.458.205-.777h-.001Zm6.628-7.893a8.009 8.009 0 0 0-9.378-.369l.15-.916a.388.388 0 0 0-.538-.418.415.415 0 0 0-.172.139.383.383 0 0 0-.068.164l-.295 1.81a.44.44 0 0 0 .082.323c.06.082.149.135.248.152l1.816.3a.387.387 0 0 0 .424-.243.466.466 0 0 0 .028-.116l.003-.02a.388.388 0 0 0-.324-.424l-.865-.142a7.23 7.23 0 0 1 8.41.371 7.219 7.219 0 0 1 1.203 10.127 7.163 7.163 0 0 1-4.815 2.7 7.165 7.165 0 0 1-5.312-1.496 7.218 7.218 0 0 1-2.74-5.29.387.387 0 1 0-.774.04 7.988 7.988 0 0 0 3.034 5.859 7.929 7.929 0 0 0 5.884 1.657 7.934 7.934 0 0 0 5.332-2.989c2.725-3.46 2.127-8.49-1.333-11.216v-.003Z"
      />
    </SvgIcon>
  )
}
export default SvgMoneyBackGuaranteePoundPriceMatch
