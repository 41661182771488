import { Player } from '@lottiefiles/react-lottie-player'

export interface LoadingSpinnerProps {
  size?: number
  loading?: boolean
}

function LoadingSpinner(props: LoadingSpinnerProps) {
  const { size = 100, loading = true } = props
  const extraStyle = loading ? {} : { display: 'none' }
  return <Player autoplay loop src="/spinner.json" style={{ height: size, width: size, ...extraStyle }} />
}

export default LoadingSpinner
