import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgReturningCustomer = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="m6.695 12.322.705 3.877a.395.395 0 0 0 .02.07c.214.55.733.906 1.323.906h6.534c.59 0 1.109-.356 1.323-.906.017-.044.057-.148.727-3.948.407-.139.7-.525.7-.978v-.067c0-.57-.462-1.033-1.032-1.033h-.508l-1.79-2.809a.387.387 0 1 0-.655.417l1.525 2.39H8.503l1.525-2.39a.387.387 0 1 0-.654-.417l-1.79 2.809h-.56c-.57 0-1.032.463-1.032 1.033v.067c0 .454.294.84.703.979Zm.072-1.046c0-.142.115-.257.257-.257h9.972c.142 0 .257.115.257.257v.067a.257.257 0 0 1-.257.257h-.006a.41.41 0 0 0-.072.008.386.386 0 0 0-.307.313c-.256 1.455-.677 3.817-.74 4.084a.638.638 0 0 1-.593.393H8.744a.639.639 0 0 1-.587-.38l-.663-3.643h8.042a.388.388 0 0 0 0-.776H7.025a.257.257 0 0 1-.258-.257v-.068.002Zm3.525 4.216a.277.277 0 0 1-.546.095l-.4-2.305a.277.277 0 0 1 .547-.094l.399 2.305v-.001Zm4.382-2.21-.399 2.305a.277.277 0 0 1-.32.225.278.278 0 0 1-.226-.32l.399-2.305a.278.278 0 0 1 .32-.226c.15.027.252.17.226.32v.001Zm-2.387-.047v2.304a.277.277 0 0 1-.554 0v-2.304a.277.277 0 0 1 .554 0Zm6.061 3.747a7.936 7.936 0 0 1-5.333 2.99 7.927 7.927 0 0 1-5.885-1.658 7.989 7.989 0 0 1-3.034-5.86.388.388 0 0 1 .774-.04 7.215 7.215 0 0 0 2.74 5.29 7.164 7.164 0 0 0 5.313 1.496 7.166 7.166 0 0 0 4.816-2.699c2.46-3.124 1.921-7.667-1.204-10.128A7.234 7.234 0 0 0 8.125 6l.866.142a.389.389 0 0 1 .324.422l-.003.02a.388.388 0 0 1-.452.361l-1.817-.3a.385.385 0 0 1-.25-.155.442.442 0 0 1-.079-.319l.295-1.81a.381.381 0 0 1 .065-.16c.042-.06.1-.109.17-.14a.389.389 0 0 1 .545.416l-.15.916a8.01 8.01 0 0 1 9.38.37c3.46 2.725 4.057 7.756 1.331 11.217h-.002Z"
      />
    </SvgIcon>
  )
}
export default SvgReturningCustomer
