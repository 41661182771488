import WithIsInViewport from '@components/WithIsInViewport/WithIsInViewport'
import { ModulesConfigProps } from '@typesApp/cms'
import { ICMAlgolia } from '@typesApp/cmsPlacement/CMAlgolia'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ICMPlaceholder } from '@typesApp/cmsPlacement/CMPlaceholder'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacement, isAlgoliaRecommendationsPlacement } from '@typesApp/cmsPlacement/Placement'
import { isAlgolia, isCMCollection, isLXTeaser } from '@typesApp/teaser'
import React from 'react'
import { StyledPlacementContainer } from './CmsPlacementContainer.style'
import CollectionContentText from './components/CollectionContentText'
import CollectionCta from './components/CollectionCta'

import AlgoliaProductsContentText from './components/AlgoliaProductsContentText'
import AlgoliaProductsCta from './components/AlgoliaProductsCta'
export interface PlacementContainerProps {
  teaserIndex?: number
  placement?: IPlacement // | ICMCollection
  banners: ModulesConfigProps[] | null
}

type Item = ICMCollection | ILXTeaser | ICMPlaceholder | ICMAlgolia | undefined

const getItem = (placement?: IPlacement): Item => {
  if (!placement) return undefined
  if (placement?.viewtype === 'dcw-products') return placement.items.find(isAlgolia)
  if (placement?.viewtype !== 'default') return placement.items.find(isLXTeaser)

  return placement.items.find(isCMCollection)
    ? placement.items.find(isCMCollection)
    : placement.items.find(isAlgoliaRecommendationsPlacement)
}

const CmsPlacementContainer: React.FC<PlacementContainerProps> = ({ teaserIndex, placement, banners, ...rest }) => {
  const placementName = placement?.name || ''
  const marginVerticalSize = (placement as IPlacement)?.marginVertical || ''
  const haveMarginLateral = (placement as IPlacement)?.marginLateral || false
  const viewType = placement?.viewtype || ''

  const item = getItem(placement)

  const teaser =
    item?.type === 'Algolia' || item?.type === 'CMPlaceholder'
      ? ''
      : item?.type === 'LXTeaser'
      ? item!
      : (item as ICMCollection)?.teasableItems[0]!

  const isCollection = item?.type === 'CMCollection' || item?.type === 'CMPlaceholder'
  const isDcwProducts = item?.type === 'Algolia'

  const backGroundColor = (placement as IPlacement)?.backgroundColor || ''

  const metaData = {
    id: placementName,
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  const placeholder = () => {
    return (
      <div
        style={{
          height: '400px',
        }}
      ></div>
    )
  }
  return (
    <WithIsInViewport placeHolder={placeholder} {...metaData} showPlaceholder={!placement?.loaded}>
      {isCollection && (
        <CollectionContentText item={item} backgroundColor={backGroundColor} marginLateral={haveMarginLateral} />
      )}
      {isDcwProducts && (
        <AlgoliaProductsContentText item={item} backgroundColor={backGroundColor} marginLateral={haveMarginLateral} />
      )}
      <StyledPlacementContainer
        marginVertical={isCollection || isDcwProducts ? 'X' : marginVerticalSize}
        marginLateral={haveMarginLateral}
        bgcolor={backGroundColor}
        {...rest}
      >
        {!!banners &&
          banners?.map((node, i) => {
            const Banner = node.banner as React.ElementType
            return (
              <Banner
                key={`placement-${i}`}
                placement={placement}
                viewType={viewType || 'default'}
                teaserIndex={teaserIndex}
              />
            )
          })}
      </StyledPlacementContainer>
      {isCollection && (
        <CollectionCta
          item={item}
          backgroundColor={backGroundColor}
          marginVertical={marginVerticalSize}
          marginLateral={haveMarginLateral}
        />
      )}
      {isDcwProducts && (
        <AlgoliaProductsCta
          item={item}
          backgroundColor={backGroundColor}
          marginVertical={marginVerticalSize}
          marginLateral={haveMarginLateral}
        />
      )}
    </WithIsInViewport>
  )
}

export default CmsPlacementContainer
