import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const Svg365DayReturns = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M8.82 14.303c-.588 0-1.073-.185-1.451-.557l.557-.73c.251.242.527.365.824.365a.555.555 0 0 0 .37-.116.39.39 0 0 0 .136-.313v-.011a.375.375 0 0 0-.186-.333c-.124-.08-.305-.12-.545-.12h-.302l-.11-.586.853-.847H7.608v-.887h2.605v.795l-.905.864c.646.154.968.532.968 1.132v.01c0 .407-.135.73-.406.972-.271.243-.621.364-1.05.364v-.002Zm3.37 0c-.441 0-.795-.133-1.062-.4-.325-.325-.487-.85-.487-1.578v-.023c0-.7.15-1.244.45-1.63.3-.387.724-.58 1.273-.58.41 0 .791.12 1.143.36l-.435.783c-.229-.17-.457-.256-.684-.256-.228 0-.406.085-.52.253a1.2 1.2 0 0 0-.194.606c.235-.174.49-.261.766-.261.336 0 .622.109.856.328.234.218.351.528.351.931v.018c0 .438-.139.789-.418 1.053-.278.265-.625.398-1.038.398l-.001-.002Zm-.385-.984a.443.443 0 0 0 .356.154c.151 0 .269-.05.354-.151a.604.604 0 0 0 .128-.407v-.01a.592.592 0 0 0-.133-.404.453.453 0 0 0-.36-.153.423.423 0 0 0-.348.15.598.598 0 0 0-.127.4v.012c0 .17.043.306.13.408v.002Zm3.512.984c-.526 0-.99-.172-1.392-.516l.516-.766c.302.24.582.36.842.36.17 0 .303-.045.4-.134a.465.465 0 0 0 .145-.36v-.01a.44.44 0 0 0-.15-.352.584.584 0 0 0-.395-.13c-.178 0-.358.054-.54.162l-.586-.332.115-2.06h2.408v.9h-1.596l-.034.62c.17-.065.346-.098.527-.098.36 0 .662.108.905.322.244.214.366.529.366.943v.018c0 .445-.142.795-.427 1.05-.284.256-.653.383-1.105.383h.001Zm1.729-8.527a8.028 8.028 0 0 0-9.402-.37l.15-.918a.389.389 0 0 0-.54-.419.416.416 0 0 0-.172.139.384.384 0 0 0-.068.164L6.72 6.188a.441.441 0 0 0 .082.324c.06.082.149.135.248.152l1.821.3a.388.388 0 0 0 .425-.243.467.467 0 0 0 .028-.116l.003-.02A.389.389 0 0 0 9 6.159l-.867-.142a7.248 7.248 0 0 1 8.43.372c3.133 2.467 3.673 7.02 1.207 10.152a7.181 7.181 0 0 1-4.827 2.706 7.183 7.183 0 0 1-5.325-1.5 7.236 7.236 0 0 1-2.747-5.303.388.388 0 1 0-.776.041 8.008 8.008 0 0 0 3.042 5.873 7.948 7.948 0 0 0 5.898 1.662 7.954 7.954 0 0 0 5.346-2.997c2.732-3.468 2.132-8.511-1.337-11.243v-.004Z"
      />
    </SvgIcon>
  )
}
export default Svg365DayReturns
