import React from 'react'
import { Button } from '@components/UI/Button'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { useExternalsActions } from '../../../../hooks/useExternalsAction'
import { ITeaserCallToAction } from '../../../../types/cmsPlacement/LXTeaser'
import { CmsCtaModal } from './CmsCtaModal'
import { ButtonProps } from '@components/UI/Button/Button'

interface IProps {
  actionSettings: ITeaserCallToAction
  dataElementId?: string
  viewtype?: IViewType
}

export const getStyledCta = (style: ITeaserCallToAction['style']) => {
  const getValues = style.split('-')
  const variant = (getValues[2] || 'primary') as ButtonProps['variant']
  const fillType = (getValues[1] || 'fill') as ButtonProps['fillType']

  return {
    variant,
    fillType,
  }
}
const CROP = 'CMS_CTA_MODAL'

const CmsCta: React.FC<IProps> = ({ actionSettings, dataElementId }) => {
  const { basePath } = useStoreIdentity()

  const actions = useExternalsActions()

  const ctaEnabled = actionSettings?.callToActionEnabled || false
  const text = actionSettings?.callToActionText || ''
  const formattedUrl = actionSettings?.target?.formattedUrl || ''
  const externalUrl = actionSettings?.target?.url || ''
  const style = actionSettings?.style || ''
  const { fillType, variant } = getStyledCta(style)
  const isExternal = ['CMExternalLink'].includes(actionSettings?.target?.type)

  const actionHash = actionSettings?.callToActionHash || ''
  const enabledHash = !!actionSettings?.callToActionHash
  const link = isExternal ? `${externalUrl}` : `${basePath}${formattedUrl ?? ''}`

  if (!ctaEnabled) return null

  const scrollHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault()
    document?.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })
  }
  if (actionSettings.target?.type === 'Action') {
    const action = actions[actionSettings.target.idAction]

    return (
      <Button variant={variant} fillType={fillType} onClick={action} data-element-id={dataElementId}>
        {text}
      </Button>
    )
  }

  if (actionSettings.target?.type === 'LXTeaser') {
    return (
      <CmsCtaModal
        data-element-id={dataElementId}
        style={style}
        teaser={actionSettings.target}
        text={text}
        crop={CROP}
      />
    )
  }

  return (
    <>
      {enabledHash ? (
        <Button
          variant={variant}
          fillType={fillType}
          onClick={e => scrollHandler(e, actionHash)}
          data-element-id={dataElementId}
        >
          {text}
        </Button>
      ) : (
        <Button href={link} variant={variant} fillType={fillType} data-element-id={dataElementId}>
          {text}
        </Button>
      )}
    </>
  )
}

export default CmsCta
