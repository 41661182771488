import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgInBuiltUvBlocking = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M16.829 12.11c-1.377-.723-3.257-1.122-5.295-1.122-2.034 0-3.912.398-5.287 1.12-1.455.764-2.257 1.84-2.257 3.027 0 1.219.857 2.575 2.291 3.63a9.074 9.074 0 0 0 5.256 1.717h.002a9.07 9.07 0 0 0 5.254-1.715c1.436-1.053 2.293-2.409 2.293-3.627 0-1.19-.801-2.265-2.257-3.03Zm-5.29 7.591v.39l-.002-.39c-2.983 0-6.766-2.15-6.766-4.564 0-1.89 2.972-3.368 6.763-3.368 1.914 0 3.665.367 4.931 1.033 1.187.623 1.84 1.453 1.84 2.338 0 2.414-3.784 4.561-6.766 4.561Zm5.865-3.942a.391.391 0 0 1 .023.552c-.855.927-3.494 1.683-5.885 1.683h-.004c-2.395 0-5.037-.756-5.89-1.683a.39.39 0 1 1 .576-.528c.622.676 2.958 1.429 5.315 1.43h.003c2.353 0 4.687-.753 5.31-1.43a.391.391 0 0 1 .552-.023v-.001ZM12.932 5.103a.39.39 0 1 1 .63-.461c.818 1.117 1.05 2.175.731 3.33-.085.306-.228.597-.38.905-.197.4-.42.853-.474 1.325a.391.391 0 0 1-.387.346c-.015 0-.03 0-.045-.002a.391.391 0 0 1-.344-.433c.07-.607.336-1.147.55-1.58.134-.275.263-.535.327-.77.255-.917.067-1.738-.609-2.66h.001Zm-1.61 0a.39.39 0 1 1 .63-.461c.819 1.117 1.052 2.175.731 3.33-.084.305-.227.596-.379.904-.197.402-.42.855-.475 1.328a.391.391 0 0 1-.387.345c-.015 0-.03 0-.045-.002a.391.391 0 0 1-.343-.433c.07-.608.335-1.148.548-1.58.137-.276.264-.535.328-.769.255-.918.067-1.74-.609-2.66l.002-.002Zm-1.825 5.011c.07-.606.336-1.146.55-1.58.134-.275.263-.535.327-.769.255-.918.067-1.74-.609-2.66a.39.39 0 1 1 .63-.462c.818 1.117 1.05 2.175.731 3.33-.085.306-.228.597-.38.905-.197.4-.42.853-.474 1.326a.391.391 0 0 1-.387.345c-.015 0-.03 0-.045-.002a.391.391 0 0 1-.344-.433h.001Z"
      />
    </SvgIcon>
  )
}
export default SvgInBuiltUvBlocking
