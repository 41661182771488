import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const Svg20By20By20RuleComputerTime = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M20.18 16.502H4.155A1.155 1.155 0 0 1 3 15.347V5.154C3 4.518 3.518 4 4.154 4h16.027c.636 0 1.154.518 1.154 1.154v10.193c0 .637-.518 1.155-1.154 1.155ZM4.155 4.77a.384.384 0 0 0-.383.383v10.193c0 .212.172.384.383.384h16.027a.384.384 0 0 0 .383-.384V5.154a.384.384 0 0 0-.383-.383H4.154Zm11.214 14.843a.386.386 0 0 0-.385-.385 1.37 1.37 0 0 1-1.37-1.37.386.386 0 0 0-.77 0c0 .522.187.999.496 1.37h-2.343c.31-.371.496-.85.496-1.37a.386.386 0 0 0-.77 0 1.37 1.37 0 0 1-1.37 1.37.386.386 0 0 0 0 .771h5.632a.386.386 0 0 0 .386-.386h-.002ZM12.465 7.131v-.228a.54.54 0 0 0 .468-.535v-.023a.541.541 0 0 0-.54-.54h-.628a.541.541 0 0 0-.54.54v.023c0 .273.204.5.468.535v.247a3.802 3.802 0 0 0-3.324 3.767 3.802 3.802 0 0 0 3.797 3.798 3.802 3.802 0 0 0 3.798-3.798c0-2.094-1.544-3.632-3.5-3.784l.001-.002Zm-.297 6.812a3.03 3.03 0 0 1-3.027-3.027 3.03 3.03 0 0 1 3.027-3.027 3.03 3.03 0 0 1 3.026 3.027 3.03 3.03 0 0 1-3.026 3.027Zm.385-3.194V9.332a.386.386 0 0 0-.771 0v1.576c0 .103.04.2.114.273l1.115 1.115a.386.386 0 1 0 .544-.544l-1.002-1.002v-.001ZM5.61 10.25V6.575h3.63a.386.386 0 0 0 0-.77H5.225a.386.386 0 0 0-.386.385v4.06a.386.386 0 0 0 .771 0Z"
      />
    </SvgIcon>
  )
}
export default Svg20By20By20RuleComputerTime
