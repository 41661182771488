import React from 'react'
import { getIcon } from './helpers/iconMap'
import { formatCmsIconName } from './helpers/formatCmsIconName'
interface ICmsIcon {
  teaserIcon: string
  height?: number
  width?: number
  htmlColor?: string
}

const CmsIcon: React.FC<ICmsIcon> = ({ teaserIcon, height = 24, width = 24, htmlColor }) => {
  const prefixedTeaserIcon = `${teaserIcon}`
  const isLarge = prefixedTeaserIcon.includes('large')
  const formattedPrefixedTeaserIcon = formatCmsIconName(prefixedTeaserIcon).replace('Large', '')
  const IconComponent = getIcon('Icon'.concat(formattedPrefixedTeaserIcon))
  height = isLarge ? 144 : height
  width = isLarge ? 144 : width
  const setIcon = { height, width, htmlColor }

  return <IconComponent {...setIcon} />
}

export default CmsIcon
