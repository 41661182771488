import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgFreeReplacementLenses = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M14.823 7.297a.332.332 0 0 1 .184-.431c.854-.344 2.482-.863 3.784-.474a.332.332 0 0 1-.189.636c-.81-.242-2.03-.077-3.347.453a.329.329 0 0 1-.43-.183l-.002-.001Zm2.064 4.125c-.905.365-1.702.494-2.379.494a4.78 4.78 0 0 1-1.687-.297c-1.11-.418-1.74-1.156-1.943-1.66l-.004-.016-.024-.051c-.506-1.257 1.147-3.067 3.762-4.121 1.206-.486 2.43-.733 3.444-.698 1.11.039 1.863.416 2.123 1.063.006.012.009.025.013.037l.016.03c.457 1.134-.302 4.003-3.32 5.218v.001Zm-5.422-1.778c.154.383.712.618 1.532.647.924.032 2.052-.198 3.173-.65 1.122-.451 2.094-1.066 2.739-1.73.57-.589.809-1.145.655-1.528-.153-.382-.712-.618-1.531-.646-.924-.032-2.052.198-3.173.65-2.386.96-3.704 2.488-3.395 3.257Zm7.79-1.143c-.702.679-1.703 1.299-2.837 1.756-1.13.454-2.275.7-3.249.7a5.705 5.705 0 0 1-.229-.005l.115.046c.733.277 1.95.467 3.586-.192 1.351-.544 2.175-1.444 2.616-2.305h-.001Zm-5.55 7.977a.55.55 0 0 1-.018.037l-.01.03c-.286.628-1.117 1.23-2.118 1.532a5.216 5.216 0 0 1-1.508.221c-.708 0-1.536-.14-2.441-.551-1.801-.818-2.599-1.977-2.951-2.807-.464-1.09-.365-2.055-.14-2.552.001-.005.005-.008.007-.014l.021-.053c.287-.634 1.058-.977 2.167-.97 1.016.008 2.228.309 3.411.846 2.568 1.166 4.14 3.045 3.58 4.28v.001Zm-2.34.965c-.979-.038-2.121-.333-3.238-.84-1.184-.538-2.208-1.251-2.882-2.011l-.021-.024.045.115c.306.72 1.008 1.732 2.615 2.46 1.478.672 2.709.535 3.48.302v-.002ZM9.85 12.805c-1.101-.5-2.217-.778-3.142-.786h-.037c-.8 0-1.353.211-1.52.58-.17.375.044.942.59 1.554.614.691 1.56 1.348 2.66 1.846 1.102.5 2.217.779 3.143.787.82.007 1.387-.205 1.558-.58.342-.756-.908-2.34-3.251-3.402l-.001.001Zm-.243.437a.332.332 0 0 0-.274.604c1.292.586 2.254 1.354 2.639 2.108a.331.331 0 1 0 .59-.302c-.618-1.212-2.117-2.03-2.955-2.41Z"
      />
    </SvgIcon>
  )
}
export default SvgFreeReplacementLenses
