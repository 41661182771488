import { CmsPicture } from '@components/common/Media/CmsPicture'
import { cmsImageCrops } from '@constants/ui'
import { TPlayerBannerHook } from '@hooks/useBannerPlayer'
import styled from '@mui/material/styles/styled'
import { teaserOverlayBackgroundGradient, teaserPropsByView } from '@utils/placements'
import { getSrcSetsImageFromCms, getVideoFromCMS } from '@utils/url'
import dynamic from 'next/dynamic'
import React from 'react'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import { ILXTeaser, ITeaserOverlaySettings, ITeaserOverlayStyle } from '../../../../types/cmsPlacement/LXTeaser'
import { IPreloadLinks, TMedia } from '../../../../types/cmsPlacement/Media'
import { isPictureMedia } from '../../../../types/cmsPlacement/Placement'
import { IViewType } from '../../../../types/cmsPlacement/ViewType'
import { Seo } from './Seo'
import { hostnameUrlsSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'

const VideoPlayer = dynamic(() => import('./VideoPlayer').then(m => m.VideoPlayer), { ssr: false })

export interface IProps {
  type: string
  media?: TMedia
  playerBannerHook?: TPlayerBannerHook
  isLazy?: boolean
  viewType?: IViewType
  teaser?: ILXTeaser
  isBackgroundGradientNeeded?: boolean
  preloadLinks?: IPreloadLinks
}

const StyledPlayerContainer = styled('div', {
  name: 'CmsCommonMedia',
  slot: 'PlayerContainer',
  shouldForwardProp: prop => prop !== 'color' && prop !== 'settings',
})<{
  color?: ITeaserOverlayStyle
  settings?: ITeaserOverlaySettings
}>(({ color, settings, theme }) => ({
  height: '100%',
  position: 'relative',
  aspectRatio: 'inherit',

  [theme.breakpoints.up('xs')]: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      bottom: '10px',
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5) 25%, rgba(255,255,255,0) 50%);',
    },
  },

  [theme.breakpoints.up('md')]: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      bottom: '10px',
      ...teaserOverlayBackgroundGradient({ color, settings }),
    },
  },

  video: {
    objectFit: 'cover',
  },

  'picture, img': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  img: {
    height: 'auto',
    width: '100%',
  },
}))

const GradientBackgroundContainer = styled('div', {
  name: 'CmsCommonMedia',
  slot: 'GradientBackgroundContainer',
  shouldForwardProp: prop => prop !== 'color' && prop !== 'settings',
})<{
  color?: ITeaserOverlayStyle
  settings?: ITeaserOverlaySettings
}>(({ color, settings, theme }) => ({
  position: 'relative',

  [theme.breakpoints.up('xs')]: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      bottom: '10px',
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5) 25%, rgba(255,255,255,0) 50%);',
    },
  },

  [theme.breakpoints.up('md')]: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      bottom: '10px',
      ...teaserOverlayBackgroundGradient({ color, settings }),
    },
  },

  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100vw',
    objectFit: 'cover',
    verticalAlign: 'bottom',
  },
}))

const BackgroundContainer = styled('div', {
  name: 'CmsCommonMedia',
  slot: 'BackgroundContainer',
})(() => ({
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100vw',
    objectFit: 'cover',
    verticalAlign: 'bottom',
  },
}))

const CMSCommonMedia: React.FC<IProps> = ({
  isLazy = true,
  type,
  media,
  playerBannerHook,
  teaser,
  viewType,
  isBackgroundGradientNeeded,
  preloadLinks,
  ...rest
}) => {
  const { isMobile, isTablet, isTabletLandscape, isDesktopL, isDesktopS } = useBreakpoints()
  const hostnameUrls = useSelector(hostnameUrlsSelector)
  const video = media?.type === 'CMVideo' ? media : undefined
  const picture = isPictureMedia(media) ? media : undefined
  const { deskL, deskS, tabletL, tabletP, mobile } = getSrcSetsImageFromCms(
    type,
    picture?.uriTemplate ?? video?.picture?.uriTemplate ?? '',
    hostnameUrls
  )

  const crop = cmsImageCrops[type]

  const { teaserOverlaySettings, teaserOverlayStyle } = teaserPropsByView(viewType || 'default')

  const Container = isBackgroundGradientNeeded ? GradientBackgroundContainer : BackgroundContainer

  const item = teaser
  const teaserOverlaySettingsValue = item?.[teaserOverlaySettings] || teaser?.[teaserOverlaySettings]
  const teaserOverlayStyleValue = item?.[teaserOverlayStyle] || teaser?.[teaserOverlayStyle]

  const urlCover = () => {
    switch (true) {
      case isDesktopL:
        return deskL
      case isDesktopS:
        return deskS
      case isTabletLandscape:
        return tabletL
      case isTablet:
        return tabletP
      case isMobile:
        return mobile
      default:
        return deskL
    }
  }

  return (
    <>
      {!isLazy ? (
        <Seo
          tabletL={preloadLinks?.tabletL || tabletL}
          tabletP={preloadLinks?.tabletP || tabletP}
          deskL={preloadLinks?.deskL || deskL}
          deskS={preloadLinks?.deskS || deskS}
          mobile={preloadLinks?.mobile || mobile}
        />
      ) : null}
      {playerBannerHook && video ? (
        <StyledPlayerContainer color={teaserOverlayStyleValue} settings={teaserOverlaySettingsValue} {...rest}>
          <>
            <VideoPlayer
              url={getVideoFromCMS(video)}
              width="100%"
              height="100%"
              style={{ position: 'relative' }}
              controls={!video.hideControl}
              loop={video.loop}
              muted={playerBannerHook.muted}
              playing={playerBannerHook.isPlaying}
              config={{
                file: {
                  attributes: {
                    dataPoster: urlCover(),
                  },
                },
              }}
              playsinline
              {...playerBannerHook.events}
            />
          </>
        </StyledPlayerContainer>
      ) : (
        <Container color={teaserOverlayStyleValue} settings={teaserOverlaySettingsValue}>
          <CmsPicture
            sources={[
              {
                srcSet: deskL,
                media: '(min-width: 1440px)',
                width: crop.deskL?.width,
                height: crop.deskL?.height,
              },
              {
                srcSet: deskS,
                media: '(min-width: 1280px)',
                width: crop.deskS?.width,
                height: crop.deskS?.height,
              },
              {
                srcSet: tabletL,
                media: '(min-width: 1024px)',
                width: crop.tabletL?.width,
                height: crop.tabletL?.height,
              },
              {
                srcSet: tabletP,
                media: '(min-width: 601px)',
                width: crop.tabletP?.width,
                height: crop.tabletP?.height,
              },
            ]}
            placeholder={{
              src: mobile as string,
              width: crop.mobile?.width,
              height: crop.mobile?.height as number,
              fetchPriority: !isLazy ? 'high' : 'auto',
              preload: !isLazy,
            }}
            lazy={isLazy}
          />
        </Container>
      )}
    </>
  )
}

export default CMSCommonMedia
