import type { CustomSVGIconProps } from '../icon.type'
import IconWrapper from '../IconWrapper'
import React from 'react'

export const CircleChevronLeftIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#767676',
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <circle cx="12" cy="12" r="11.5" fill="none" stroke={htmlColor}></circle>
      <path
        d="M10.15,12.35a.492.492,0,0,1,0-.7l3-3a.495.495,0,0,1,.7.7L11.21,12l2.64,2.65a.495.495,0,0,1-.7.7Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CircleChevronRightIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#767676',
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <circle cx="12" cy="12" r="11.5" fill="none" stroke={htmlColor}></circle>
      <path
        d="M13.85,11.65a.492.492,0,0,1,0,.7l-3,3a.495.495,0,0,1-.7-.7L12.79,12,10.15,9.35a.495.495,0,0,1,.7-.7Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CircleChevronUpIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#767676',
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <circle cx="12" cy="12" r="11.5" fill="none" stroke={htmlColor}></circle>
      <path
        d="M11.65,10.15a.492.492,0,0,1,.7,0l3,3a.495.495,0,0,1-.7.7L12,11.21,9.35,13.85a.495.495,0,0,1-.7-.7Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}
export const CircleChevronDownIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#767676',
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <circle cx="12" cy="12" r="11.5" fill="none" stroke={htmlColor}></circle>
      <path
        d="M12.35,13.85a.492.492,0,0,1-.7,0l-3-3a.495.495,0,0,1,.7-.7L12,12.79l2.65-2.64a.495.495,0,0,1,.7.7Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}
