import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMutuellePartnershipsTiersPayment = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M19.66 18.196H4.34a.34.34 0 0 1-.34-.34V14.48a.34.34 0 0 1 .34-.34h15.32a.34.34 0 0 1 .34.34v3.376a.34.34 0 0 1-.34.34Zm-14.98-.68h14.64V14.82H4.68v2.696Zm13.854-3.89H5.466a.34.34 0 0 1-.34-.34V9.91a.34.34 0 0 1 .34-.34h13.067a.34.34 0 0 1 .34.34v3.376a.34.34 0 0 1-.34.34Zm-12.727-.68h12.386V10.25H5.807v2.696Zm11.489-3.889H6.704a.34.34 0 0 1-.34-.34V5.34a.34.34 0 0 1 .34-.34h10.592a.34.34 0 0 1 .34.34v3.377a.34.34 0 0 1-.34.34Zm-10.251-.68h9.91V5.68h-9.91v2.695Zm5.74-2.02-.249.292c-.072-.081-.151-.123-.239-.123-.119 0-.2.061-.246.185h.35v.249h-.395v.128h.394v.25h-.354c.043.13.129.193.256.193.088 0 .17-.043.246-.13l.24.286a.655.655 0 0 1-.51.236.648.648 0 0 1-.439-.153.74.74 0 0 1-.233-.425h-.175v-.256h.147V6.96h-.147v-.255h.182a.748.748 0 0 1 .237-.415.647.647 0 0 1 .436-.153c.197 0 .363.074.497.222l.002-.002Zm0 4.647-.249.293c-.072-.082-.151-.124-.239-.124-.119 0-.2.062-.246.185h.35v.249h-.395v.128h.394v.251h-.354c.043.129.129.193.256.193.088 0 .17-.044.246-.13l.24.285a.655.655 0 0 1-.51.237.649.649 0 0 1-.439-.154.74.74 0 0 1-.233-.425h-.175v-.256h.147v-.128h-.147v-.256h.182a.747.747 0 0 1 .237-.414.647.647 0 0 1 .436-.154c.197 0 .363.074.497.222l.002-.002Zm0 4.577-.249.293c-.072-.082-.151-.123-.239-.123-.119 0-.2.06-.246.184h.35v.25h-.395v.128h.394v.25h-.354c.043.129.129.193.256.193.088 0 .17-.044.246-.13l.24.286a.655.655 0 0 1-.51.236.649.649 0 0 1-.439-.154.74.74 0 0 1-.233-.425h-.175v-.255h.147v-.129h-.147v-.255h.182a.747.747 0 0 1 .237-.415.647.647 0 0 1 .436-.153c.197 0 .363.073.497.221l.002-.002Z"
      />
    </SvgIcon>
  )
}
export default SvgMutuellePartnershipsTiersPayment
