import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMoreActions = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 25 24" {...other}>
      <path fill="#fff" d="M.682 0h24v24h-24z" />
      <circle cx={12.682} cy={6} r={2} fill="#000" />
      <circle cx={12.682} cy={12} r={2} fill="#000" />
      <circle cx={12.682} cy={18} r={2} fill="#000" />
    </SvgIcon>
  )
}
export default SvgMoreActions
