export function formatCmsIconName(inputString) {
  if (typeof inputString !== 'string') {
    return ''
  }

  const words = inputString.replace('vde-icon--', '').split(/[_-]/)

  const iconName = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })

  const result = iconName.join('')

  return result
}
