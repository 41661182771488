import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgAirplane = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M11.516 17.63a.541.541 0 0 1-.248-.06c-.243-.122-.33-.386-.33-.592 0-.023.002-.047.006-.07l.752-4.063c-.374.149-.958.382-1.862.74-3.414 1.44-4.467.397-4.575.272a.383.383 0 0 1-.066-.107c-.352-.849-.905-2.175-.992-2.35l-.033-.062c-.108-.206-.27-.517-.128-.833.09-.199.27-.334.538-.4.66-.164 1.423.532 1.606.71.717.576 1.146.463 1.207.44l.014-.005 4.01-1.746c.237-.114 3.95-1.897 5.572-2.394 1.316-.403 2.442-.009 2.833.568.23.34.217.73-.034 1.043-.503.625-3.415 2.02-4.277 2.423l-2.453 5.371a.385.385 0 0 1-.08.114 9.455 9.455 0 0 1-.92.785c-.193.144-.374.217-.54.217v-.001Zm.686-5.787a.387.387 0 0 1 .38.455l-.806 4.357c.289-.228.514-.438.608-.528l2.482-5.434a.385.385 0 0 1 .188-.19c1.53-.71 3.824-1.885 4.131-2.267.027-.033.045-.055-.003-.127-.17-.25-.92-.583-1.968-.262-1.585.486-5.432 2.335-5.47 2.353a1.653 1.653 0 0 1-.014.007l-4.012 1.747c-.14.064-.918.354-2.035-.552a.215.215 0 0 1-.03-.026c-.265-.264-.691-.538-.867-.528.021.045.048.096.065.13l.039.076c.117.233.797 1.87.979 2.312.197.127 1.142.572 3.673-.495 2.232-.888 2.516-1.003 2.517-1.003a.38.38 0 0 1 .143-.027v.002Zm-2.867-1.96a.38.38 0 0 1-.208-.062L6.992 8.446c-.226-.15-.528-.351-.43-.674a.4.4 0 0 1 .226-.254c.135-.08.525-.286 1.09-.399.476-.095 1.519.23 3.16.824.21.076.426.154.493.173a.386.386 0 0 1-.062.767c-.097 0-.16-.021-.693-.214-1.582-.572-2.507-.839-2.746-.793-.132.027-.25.059-.355.093l1.87 1.205a.386.386 0 0 1-.21.71v-.001Z"
      />
    </SvgIcon>
  )
}
export default SvgAirplane
