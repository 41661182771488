import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const Svg12HrsPlusIt = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M20.129 12.344a8.007 8.007 0 0 1-3.042 5.872 7.968 7.968 0 0 1-4.945 1.714 7.999 7.999 0 0 1-6.298-3.049C3.112 13.413 3.712 8.371 7.18 5.638a8.028 8.028 0 0 1 9.4-.37l-.15-.918a.388.388 0 0 1 .542-.418.42.42 0 0 1 .168.135c.037.05.061.107.072.167l.295 1.816a.446.446 0 0 1-.078.318.39.39 0 0 1-.252.156l-1.821.301a.387.387 0 0 1-.429-.252.44.44 0 0 1-.024-.11l-.003-.02a.389.389 0 0 1 .325-.424l.867-.142a7.247 7.247 0 0 0-8.43.372c-3.13 2.466-3.671 7.02-1.206 10.151 2.466 3.131 7.02 3.673 10.151 1.206a7.236 7.236 0 0 0 2.747-5.302.389.389 0 0 1 .776.04h-.001ZM9.153 11.56h.902V7.986h-.658l-.898.263.127.755.527-.136V11.56Zm1.354 0h2.453v-.786h-1.292l.593-.552c.466-.43.69-.72.69-1.196v-.015c0-.639-.487-1.06-1.177-1.06-.552 0-.927.224-1.287.644l.542.578c.253-.269.431-.4.649-.4.217 0 .36.136.36.339s-.111.36-.401.644l-1.13 1.085v.72-.002Zm3.56-.524h.743v-.745h.731V9.57h-.73v-.745h-.745v.745h-.73v.722h.73v.745ZM9.825 15.73c.731 0 1.256-.559 1.256-1.323v-.067c0-.764-.52-1.312-1.251-1.312-.731 0-1.256.558-1.256 1.318v.072c0 .764.52 1.313 1.251 1.313v-.001Zm.005-.721c-.262 0-.434-.253-.434-.61v-.049c0-.353.157-.606.43-.606.271 0 .434.253.434.61v.049c0 .357-.163.606-.43.606Zm1.58.658h.84v-.945c0-.52.225-.76.632-.76h.057v-.921c-.358-.019-.563.187-.687.51v-.467h-.84v2.583h-.002Zm2.918.063c.43 0 .745-.173.988-.435l-.401-.477c-.158.153-.324.243-.525.243-.254 0-.44-.139-.502-.42h1.503v-.234c0-.793-.382-1.38-1.146-1.38-.678 0-1.17.548-1.17 1.327v.052c0 .812.522 1.322 1.253 1.322v.002Zm-.45-1.557c.034-.33.167-.501.368-.501.201 0 .34.176.364.5h-.731Z"
      />
    </SvgIcon>
  )
}
export default Svg12HrsPlusIt
