import { useState, useEffect } from 'react'

function getCookieValue(name: string): string | undefined {
  if (typeof window === 'undefined') {
    return undefined
  }

  const matches = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
  return matches ? decodeURIComponent(matches[2]) : undefined
}

function useCookieWatcher(cookieName: string): string | undefined {
  const [cookieValue, setCookieValue] = useState<string | undefined>(() => getCookieValue(cookieName))

  useEffect(() => {
    const checkCookieChange = () => {
      const currentCookieValue = getCookieValue(cookieName)
      if (currentCookieValue !== cookieValue) {
        setCookieValue(currentCookieValue)
      }
    }

    const intervalId = setInterval(checkCookieChange, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [cookieName, cookieValue])

  return cookieValue
}

export default useCookieWatcher
