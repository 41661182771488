import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgTrustpilot5Stars = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M10.346 10.87v3.028h3.028V10.87h-3.028Zm2.019 1.95.203.627-.689-.502-.688.502.264-.81-.689-.5h.852l.264-.808.261.81h.852l-.689.498-.424.308.483-.125Zm-5.262-1.95v3.028h3.028V10.87H7.103Zm2.019 1.95.203.627-.689-.502-.688.502.264-.81-.689-.5h.852l.264-.808.261.81h.852l-.689.498-.424.308.483-.125Zm7.71-1.95v3.028h3.028V10.87h-3.028Zm2.019 1.95.203.627-.689-.502-.688.502.264-.81-.689-.5h.852l.264-.808.261.81h.852l-.689.498-.424.308.483-.125Zm-5.262-1.95v3.028h3.028V10.87h-3.028Zm2.019 1.95.203.627-.689-.502-.688.502.264-.81-.689-.5h.852l.264-.808.261.81h.852l-.689.498-.424.308.483-.125ZM3.86 10.87v3.028h3.028V10.87H3.86Zm2.019 1.95.203.627-.689-.502-.688.502.264-.81-.689-.5h.852l.264-.808.261.81h.852l-.689.498-.424.308.483-.125Z"
      />
    </SvgIcon>
  )
}
export default SvgTrustpilot5Stars
