import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCopy = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M18.42 6.762a.347.347 0 0 0-.006-.068l-.002-.01a.324.324 0 0 0-.018-.056c-.003-.006-.005-.014-.009-.02a.314.314 0 0 0-.023-.043l-.011-.018a.37.37 0 0 0-.042-.05l-2.284-2.284a.359.359 0 0 0-.056-.045l-.002-.003a.331.331 0 0 0-.067-.035.387.387 0 0 0-.15-.03H7.614a.399.399 0 0 0-.394.394V17.75a.4.4 0 0 0 .394.394h10.414a.4.4 0 0 0 .395-.394V6.762h-.001Zm-2.283-1.34.96.96h-.96v-.96ZM7.993 17.367V4.874h7.369V6.77c0 .214.174.388.387.388h1.896v10.209H7.993Zm8.47 2.339a.4.4 0 0 1-.395.394H5.654a.4.4 0 0 1-.394-.394V6.452a.4.4 0 0 1 .394-.394.388.388 0 0 1 .381.462v12.807h9.967a.388.388 0 0 1 .462.38l-.001-.001Zm-5.657-4.077.3.305a.809.809 0 0 1-.662.316c-.47 0-.836-.36-.836-.934v-.032c0-.568.37-.94.855-.94.289 0 .486.114.636.29l-.304.323c-.079-.094-.178-.174-.323-.174-.216 0-.377.192-.377.505v.01c0 .33.163.51.377.51.157 0 .252-.083.336-.18l-.002.001Zm1.223-1.285c-.494 0-.855.386-.855.935v.04c0 .553.355.934.851.934s.855-.387.855-.936v-.04c0-.552-.355-.933-.852-.933Zm.367.97c0 .318-.147.51-.368.51-.22 0-.37-.195-.37-.512v-.03c0-.317.15-.51.367-.51.218 0 .371.195.371.513v.029Zm1.361-.936H13.1v1.84h.471v-.533h.182c.402-.006.723-.216.723-.658v-.01c0-.413-.284-.64-.718-.64Zm.245.668c0 .155-.1.252-.266.252h-.166v-.515h.163c.17 0 .268.087.268.255v.008h.001Zm1.603-.668h.515l-.59 1.146v.694h-.474v-.688l-.591-1.152h.524l.307.665.31-.665ZM9.415 8.544a.388.388 0 0 1-.388-.388v-2.1c0-.213.174-.387.388-.387h2.073a.388.388 0 0 1 0 .775H9.802v1.712a.388.388 0 0 1-.388.388Z"
      />
    </SvgIcon>
  )
}
export default SvgCopy
