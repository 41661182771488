import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCheckbox = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <rect width={16} height={16} x={4} y={4} fill="currentColor" rx={1} />
      <path
        fill="#fff"
        d="m16.505 9.28-5 6c-.184.222-.768.86-.768.86s-.542-.611-.737-.824l-3-3.273 1.474-1.351 2.227 2.43L14.97 8l1.536 1.28Z"
      />
    </SvgIcon>
  )
}
export default SvgCheckbox
