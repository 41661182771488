import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgDrinks = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M12.116 3.982a.346.346 0 0 0-.306-.172H8.988a.462.462 0 0 0-.452.368L8.32 5.221H4.54a.565.565 0 0 0-.415.183.584.584 0 0 0-.152.447l1.123 13.428c.026.302.27.531.568.531H8.78a.571.571 0 0 0 .567-.531l1.124-13.427a.592.592 0 0 0-.152-.447.566.566 0 0 0-.415-.183h-.806l.113-.55a.127.127 0 0 1 .124-.1h2.457c.276 0 .484-.295.325-.588l-.001-.002ZM4.762 5.995h3.4L7.786 7.82c-.149.057-.277.121-.394.181-.237.122-.409.21-.69.21-.319 0-.483-.08-.56-.135a.09.09 0 0 0-.09-.007l-.588.263a.073.073 0 0 0-.031.106c.108.169.443.547 1.27.547.38 0 .653-.105.888-.218l-1.636 7.936c-.023.114.027.274.12.346.08.06.17.09.257.09a.38.38 0 0 0 .372-.303L8.43 8.455a1.64 1.64 0 0 1 .188-.011c.434 0 .7.09.845.161l-.738 8.813H5.718L4.762 5.99v.004Zm1.09 13.04-.069-.84h2.878l-.07.84H5.853Zm3.83-13.04L9.53 7.803a2.922 2.922 0 0 0-.912-.132h-.026l.346-1.678h.744v.002Zm9.481 2.158h-7.501a.825.825 0 0 0-.824.824v2.871a4.408 4.408 0 0 0 4.169 4.396v2.79h-1.875a.388.388 0 1 0 0 .776h4.528a.388.388 0 1 0 0-.776h-1.876v-2.796a4.496 4.496 0 0 0 4.17-4.479V8.944a.79.79 0 0 0-.79-.79Zm-3.683 7.32a.358.358 0 0 0-.171 0h-.068a3.63 3.63 0 0 1-3.626-3.626V8.976c0-.026.021-.047.047-.047h7.501c.007 0 .013.006.013.012v1.255h-5.11a.388.388 0 1 0 0 .776h5.11v.786a3.719 3.719 0 0 1-3.696 3.715Zm-1.872-1.678a.388.388 0 0 1-.54.1c-.545-.375-.836-1.297-.836-1.818 0-.47.014-1.534.014-1.545a.389.389 0 0 1 .389-.384h.005a.39.39 0 0 1 .384.394c0 .01-.014 1.07-.014 1.534 0 .398.26 1.013.498 1.177a.388.388 0 0 1 .1.54v.002Zm1.13.632a.39.39 0 0 1-.465.294.976.976 0 0 1-.376-.178.388.388 0 0 1 .478-.613.206.206 0 0 0 .07.032c.21.047.34.255.293.465Z"
      />
    </SvgIcon>
  )
}
export default SvgDrinks
