import { useState, useEffect, useMemo } from 'react'
import { parseCustomerSegmentsUtil } from '@utils/Cookies'
import { getProductPriceByCustomerSegments } from '@components/common/UI/ProductPrice/utils/utils'
import useCookieWatcher from '@hooks/useCookieWatcher'
import { usePageState } from '@utils/SSR/PageContext'

// watches cookie userg changes and returns the price for the current product
const useProductPrice = currentProductPrice => {
  const [pageState] = usePageState()
  const ssrCustomerSegments = parseCustomerSegmentsUtil(pageState?.cookies?.userg)
  const usergCookie = useCookieWatcher('userg')
  const customerSegments = useMemo(() => parseCustomerSegmentsUtil(usergCookie), [usergCookie])

  const initialPrice = getProductPriceByCustomerSegments(currentProductPrice, ssrCustomerSegments)
  const [prices, setPrices] = useState(getProductPriceByCustomerSegments(currentProductPrice, ssrCustomerSegments))
  const [cachedSegment, setCachedSegment] = useState(ssrCustomerSegments?.[0])

  useEffect(() => {
    // mis-match between ssr and client side customer segments
    if (customerSegments && ssrCustomerSegments?.[0] !== customerSegments?.[0]) {
      setPrices(getProductPriceByCustomerSegments(currentProductPrice, customerSegments))
    }

    // mis-match between client side old and client side new customer segments
    if (cachedSegment !== customerSegments?.[0]) {
      setCachedSegment(customerSegments?.[0])
      setPrices(getProductPriceByCustomerSegments(currentProductPrice, customerSegments))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegments])

  const currentPrice = prices ?? initialPrice
  const currentSegments = customerSegments ?? ssrCustomerSegments

  return { currentPrice, currentSegments }
}

export default useProductPrice
