import styled from '@mui/material/styles/styled'
import { ICMAlgolia } from '@typesApp/cmsPlacement/CMAlgolia'
import { cmsModuleLateralMargin, cmsModuleVerticalMargin, teaserBackGroundColorToBg } from '@utils/placements'
import React from 'react'
import CmsCta from '../../CmsCta'

const WrapperAlgoliaProductsCta = styled('div', {
  name: 'DcwProductsCta',
  slot: 'Wrapper',
  shouldForwardProp: prop =>
    prop !== 'marginVertical' && prop !== 'backgroundColor' && prop !== 'paddingTopEnabled' && prop !== 'marginLateral',
})<{
  backgroundColor: string
  marginVertical: string
  marginLateral: boolean
  paddingTopEnabled?: boolean
}>(({ backgroundColor, marginVertical, marginLateral, paddingTopEnabled, theme }) => ({
  ...cmsModuleVerticalMargin(marginVertical, theme),
  ...teaserBackGroundColorToBg(backgroundColor, theme),
  ...cmsModuleLateralMargin(marginLateral, theme),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(4),
  padding: theme.spacing(paddingTopEnabled ? 6 : 0, 11, 10),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(paddingTopEnabled ? 6 : 0, 11, 16),
  },
}))

const AlgoliaProductsCta: React.FC<{
  backgroundColor: string
  item: ICMAlgolia
  marginVertical: string
  marginLateral: boolean
}> = ({ backgroundColor, marginVertical, marginLateral, item }) => {
  if (!item) return null
  const AlgoliaCta = item.teaserLXCallToActionSettings || []
  const isProductBanner = ['dcw-products'].includes(item.viewtype || '')
  const AlgoliaProductsBg = isProductBanner ? 'bg-light-secondary' : backgroundColor
  const paddingTopEnabled = AlgoliaCta.length > 0
  return (
    <WrapperAlgoliaProductsCta
      backgroundColor={AlgoliaProductsBg}
      marginVertical={marginVertical}
      marginLateral={marginLateral}
      paddingTopEnabled={paddingTopEnabled}
    >
      {AlgoliaCta?.map((actionSettings, index) => (
        <CmsCta
          actionSettings={actionSettings}
          data-element-id={`X_X_${index}Placement_DcwProducts_CTA`}
          key={`cms-content__dcwProducts--cta-${index}`}
        />
      ))}
    </WrapperAlgoliaProductsCta>
  )
}

export default AlgoliaProductsCta
