import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgUpgradeToABetterLens = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M18.017 11.96a.388.388 0 0 0-.659.406c1.576 2.55 1.967 4.826.998 5.795-.682.68-2.034.701-3.71.055-1.803-.694-3.775-2.056-5.552-3.833-1.777-1.776-3.138-3.748-3.833-5.552-.645-1.676-.625-3.028.056-3.71.88-.88 2.85-.642 5.144.619a.387.387 0 0 0 .373-.678c-2.647-1.456-4.916-1.639-6.066-.488-.02.019-.037.04-.055.06a.455.455 0 0 0-.052.043c-.863.863-1.214 2.863-.897 5.096.213 1.494.917 4.347 3.253 6.683 2.336 2.336 5.19 3.04 6.683 3.253.546.077 1.076.115 1.58.115 1.561 0 2.864-.36 3.515-1.012.01-.01.016-.02.025-.03.027-.023.056-.047.083-.073 1.251-1.252.911-3.839-.887-6.75h.002Zm-10.45 3.95c-2.176-2.177-2.835-4.847-3.034-6.244-.04-.28-.067-.548-.085-.807.03.084.059.167.092.252.732 1.902 2.155 3.97 4.007 5.822 1.853 1.853 3.92 3.276 5.822 4.008.081.031.161.059.24.087a11.319 11.319 0 0 1-.797-.084c-1.398-.199-4.067-.857-6.244-3.034h-.001Zm9.251-4.662h-2.314a.388.388 0 0 1-.387-.387V8.93h-2.014a.388.388 0 0 1-.387-.387V6.37c0-.214.174-.388.388-.388h2.013V4.244c0-.214.174-.387.387-.387h2.314c.214 0 .387.173.387.387v1.74h2.03c.213 0 .387.173.387.387v2.172a.388.388 0 0 1-.387.387h-2.03v1.93a.388.388 0 0 1-.387.388Zm-1.926-.775h1.539v-1.93c0-.214.174-.388.387-.388h2.03V6.758h-2.03a.388.388 0 0 1-.387-.387V4.63h-1.54v1.74a.388.388 0 0 1-.386.387H12.49v1.397h2.014c.213 0 .387.174.387.388v1.93Zm2.235 7.028c-2.078 0-4.672-1.405-7.14-3.873a.387.387 0 1 1 .547-.548c2.37 2.37 4.808 3.699 6.697 3.646a.386.386 0 1 1 .02.773l-.123.003h-.001Z"
      />
    </SvgIcon>
  )
}
export default SvgUpgradeToABetterLens
