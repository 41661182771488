import React, { useState } from 'react'

import { Button } from '@components/UI/Button'
import { CloseIcon } from '@components/UI/Icons/close'
import { ctaFillMap, ctaStylesMap } from '@constants/ui'
import { useExternalsActions } from '@hooks/useExternalsAction'
import useMediaByDeviceType from '@hooks/useMediaByDeviceType'
import DialogContent from '@mui/material/DialogContent'
import { ICMLXTeaser } from '@typesApp/cmsPlacement/CMLXTeaser'
import CMSCommonMedia from '../CmsCommonMedia'
import CmsCta from './CmsCta'
import {
  StyledCtaModalBody,
  StyledCtaModalButtonsContainer,
  StyledCtaModalCloseButton,
  StyledCtaModalDialog,
  StyledCtaModalTitle,
} from './CmsCtaModal.style'

interface CmsCtaModalProps {
  style: string
  teaser: ICMLXTeaser
  text: string
  dataElementId?: string
  crop?: string
}

export const CmsCtaModal: React.FC<CmsCtaModalProps> = ({ style, teaser, text, dataElementId, crop }) => {
  const { teaserLXCallToActionSettings, teaserTitle1, teaserText1 } = teaser
  const [showModal, setShowModal] = useState(false)
  const actions = useExternalsActions()
  const action = actions[teaser?.idAction]
  const handleCtaAction = () => {
    if (action) {
      action()
    }
    setShowModal(true)
  }
  const handelModalClose = () => {
    setShowModal(false)
  }

  const media = useMediaByDeviceType(teaser?.media)

  return (
    <>
      <Button
        variant={ctaStylesMap[style.split('-')[2]] || 'primary'}
        fillType={ctaFillMap[style.split('-')[1]] || 'fill'}
        onClick={handleCtaAction}
      >
        {text}
      </Button>
      <StyledCtaModalDialog open={showModal} onClose={handelModalClose} fullHeightOnSmallScreens={true}>
        <DialogContent>
          <StyledCtaModalCloseButton aria-label="close" onClick={handelModalClose}>
            <CloseIcon />
          </StyledCtaModalCloseButton>

          <StyledCtaModalTitle>{teaserTitle1}</StyledCtaModalTitle>
          {teaser?.media && <CMSCommonMedia media={media} type="CMS_CTA_MODAL" />}
          <StyledCtaModalBody
            dangerouslySetInnerHTML={{
              __html: teaserText1,
            }}
          ></StyledCtaModalBody>
          {teaserLXCallToActionSettings && teaserLXCallToActionSettings.length > 0 && (
            <StyledCtaModalButtonsContainer>
              {teaserLXCallToActionSettings.map((actionSettings, index) => (
                <CmsCta
                  actionSettings={actionSettings}
                  data-element-id={dataElementId}
                  key={`cms-content__text-module--cta-${index}`}
                  viewtype={'default'}
                />
              ))}
            </StyledCtaModalButtonsContainer>
          )}
        </DialogContent>
      </StyledCtaModalDialog>
    </>
  )
}
