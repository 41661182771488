import { styled } from '@mui/material'

export const StyledSuggestedProductTile = styled('div', {
  name: 'SuggestedProductTile',
})(({ width }: { width?: string }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: width ?? 'auto',
}))

export const StyledSuggestedProductTileFooter = styled('div', {
  name: 'SuggestedProductTileFooter',
  slot: 'Footer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
}))

export const StyledProductTileImageWrapper = styled('div', {
  name: 'ProductTileImage',
  slot: 'Image',
})(({ theme }) => ({
  aspectRatio: 'auto',
  display: 'flex',
  alignItems: 'end',
  marginBottom: theme.spacing(5),
  position: 'relative',
}))
