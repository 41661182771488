import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgLensCare2 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M18.904 5.436v-.009l.001-.018c0-.016-.002-.032-.003-.047v-.006c-.078-1.13-2.61-1.646-4.967-1.646-2.358 0-4.935.525-4.97 1.676v.022c-.001.119.01 1.109.849 1.995.44.466 1.02.814 1.735 1.047a7.888 7.888 0 0 0-.507.227.443.443 0 0 0-.062.038c-.033.025-.841.637-2.231 2.089a2081.515 2081.515 0 0 1-3.91 4.067.388.388 0 0 0 .559.538c.025-.026 2.543-2.639 3.911-4.069 1.202-1.254 1.961-1.867 2.11-1.985 2.619-1.273 4.21-.094 4.939.744-2.367.284-3.523 1.525-3.652 1.672-1.482 1.528-1.194 3.12-.735 3.955l-3.035 3.336a.387.387 0 1 0 .573.522l3.236-3.558 4.712-5.18a.389.389 0 0 0 .058-.439 2.574 2.574 0 0 0-.104-.176c-.007-.011-.013-.022-.021-.032a5.14 5.14 0 0 0-1.644-1.595c2.523-.604 3.038-2.475 3.135-3.02a.76.76 0 0 0 .018-.123l.004-.027v.002Zm-5.635 6.87.019-.019c.01-.012.98-1.132 3.094-1.407l-3.846 4.228c-.228-.571-.397-1.64.734-2.8l-.001-.001Zm.666-7.82c2.836 0 4.148.69 4.193.919l-.002.019c-.077.237-1.39.91-4.19.91-2.8 0-4.169-.702-4.194-.924.025-.222 1.335-.924 4.194-.924h-.001Zm-.144 3.531c-2.212-.05-3.215-.79-3.67-1.467.973.379 2.424.558 3.814.558 1.39 0 2.721-.165 3.69-.512-.025.036-.051.07-.08.106-.73.9-2.03 1.354-3.754 1.315Z"
      />
    </SvgIcon>
  )
}
export default SvgLensCare2
