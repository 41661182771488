import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgJpg = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M9.892 15.758h.47v1.225c0 .213-.055.368-.168.479a.61.61 0 0 1-.447.166.671.671 0 0 1-.54-.242l.266-.32c.069.081.14.133.234.133.11 0 .183-.076.183-.231v-1.21h.002Zm2.1.64v.01c0 .441-.32.652-.723.657h-.181v.534h-.471v-1.84h.658c.434 0 .717.225.717.639Zm-.473.02c0-.168-.097-.254-.268-.254h-.162v.515h.166c.166 0 .265-.098.265-.253v-.007Zm1.422.471h.292v.274a.379.379 0 0 1-.212.055c-.237 0-.419-.187-.419-.524v-.026c0-.305.18-.51.413-.51a.51.51 0 0 1 .36.152l.264-.36a.945.945 0 0 0-.634-.224c-.507 0-.885.385-.885.936v.037c0 .575.38.931.883.931.28 0 .503-.1.676-.23v-.877h-.737v.366Zm4.931-9.971v12.597a.396.396 0 0 1-.395.395H5.525a.396.396 0 0 1-.395-.395V4.305c0-.218.177-.395.395-.395h9.339c.05 0 .1.01.148.03l.005.002c.022.009.041.02.06.032l.004.002c.02.014.038.028.056.046l2.621 2.62a.37.37 0 0 1 .042.052c.005.005.008.012.011.018a.314.314 0 0 1 .032.063.446.446 0 0 1 .018.055l.002.01a.347.347 0 0 1 .007.069v.007l.002.002Zm-2.62-.388h1.297L15.25 5.232V6.53Zm1.845 12.605V7.305h-2.233a.388.388 0 0 1-.388-.387V4.685H5.904v14.45h11.193ZM9.787 5.868a.388.388 0 0 0-.388-.388H7.326a.388.388 0 0 0-.388.388v2.1a.388.388 0 0 0 .775 0V6.254H9.4a.388.388 0 0 0 .387-.387Z"
      />
    </SvgIcon>
  )
}
export default SvgJpg
