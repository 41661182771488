import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgNoPrescriptionNeededRx = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M18.028 19.84H6.07a.391.391 0 0 1-.391-.391V4.23c0-.216.175-.391.391-.391h2.174a.391.391 0 0 1 0 .782H6.464v14.434h11.172V4.623h-1.781a.391.391 0 0 1 0-.782h2.174c.216 0 .39.175.39.391V19.45a.391.391 0 0 1-.39.391h-.001ZM14.615 6.405V4.231a.391.391 0 0 0-.39-.391h-4.35a.391.391 0 0 0-.39.391v2.174c0 .216.175.392.39.392h4.35a.391.391 0 0 0 .39-.392Zm-4.348-1.781h3.565v1.39h-3.565v-1.39Zm4.108 11.929a.395.395 0 0 0 .153-.615l-.024-.027-1.088-1.081 1.084-1.078a.394.394 0 0 0 .005-.557.383.383 0 0 0-.245-.113h-.013a.381.381 0 0 0-.3.11l-1.08 1.087-2.047-2.046h.688a2.025 2.025 0 0 0 2.021-2.022 2.025 2.025 0 0 0-2.021-2.022H9.877a.391.391 0 0 0-.391.392v6.522a.391.391 0 0 0 .782 0v-2.318l2.046 2.046-1.087 1.08a.398.398 0 0 0-.114.298.387.387 0 0 0 .151.281.384.384 0 0 0 .52-.022l1.081-1.087 1.076 1.083a.398.398 0 0 0 .434.09v-.001Zm-4.108-7.581h1.24a1.24 1.24 0 0 1 0 2.477h-1.24V8.972Z"
      />
    </SvgIcon>
  )
}
export default SvgNoPrescriptionNeededRx
