import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSecureAuthorityFigure = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M12.002 20.165c-.11 0-.221-.02-.315-.06-.263-.11-.527-.218-.791-.325-.725-.294-1.475-.597-2.184-.97-1.956-1.024-3.166-2.683-3.598-4.93a7.298 7.298 0 0 1-.115-1.342c-.007-1.093-.007-2.203-.007-3.277 0-.778 0-1.557-.002-2.337 0-.327.15-.536.463-.639 1.124-.37 2.248-.743 3.371-1.115.966-.32 1.931-.641 2.898-.96a.917.917 0 0 1 .556-.004c1.835.606 3.7 1.225 5.501 1.825l.807.269c.04.013.078.027.121.044l.055.02a.386.386 0 0 1 .253.363v3.694c0 .231 0 .463.002.694.004.623.007 1.266-.026 1.903-.097 1.876-.854 3.487-2.251 4.79-.6.558-1.314 1.007-2.183 1.37l-.442.185c-.586.245-1.19.5-1.79.74a.88.88 0 0 1-.323.06v.002ZM5.761 6.998c.002.754.002 1.509.002 2.263 0 1.073 0 2.182.007 3.271.003.474.035.855.102 1.201.385 2.004 1.46 3.482 3.197 4.391.676.355 1.408.652 2.115.938.268.109.535.217.801.33.006 0 .038 0 .05-.006.595-.239 1.197-.492 1.78-.736l.441-.185c.784-.328 1.422-.728 1.954-1.223 1.245-1.161 1.92-2.595 2.006-4.263.032-.616.028-1.247.025-1.86l-.002-.7c.002-.857 0-1.714 0-2.578V7l-.706-.235c-1.802-.6-3.665-1.22-5.5-1.824a.16.16 0 0 0-.071.002c-.966.318-1.931.639-2.897.959-1.101.366-2.202.731-3.304 1.094v.002Zm5.388 7.018 4.143-4.143a.386.386 0 1 0-.547-.546l-3.869 3.868-1.551-1.55a.386.386 0 1 0-.547.546l1.824 1.824a.387.387 0 0 0 .546.001Zm1.125 4.021c.436-.174.874-.359 1.298-.537l.326-.137c.645-.27 1.174-.602 1.619-1.017.774-.722 1.287-1.579 1.524-2.545a.386.386 0 1 0-.75-.184c-.202.819-.64 1.547-1.301 2.164a4.607 4.607 0 0 1-1.39.87l-.327.137c-.422.177-.857.36-1.287.532a.387.387 0 0 0 .288.718Z"
      />
    </SvgIcon>
  )
}
export default SvgSecureAuthorityFigure
