import CmsTeaserDetailText from '@components/Cms/CmsComponents/CmsTeaserDetailText'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledBoxBannerTitle = styled(Typography, {
  name: 'BoxBannerTitle',
  slot: 'Title',
})(({ theme }) => ({
  marginBottom: theme.spacing(6),
}))

export const StyledBoxBannerTextContainer = styled(CmsTeaserDetailText, {
  name: 'BoxBannerTextContainer',
  slot: 'TextContainer',
})(() => ({
  width: '100%',
}))
