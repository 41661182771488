import Head from 'next/head'
import { ConditionalWrapper } from './ConditionalWrapper'
import { ImageProps } from './Image'
import Source, { SourceProps } from './Source'

export type PictureProps = {
  lazy?: boolean
  sources: Omit<SourceProps, 'lazy'>[]
  placeholder: ImageProps
  wrapper?: ConditionalWrapper
}

export const CmsPicture: React.FC<PictureProps> = ({ sources, lazy, placeholder, wrapper }) => {
  return (
    <>
      {placeholder.preload && (
        <Head>
          {sources.map(source => (
            <link
              key={source.media}
              rel="preload"
              as="image"
              href={source.srcSet}
              media={source.media}
              fetchpriority="high"
            />
          ))}
        </Head>
      )}
      <ConditionalWrapper condition={Boolean(wrapper?.condition)} wrapperTag={wrapper?.wrapperTag}>
        <picture>
          {sources.map(source => {
            return <Source key={source.media} {...source} />
          })}
          <img
            sizes={placeholder.sizes}
            fetchpriority={placeholder.fetchPriority !== 'auto' ? placeholder.fetchPriority : undefined}
            alt={placeholder.alt ?? undefined}
            width={placeholder.width}
            height={placeholder.height}
            loading={lazy ? 'lazy' : 'eager'}
            src={placeholder.src}
          />
        </picture>
      </ConditionalWrapper>
    </>
  )
}
