import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCleanliness = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="m11.063 10.324.016-2.883a.39.39 0 0 1-.016-.101V5.113a.6.6 0 0 0-1.197 0v.123l-.021 2.855v2.248a.386.386 0 0 1-.77 0V6.286a.6.6 0 0 0-1.199 0v.124l-.02 4.014c0 .02-.004.04-.007.059.005.053.009.105.009.158v1.217l.789.792c.43.43.667 1.002.667 1.611v.823a.385.385 0 0 1-.648.358l-.01-.01a.386.386 0 0 1-.114-.273v-.9c0-.402-.157-.78-.441-1.066l-.902-.906a.387.387 0 0 1-.112-.272V10.64a.66.66 0 0 0-.196-.472.663.663 0 0 0-.944 0 .663.663 0 0 0-.196.472v3.563a4.648 4.648 0 0 0 6.105 4.406.385.385 0 0 1 .242.731A5.418 5.418 0 0 1 4.98 14.2v-3.562c0-.384.15-.745.422-1.017a1.429 1.429 0 0 1 1.017-.422c.238 0 .467.057.671.166l.016-3.08a1.371 1.371 0 0 1 1.992-1.22 1.371 1.371 0 0 1 1.368-1.325c.74 0 1.343.59 1.367 1.325a1.37 1.37 0 0 1 1.992 1.219v.492A1.371 1.371 0 0 1 15.805 8v3.969a.386.386 0 0 1-.77 0V8a.6.6 0 0 0-1.199 0v.124l-.02 2.16a.386.386 0 0 1-.386.381h-.004a.386.386 0 0 1-.382-.388l.016-1.715c-.002-.017-.005-.033-.005-.05V6.283a.6.6 0 0 0-1.198 0v.123l-.02 3.918a.385.385 0 0 1-.386.384h-.002a.386.386 0 0 1-.383-.388l-.003.003Zm7.283 6.435a2.864 2.864 0 0 1-.943 2.006c-.252.234-.55.421-.911.573l-.175.074c-.235.099-.47.197-.706.292a.523.523 0 0 1-.388-.001l-.309-.128c-.29-.118-.588-.239-.875-.389-.82-.43-1.328-1.124-1.508-2.065a3.011 3.011 0 0 1-.049-.56c-.002-.435-.002-.871-.002-1.306v-.904a.42.42 0 0 1 .305-.42l1.329-.44c.38-.126.758-.253 1.138-.377a.536.536 0 0 1 .33-.001c.736.242 1.469.486 2.203.73l.281.094.053.019.02.008a.33.33 0 0 1 .218.31V16c0 .247.003.503-.01.76h-.001ZM17.695 16V14.51l-.119-.039c-.719-.239-1.438-.479-2.158-.715l-1.096.363-1.181.39v.748c0 .434 0 .868.002 1.301 0 .173.013.317.036.44.141.734.533 1.273 1.166 1.605.259.135.53.245.817.362l.256.105c.215-.087.429-.177.642-.267l.175-.074a2.4 2.4 0 0 0 .717-.447c.461-.43.701-.939.733-1.556.012-.238.011-.474.01-.724Zm-1.102-.728-1.58 1.58-.565-.565a.33.33 0 0 0-.466.466l.799.8a.328.328 0 0 0 .467 0l1.813-1.813a.33.33 0 0 0-.467-.467l-.001-.001Z"
      />
    </SvgIcon>
  )
}
export default SvgCleanliness
