import React from 'react'
import { StyledPriceWrapper, StyledRrpPrice } from './ProductTilePrice.style'
import { ProductPriceReturn } from '../ProductPrice/Price'
import { Variant } from '@mui/material/styles/createTypography'

type ProductTilePriceProps<T extends ProductPriceReturn> = {
  rrp?: React.ReactNode
  offerPrice?: React.ReactNode
  variant?: Variant
}

function ProductTilePrice<T extends ProductPriceReturn>({
  rrp,
  offerPrice,
  variant = 'h5',
}: ProductTilePriceProps<T>): JSX.Element {
  const isOnOffer = !!(offerPrice && offerPrice)

  return (
    <StyledPriceWrapper variant={variant}>
      {rrp && <StyledRrpPrice isOnOffer={isOnOffer}>{rrp}</StyledRrpPrice>}
      {isOnOffer && offerPrice}
    </StyledPriceWrapper>
  )
}

export default ProductTilePrice
