import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSimpleOrderingThreeClickReOrdering = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M15.8 20.345a.8.8 0 0 1-.678-.365l-1.462-2.254-1.285 1.937a.78.78 0 0 1-.816.343.812.812 0 0 1-.64-.65L9.064 9.488l-.002-.01a.773.773 0 0 1 .313-.826l.028-.02.03-.018a.774.774 0 0 1 .884.04l.007.006 8.334 5.603a.813.813 0 0 1 .344.845.78.78 0 0 1-.637.615l-2.287.414 1.49 2.23a.827.827 0 0 1 .118.623.786.786 0 0 1-.348.5l-1.101.725a.78.78 0 0 1-.438.133v-.002Zm-2.137-3.712h.002c.13 0 .253.067.323.175l1.781 2.746c.008.012.02.015.025.016.005.001.008 0 .013-.003l1.111-.732c.009-.006.011-.008.012-.013 0-.005.002-.018-.008-.033l-1.815-2.717a.385.385 0 0 1 .253-.596l2.868-.52.015-.004s.005-.01.003-.023c-.002-.015-.011-.022-.02-.028l-8.358-5.62c-.015-.008-.022-.01-.032-.007a.008.008 0 0 0-.006.002l-.004.005c-.006.007-.01.015-.006.032l1.86 9.898c.003.009.006.02.019.027.01.007.02.007.023.007l.01-.012 1.61-2.429a.386.386 0 0 1 .323-.173l-.002.002Zm-1.71-8.755 1.475-.972a.388.388 0 0 0-.426-.647l-1.475.972a.388.388 0 0 0 .426.647ZM6.238 11.64l1.475-.972a.388.388 0 0 0-.426-.646l-1.474.971a.388.388 0 0 0 .425.647Zm1.275-3.124a.387.387 0 0 0-.3-.457l-1.731-.356a.387.387 0 0 0-.156.757l1.73.356a.386.386 0 0 0 .457-.3Zm2.998-1.975.356-1.73a.387.387 0 0 0-.757-.156l-.356 1.73a.387.387 0 0 0 .757.156Zm-2.074.612a.388.388 0 0 0 .11-.537l-.971-1.474a.388.388 0 0 0-.647.425l.972 1.475a.387.387 0 0 0 .536.11Z"
      />
    </SvgIcon>
  )
}
export default SvgSimpleOrderingThreeClickReOrdering
