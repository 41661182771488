import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgFemaleBathroomSign = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M15.245 12.72a.353.353 0 0 1-.422-.265c-.203-.882-.664-2.682-.913-3.65l-.13-.508c-.041-.03-.234-.144-.879-.188-.423-.03-.807-.011-.812-.011h-.024c-.004 0-.376-.01-.781.028-.608.057-.791.168-.832.199-.155.658-.76 2.914-1.105 4.152a.353.353 0 0 1-.678-.188c.348-1.252.964-3.548 1.104-4.154l.001-.006c.124-.501.818-.635 1.33-.693a7.79 7.79 0 0 1 .965-.043 8.44 8.44 0 0 1 1.006.022c.53.047 1.252.168 1.38.683.028.105.076.291.137.532.249.97.713 2.777.917 3.667a.353.353 0 0 1-.264.422Zm-.766-.45a.352.352 0 0 0-.681.177l.432 1.661s-.005.003-.008.003l-.761.003a.351.351 0 0 0-.351.352v4.348h-.67v-4.351a.352.352 0 0 0-.705 0v4.351h-.64v-4.348a.351.351 0 0 0-.352-.352l-.762-.003s-.006 0-.008-.003l.406-1.696a.352.352 0 0 0-.684-.165l-.424 1.766a.335.335 0 0 0-.01.082c0 .396.322.72.717.72h.411v3.984c0 .396.286.72.638.72h.935a.36.36 0 0 0 .072-.007c.018.002.035.005.054.005a.293.293 0 0 0 .072-.008c.026.006.054.01.083.01h.935c.352 0 .638-.322.638-.72v-3.982h.408a.723.723 0 0 0 .72-.721.33.33 0 0 0-.011-.089l-.453-1.736-.001-.001ZM10.3 5.298v-.042c.03-.8.604-1.557 1.413-1.705.737-.135 1.512.162 1.902.823a1.807 1.807 0 0 1-.165 2.073c-.458.553-1.283.798-1.962.533-.706-.277-1.177-.915-1.188-1.682Zm.703 0a1.1 1.1 0 0 0 .481.9c.244.152.53.22.786.166.271-.057.529-.2.672-.404.184-.26.255-.499.216-.82 0 .016-.02-.09-.024-.105a.953.953 0 0 0-.11-.274 1.081 1.081 0 0 0-2.022.535l.001.002Z"
      />
    </SvgIcon>
  )
}
export default SvgFemaleBathroomSign
