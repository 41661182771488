import { TFunction } from 'next-i18next'
import { TCurrentBadges } from '../types'
import { cloneDeep } from '@utils/helpers'
import { getBrand, getModelCode, getModelName, getProductType } from '@utils/productAttributesAlgolia'
import { PRODUCT_TYPES_MAP } from '@constants/product'
import { isCLAccessories, isContactLenses } from '@utils/product'
import { NOT_FOUND } from '@constants/routes'
import { TProduct } from '@utils/productNew'

export const getPriorityBadgeText = (badges: TCurrentBadges, t: TFunction): string => {
  switch (true) {
    case badges.isExclusive:
      return t('ProductTile.Labels.exclusive')
    case badges.isOnlineExclusive:
      return t('ProductTile.Labels.onlineExclusive')
    case badges.isAvantPremiere:
      return t('ProductTile.Labels.avantPremiere')
    case badges.isNew:
      return t('ProductTile.Labels.new')
    case badges.isMostPopular:
      return t('ProductTile.Labels.mostPopular')
    default:
      return ''
  }
}

export const getAttachments = (cluster: TProduct) => {
  return cluster.attachments
}

export const getItems = (product: TProduct) => {
  return product?.sKUs != null ? product?.sKUs : product?.items
}

export const getCluster = (product: TProduct, isClustered?: boolean) => {
  return cloneDeep((product?.cluster ?? (isClustered ? product?.cluster : product?.items?.slice(0, 1))) || [])
}

export const getCurrentSku = (product: TProduct, selectedViewCluster: TProduct, clusterLength: number) => {
  if (clusterLength === 0) {
    return product
  }
  const items = getItems(selectedViewCluster)

  if (!items) {
    return product
  }

  return items[0]
}

export const getProductInfo = (product: TProduct) => {
  const productType: string =
    (product !== null && PRODUCT_TYPES_MAP[getProductType(product)?.toLowerCase()]) || 'frames'
  const name: string = getModelName(product)
  const brand: string = getBrand(product)
  const modelCode: string = getModelCode(product)
  const productItems = getItems(product)
  return {
    productType,
    name,
    brand,
    modelCode,
    productItems,
  }
}

export const getSeo = (selectedViewCluster: TProduct, productItems: TProduct[] | undefined, product: TProduct) => {
  const { url } = getItems(selectedViewCluster)?.[0] || (productItems && productItems[0]) || product || {}
  return url
}

export const getMocoLinkTo = (product: TProduct, productType: string, seo?: string): string => {
  if (isContactLenses(productType) || isCLAccessories(productType)) {
    return product?.url || NOT_FOUND
  }
  const items = product && getItems(product)
  return seo || items?.[0]?.url || NOT_FOUND
}
