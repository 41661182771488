import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgReviews = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M7.574 18.745h-.04a.863.863 0 0 1-.63-.322.8.8 0 0 1-.17-.598l.536-4.166-3.05-3.068a.818.818 0 0 1-.206-.363.794.794 0 0 1 .092-.618.858.858 0 0 1 .537-.384l.016-.003 4.411-.819 2.164-3.686c.14-.243.398-.4.688-.419h.003a.852.852 0 0 1 .787.418L14.88 8.39l4.415.797a.866.866 0 0 1 .39.18.807.807 0 0 1 .087 1.178l-.01.009-3.047 3.091.555 4.151a.8.8 0 0 1-.078.46.87.87 0 0 1-1.12.39l-4.08-1.773-4.061 1.794a.874.874 0 0 1-.36.078h.003Zm-2.762-8.76a.072.072 0 0 0-.042.03c-.006.01-.004.015-.003.019a.033.033 0 0 0 .01.016l3.178 3.197a.39.39 0 0 1 .11.324l-.56 4.353s0 .007.007.018a.08.08 0 0 0 .056.027.083.083 0 0 0 .047-.009l4.223-1.864a.387.387 0 0 1 .312 0l4.237 1.842c.046.02.1.002.113-.025.003-.007.003-.012.002-.014l-.581-4.336a.39.39 0 0 1 .109-.324l3.174-3.221c.008-.009.008-.016.008-.02 0-.009-.007-.017-.017-.026a.083.083 0 0 0-.039-.016l-4.589-.83a.388.388 0 0 1-.265-.185l-2.26-3.83c-.012-.019-.04-.034-.075-.034-.031.002-.053.018-.062.032L9.65 8.952a.387.387 0 0 1-.264.185l-4.576.849h.002Z"
      />
    </SvgIcon>
  )
}
export default SvgReviews
