import { getHeadersWithToken } from '@utils/common'
import axios from 'axios'
import qs from 'qs'
import config from '../../../configs/config.base'
import { UserData } from '../../../types/FrameGenius/frameAdvisor'
import { IProduct } from '../../../types/product'
interface IFrameGeniusService {
  loadFrameAdvisorProducts: (
    storeId: number,
    langId: number,
    catalogId: number,
    brand: string,
    partNumber: number[]
  ) => Promise<IProduct[]>
  saveProfileData: (profileData: UserData, storeID: string) => void
  getProfileData: (storeID: string) => Promise<UserData>
}

interface FrameGeniusProductsRequest {
  storeId: number
  langId: number
  catalogId: number
  brand: string
  partNumber: number[]
  profile?: string
}
class FrameGeniusService implements IFrameGeniusService {
  private readonly frameAdvisorProductsEndpoint = `${config.cmsApiUrl}`

  private readonly generateProfileEndpoint = (storeId: string) => {
    return `${config.transactionHost}/wcs/resources/store/${storeId}/frameadvisor/userprofile`
  }

  loadFrameAdvisorProducts = async (
    storeId: number,
    langId: number,
    catalogId: number,
    brand: string,
    partNumber: number[]
  ): Promise<IProduct[]> => {
    const params: FrameGeniusProductsRequest = {
      storeId,
      langId,
      catalogId,
      brand,
      partNumber,
      profile: 'light_product',
    }
    const stringifiedParams = qs.stringify(params, { indices: false })
    const url = `${this.frameAdvisorProductsEndpoint}/wcs/productinfo?${stringifiedParams}`
    const response = await axios.get(url, { headers: getHeadersWithToken() })
    return response.data ? (Object.values(response.data) as IProduct[]) : []
  }

  saveProfileData = (profileData: UserData, storeID: string) => {
    const endpoint = this.generateProfileEndpoint(storeID)
    return axios.post(endpoint, profileData, { headers: getHeadersWithToken() })
  }
  getProfileData = async (storeID: string): Promise<UserData> => {
    const endpoint = this.generateProfileEndpoint(storeID)
    const userData = await axios.get(endpoint, { headers: getHeadersWithToken() })
    return userData.data
  }
  resetProfileData = (storeID: string) => {
    const endpoint = this.generateProfileEndpoint(storeID)
    return axios.delete(endpoint, { headers: getHeadersWithToken() })
  }
}
const frameGeniusService = new FrameGeniusService()
export default frameGeniusService
