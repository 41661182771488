import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMoneyBackGuaranteeEuroPriceMatch = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M14.56 14.482c-.629.912-1.57 1.436-2.582 1.436-1.498 0-2.765-1.158-3.166-2.74H7.81a.388.388 0 0 1 0-.776h.88a4.308 4.308 0 0 1-.001-.58h-.88a.388.388 0 0 1 0-.776h1c.4-1.584 1.67-2.745 3.168-2.745 1.011 0 1.952.523 2.582 1.436a.388.388 0 0 1-.638.44c-.483-.698-1.19-1.1-1.944-1.1-1.08 0-2.004.822-2.364 1.97h2.494a.388.388 0 0 1 0 .776H9.464a3.511 3.511 0 0 0 0 .58h2.643a.388.388 0 0 1 0 .776H9.615c.36 1.147 1.283 1.964 2.362 1.964.752 0 1.461-.4 1.944-1.1a.388.388 0 1 1 .639.441v-.002Zm2.463-8.7a8.008 8.008 0 0 0-9.378-.369l.15-.916a.388.388 0 0 0-.538-.418.415.415 0 0 0-.172.139.383.383 0 0 0-.068.164l-.295 1.81a.44.44 0 0 0 .082.323c.06.082.149.135.248.152l1.816.3a.387.387 0 0 0 .424-.243.466.466 0 0 0 .028-.116l.003-.02a.388.388 0 0 0-.324-.424l-.865-.142a7.23 7.23 0 0 1 8.41.371 7.219 7.219 0 0 1 1.203 10.127 7.163 7.163 0 0 1-4.815 2.7 7.167 7.167 0 0 1-5.312-1.496 7.218 7.218 0 0 1-2.74-5.29.387.387 0 1 0-.774.04 7.988 7.988 0 0 0 3.034 5.859 7.929 7.929 0 0 0 5.884 1.657 7.934 7.934 0 0 0 5.332-2.989c2.725-3.46 2.127-8.49-1.333-11.216v-.003Z"
      />
    </SvgIcon>
  )
}
export default SvgMoneyBackGuaranteeEuroPriceMatch
