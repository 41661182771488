import { currentPageSeoDataSelector } from '../../features/seo/selector'
import { useSelector } from 'react-redux'
import { openDrawerSearchSelector } from '../../features/ui/selector'
import { useRouter } from 'next/router'
import {
  CART,
  WISHLIST,
  PRESCRIPTION_VERIFICATION,
  STORELOCATOR,
  SIGNIN,
  SITEMAP,
  CHECKOUT,
  CHECKOUT_CHILDREN,
  ORDER_RETURN_SELECT,
  ORDER_RETURN,
  ORDER_RETURN_CONFIRMATION,
  ORDER_RETURN_PRINT,
  NOT_FOUND,
  SEARCH,
} from '../../constants/routes'

export const usePageType = () => {
  const router = useRouter()
  const urlIdentifier = router.asPath.split('?')[0]

  const seoData = useSelector(currentPageSeoDataSelector())
  const isSearchDrawerVisible = useSelector(openDrawerSearchSelector)

  const seoPageType: string = seoData?.page?.type || ''
  const pageTypes = {
    search: isSearchDrawerVisible || urlIdentifier === `${SEARCH}`,
    home: urlIdentifier === '/' || urlIdentifier === '/c',
    cart: urlIdentifier === `/${CART}`,
    checkout:
      urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.UPLOAD_PRESCRIPTION}` ||
      urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.SHIPPING}` ||
      urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.PAYMENT}` ||
      urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.FINALIZE}` ||
      urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.ORDER_CONFIRMATION}`,
    checkoutExternal: urlIdentifier === `/${PRESCRIPTION_VERIFICATION}`,
    plp: seoPageType === 'ProductListPage' || seoPageType === 'CategoryPage',
    pdp: seoPageType === 'ProductPage' || seoPageType === 'ItemPage',
    sitemap: urlIdentifier === `/${SITEMAP}`,
    wishlist: urlIdentifier === `/${WISHLIST}`,
    storeLocator: urlIdentifier === `/${STORELOCATOR}`,
    signIn: urlIdentifier === `/${SIGNIN}`,
    page404: seoPageType === 'NotFound' || urlIdentifier === `${NOT_FOUND}`,
    orderReturnSelect: urlIdentifier === `/${ORDER_RETURN}/${ORDER_RETURN_SELECT}`,
    orderReturnConfirm: urlIdentifier === `/${ORDER_RETURN}/${ORDER_RETURN_CONFIRMATION}`,
    orderReturnPrint: urlIdentifier === `/${ORDER_RETURN}/${ORDER_RETURN_PRINT}`,
  } as const

  return { pageType: Object.keys(pageTypes).find(key => pageTypes[key]) }
}
