import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgHat = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M20.378 15.919a.29.29 0 0 0-.087-.144l-2.879-2.61-.01-.603a5.2 5.2 0 0 0-.018-.347l-.008-.097a5.051 5.051 0 0 0-5.56-4.521 5.09 5.09 0 0 0-4.446 4.03l-1.604-.286a.305.305 0 0 0-.347.234.3.3 0 0 0 .247.36l1.138.202-1.407.9a.301.301 0 0 0-.09.418.29.29 0 0 0 .185.127l.01.001a.298.298 0 0 0 .22-.04l1.545-.988a2.53 2.53 0 0 0 0 .16l.01.602-2.794 2.695a.301.301 0 0 0 .214.52 3.555 3.555 0 0 1 1.793.403 4.15 4.15 0 0 0 2.064.462h.037c.705 0 1.403-.182 2.019-.526.53-.3 1.132-.457 1.74-.457H12.4a3.565 3.565 0 0 1 1.79.402c.633.329 1.35.49 2.064.463h.037c.705 0 1.403-.181 2.02-.526a3.55 3.55 0 0 1 1.74-.457h.046a.303.303 0 0 0 .282-.38v.003Zm-8.11-7.746h.063a4.445 4.445 0 0 1 4.334 3.366l-8.69.132a4.492 4.492 0 0 1 4.294-3.497Zm-4.394 4.285.004-.066.002-.032c.002-.028.003-.057.006-.085l8.887-.134.008.08.003.032.007.071c.007.092.01.173.01.249l.008.432-8.931.135-.007-.432c-.001-.077 0-.159.003-.25Zm10.157 3.765a3.55 3.55 0 0 1-1.74.457h-.049a3.557 3.557 0 0 1-1.79-.402 4.163 4.163 0 0 0-2.064-.463h-.037c-.705 0-1.403.181-2.019.525-.53.299-1.132.457-1.74.457h-.049a3.56 3.56 0 0 1-1.79-.404 4.097 4.097 0 0 0-1.354-.426l2.307-2.223 9.296-.142 2.373 2.153a4.093 4.093 0 0 0-1.342.467h-.002Z"
      />
    </SvgIcon>
  )
}
export default SvgHat
