import { IVideoMedia } from '@typesApp/cmsPlacement/Media'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { TVideoSourceType } from '../types'

type TSelectedVideo = {
  video?: IVideoMedia
  sourceType: TVideoSourceType
}

export const useVideo = (media?: IVideoMedia[]): TSelectedVideo => {
  const [desktopVideo, mobileVideo] = media ?? []
  const { isMobile } = useBreakpoint('sm')

  return {
    video: isMobile && mobileVideo ? mobileVideo : desktopVideo,
    sourceType: isMobile && mobileVideo ? 'mobile' : 'desktop',
  }
}
