import { locationOriginSelector } from '@redux/selectors/site'
import { parseAndReplaceLocaleInCMSResponse } from '@utils/locale'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

const useReplaceLocaleInCMSUrl = () => {
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)

  const replaceLocaleInCmsUrls = (cmsValue: string | undefined): string => {
    return parseAndReplaceLocaleInCMSResponse(isLocaleDomain, locale, cmsValue || '', locationOrigin)
  }

  return replaceLocaleInCmsUrls
}

export default useReplaceLocaleInCMSUrl
