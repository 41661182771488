import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSave10 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="m8.227 11.208-.526.132-.126-.754.895-.258h.657v3.56h-.9v-2.68Zm1.366.95v-.075c0-1.051.556-1.8 1.452-1.8.895 0 1.442.738 1.442 1.795v.075c0 1.052-.557 1.801-1.452 1.801-.896 0-1.442-.738-1.442-1.795Zm1.977 0v-.07c0-.647-.217-.992-.536-.992-.32 0-.527.334-.527.98v.072c0 .647.213.992.537.992.323 0 .526-.34.526-.981Zm1.24-.87v-.04c0-.551.329-.947.814-.947.485 0 .809.395.809.947v.035c0 .551-.33.946-.815.946-.485 0-.809-.395-.809-.941Zm2.766-.94h.597l-1.3 1.892-1.097 1.649h-.598l1.295-1.877 1.103-1.664Zm-2.2.9v.035c0 .263.1.43.248.43.147 0 .243-.162.243-.43v-.03c0-.268-.097-.43-.249-.43s-.242.162-.242.425Zm1.542 1.74v-.036c0-.55.324-.945.815-.945.491 0 .81.395.81.94v.042c0 .546-.33.946-.816.946-.485 0-.809-.394-.809-.946v-.001Zm1.058 0v-.036c0-.262-.097-.43-.249-.43-.151 0-.248.162-.248.43v.03c0 .268.101.43.253.43s.243-.162.243-.424Zm1.015-7.223a8.012 8.012 0 0 0-9.382-.37l.15-.917a.388.388 0 0 0-.544-.416.396.396 0 0 0-.236.3l-.295 1.81a.445.445 0 0 0 .083.326.39.39 0 0 0 .246.149l1.817.3a.389.389 0 0 0 .427-.246.439.439 0 0 0 .027-.113l.002-.02a.388.388 0 0 0-.324-.424l-.866-.142a7.233 7.233 0 0 1 8.413.371c3.125 2.462 3.665 7.006 1.203 10.131a7.166 7.166 0 0 1-4.816 2.7 7.166 7.166 0 0 1-5.315-1.496 7.22 7.22 0 0 1-2.74-5.292.389.389 0 0 0-.775.04A7.991 7.991 0 0 0 7.1 18.319a7.95 7.95 0 0 0 4.936 1.71 7.983 7.983 0 0 0 6.285-3.043c2.727-3.46 2.128-8.494-1.332-11.22h.001Z"
      />
    </SvgIcon>
  )
}
export default SvgSave10
