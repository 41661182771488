import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const Svg12HrsPlusEs = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M20.129 12.344a8.007 8.007 0 0 1-3.042 5.872 7.968 7.968 0 0 1-4.945 1.714 7.999 7.999 0 0 1-6.298-3.049C3.112 13.413 3.712 8.371 7.18 5.638a8.028 8.028 0 0 1 9.4-.37l-.15-.918a.388.388 0 0 1 .542-.418.42.42 0 0 1 .168.135c.037.05.061.107.072.167l.295 1.816a.446.446 0 0 1-.078.318.39.39 0 0 1-.252.156l-1.821.301a.387.387 0 0 1-.429-.252.44.44 0 0 1-.024-.11l-.003-.02a.389.389 0 0 1 .325-.424l.867-.142a7.247 7.247 0 0 0-8.43.372c-3.13 2.466-3.671 7.02-1.206 10.151 2.466 3.131 7.02 3.673 10.151 1.206a7.236 7.236 0 0 0 2.747-5.302.388.388 0 1 1 .776.04h-.001ZM7.9 12.986h.744v-.746h.73v-.722h-.73v-.745H7.9v.745H7.17v.722h.73v.745Zm2.392.522h.902V9.935h-.659l-.897.263.126.755.528-.136V13.509Zm1.354 0H14.1v-.785h-1.293l.593-.552c.467-.43.69-.72.69-1.196v-.015c0-.639-.487-1.06-1.176-1.06-.552 0-.928.224-1.287.644l.542.578c.253-.269.43-.4.648-.4.218 0 .36.136.36.339s-.111.36-.401.644l-1.13 1.085v.72-.002Zm2.814 0h.814v-1.474c0-.231.124-.357.3-.357.177 0 .287.125.287.357v1.474h.814V11.78c0-.504-.273-.827-.74-.827-.318 0-.517.185-.66.379v-1.17h-.814v3.348h-.001Z"
      />
    </SvgIcon>
  )
}
export default Svg12HrsPlusEs
