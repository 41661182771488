import { IconButton } from '@components/UI/IconButton'
import styled from '@mui/material/styles/styled'
import Dialog from '@mui/material/Dialog'

export const StyledCtaModalDialog = styled(Dialog, {
  name: 'StyledDialog',
  slot: 'Dialog',
  shouldForwardProp: prop => prop !== 'fullHeightOnSmallScreens',
})<{ fullHeightOnSmallScreens: boolean }>(({ theme, fullHeightOnSmallScreens }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: '100%',
      width: '100%',
      height: fullHeightOnSmallScreens ? '100%' : 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: theme.spacing(1),
      maxWidth: 576,
    },
    [theme.breakpoints.up('md')]: {},
  },
  '& .MuiDialogContent-root': {
    padding: `${theme.spacing(10)} ${theme.spacing(8)} ${theme.spacing(5)}`,
  },
}))
export const StyledCtaModalTitle = styled('div', {
  name: 'StyledTitle',
  slot: 'Title',
})(({ theme }) => ({
  textAlign: 'center',
  fontSize: 16,
  fontWeight: 'bold',
  marginBottom: theme.spacing(4),
}))
export const StyledCtaModalBody = styled('div', {
  name: 'StyledCtaModal',
  slot: 'Body',
})(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& p, & li': {
    fontSize: 10,
    lineHeight: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  '& ol': {
    paddingLeft: theme.spacing(6),
  },
}))
export const StyledCtaModalCloseButton = styled(IconButton, {
  name: 'StyledDialog',
  slot: 'CloseButton',
})(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(6),
  right: theme.spacing(6),
}))
export const StyledCtaModalButtonsContainer = styled('div', {
  name: 'StyledCtaModal',
  slot: 'Container',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'center',
}))
