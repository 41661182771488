import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMultipleChoiceDash = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path fill="currentColor" d="M8 11h8v2H8z" />
    </SvgIcon>
  )
}
export default SvgMultipleChoiceDash
