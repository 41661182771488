import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgReusable = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M9.13 8.542c2.292-1.857 5.61-2.274 7.905-2.427a.366.366 0 0 1 .188.693c-.27.135-.667.712-.918 1.213-.411.816-.39 1.69-.368 2.617.023.903.045 1.838-.352 2.737-.728 1.646-2.661 2.523-4.358 2.523-.376 0-.74-.043-1.076-.13a.367.367 0 1 1 .184-.71c1.958.507 3.984-.637 4.579-1.98.331-.75.31-1.562.29-2.423-.023-.976-.048-1.986.447-2.965a6.28 6.28 0 0 1 .456-.765c-2.087.204-4.684.703-6.514 2.186-1.58 1.28-2.01 3.128-1.209 5.038a10.575 10.575 0 0 1 3.529-4.208.366.366 0 1 1 .421.6 9.897 9.897 0 0 0-3.854 5.442.367.367 0 1 1-.705-.2c.07-.246.15-.486.235-.723-1.307-2.405-.88-4.897 1.12-6.518Zm10.647-.109-1.633-.702-.01-.003a.259.259 0 0 0-.05-.015c-.005 0-.01-.003-.014-.004a.377.377 0 0 0-.06-.006h-.048c-.006 0-.011.002-.017.003a.28.28 0 0 0-.068.017l-.017.005a.36.36 0 0 0-.053.028l-.019.013a.857.857 0 0 0-.033.025l-.017.016a.342.342 0 0 0-.04.048l-.002.004a.333.333 0 0 0-.03.054l-.004.006-.003.01c-.003.006-.007.013-.009.02l-.583 1.759a.368.368 0 0 0 .697.231l.3-.901a6.94 6.94 0 0 1 .51 2.612 6.929 6.929 0 0 1-6.92 6.92 6.929 6.929 0 0 1-6.92-6.92 6.929 6.929 0 0 1 6.92-6.92c1.1 0 2.151.25 3.125.744a.367.367 0 0 0 .331-.654A7.577 7.577 0 0 0 11.654 4C7.434 4 4 7.433 4 11.654c0 4.22 3.433 7.654 7.654 7.654 4.22 0 7.654-3.433 7.654-7.654 0-.983-.19-1.956-.553-2.86l.734.315a.365.365 0 0 0 .481-.192.368.368 0 0 0-.192-.483h-.001Z"
      />
    </SvgIcon>
  )
}
export default SvgReusable
