import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgBook = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="m20.195 8.17-1.053-.266V6.808a.388.388 0 0 0-.32-.382 8.997 8.997 0 0 0-2.406-.036c-1.532.18-2.813.741-3.744 1.635a8.06 8.06 0 0 0-.891-1.437C10.733 5.251 9.416 4.571 7.973 4.62a.389.389 0 0 0-.376.39v1.293a7.912 7.912 0 0 0-1.482.125.39.39 0 0 0-.315.383v1.074a7.66 7.66 0 0 0-1.055.288.39.39 0 0 0-.255.366v9.039a.392.392 0 0 0 .53.364c.034-.014 3.447-1.295 7.31.49.005.002.008.002.013.005a.312.312 0 0 0 .056.018l.02.005a.42.42 0 0 0 .075.008h.019c.012 0 .024 0 .037-.003.007 0 .015-.004.023-.005a.13.13 0 0 0 .02-.005l.01-.003a.577.577 0 0 0 .061-.023l.01-.003c.035-.018 3.46-1.77 7.3-.455a.39.39 0 0 0 .516-.369V8.554a.39.39 0 0 0-.294-.377V8.17Zm-3.71-1.005a8.741 8.741 0 0 1 1.877-.015v.924l-.058 7.47c-2.765-.291-4.529.774-5.423 1.554V8.94c.824-.991 2.036-1.589 3.603-1.776v.001Zm-8.11-1.76c2.241.17 3.424 2.545 3.728 3.253v7.572c-1.185-1.886-2.917-2.163-3.728-2.18V5.404ZM6.578 7.139c.384-.05.756-.06 1.018-.06v7.374c0 .114.05.221.136.296.085.074.2.107.312.089.007-.001.741-.1 1.593.241.714.286 1.302.784 1.759 1.486-1.87-1.249-3.906-1.16-4.818-1.034V7.139Zm1.42 9.551c-1.212 0-2.173.2-2.732.353V8.816a7.18 7.18 0 0 1 .533-.138v7.318a.39.39 0 0 0 .484.377c.115-.028 2.288-.538 4.349.636A11.025 11.025 0 0 0 8 16.69h-.001Zm11.712.376c-2.069-.585-3.965-.395-5.32-.07.95-.512 2.345-.915 4.242-.626a.39.39 0 0 0 .449-.383l.056-7.283.574.145V17.066Z"
      />
    </SvgIcon>
  )
}
export default SvgBook
