import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgDollar = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M15.184 13.762c-.195-.349-.511-.665-.936-.94-.423-.274-1.064-.558-1.903-.848-.797-.284-1.367-.53-1.694-.733-.324-.201-.561-.43-.702-.68-.142-.25-.215-.576-.215-.969 0-.623.235-1.123.698-1.484.466-.364 1.101-.548 1.888-.548.787 0 1.671.188 2.56.559l.025.011a.38.38 0 0 0 .293-.7l-.025-.013a7.17 7.17 0 0 0-2.656-.573V4.809a.39.39 0 0 0-.778 0v2.063c-.746.08-1.383.326-1.892.737-.638.512-.96 1.184-.96 1.996 0 .739.215 1.337.64 1.782.422.44 1.195.858 2.296 1.236.749.255 1.323.495 1.71.714.382.218.662.462.832.726.169.262.255.599.255 1 0 .648-.26 1.179-.769 1.577-.511.4-1.215.604-2.091.604-1.11 0-2.048-.143-2.792-.423l-.04-.012a.376.376 0 0 0-.467.258.377.377 0 0 0 .254.466l.059.023c.69.285 1.689.431 2.967.437v2.038a.39.39 0 0 0 .778 0v-2.08c.784-.091 1.441-.343 1.958-.754.666-.53 1.004-1.258 1.004-2.164 0-.494-.099-.922-.296-1.273v.002Z"
      />
    </SvgIcon>
  )
}
export default SvgDollar
