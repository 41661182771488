import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgOpeningHours = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M17.158 16.975a.385.385 0 0 1-.224-.697 6.543 6.543 0 0 0 2.717-5.297 6.53 6.53 0 0 0-6.523-6.523 6.541 6.541 0 0 0-5.317 2.743.385.385 0 0 1-.625-.446 7.31 7.31 0 0 1 5.942-3.065c4.02 0 7.291 3.27 7.291 7.29a7.312 7.312 0 0 1-3.036 5.921.378.378 0 0 1-.224.073Zm-.806-5.812a.385.385 0 0 0-.385-.384H13.3l-.04-2.91a.384.384 0 0 0-.383-.38h-.006a.384.384 0 0 0-.379.39l.045 3.29c.004.21.175.378.385.378h3.046a.385.385 0 0 0 .385-.384h-.002Zm-2.894 8.527c.641-.01 1.115-.11 1.54-.324.463-.235.794-.567.981-.989.281-.632.178-1.274-.298-1.857-.461-.565-1.045-1.076-1.836-1.61-.22-.148-.571-.27-1-.015-.33.197-.63.42-.915.682a.82.82 0 0 1-.18.139.754.754 0 0 1-.17-.084 8.067 8.067 0 0 1-2.921-2.929c-.12-.206-.086-.271-.068-.306.047-.091.128-.185.193-.256.056-.061.12-.12.186-.185a3.83 3.83 0 0 0 .232-.232c.16-.176.25-.398.266-.66.02-.305-.065-.602-.258-.91a5.266 5.266 0 0 0-1.587-1.596c-.268-.176-.586-.37-.976-.457-.56-.124-1.056.024-1.43.43a2.013 2.013 0 0 0-.471.888c-.09.36-.15.684-.182.988a4.757 4.757 0 0 0 .071 1.484c.15.718.448 1.434.911 2.193.748 1.223 1.65 2.3 2.684 3.201 1.023.891 2.08 1.536 3.232 1.97.733.275 1.38.417 1.978.434H13.458ZM6.481 8.852c.237.052.46.177.721.349.563.368 1.02.827 1.358 1.364.108.17.15.308.142.451-.008.126-.054.176-.068.193-.058.063-.123.125-.193.191-.073.07-.15.143-.223.224-.098.107-.221.251-.31.423-.245.475.004.904.087 1.044a8.81 8.81 0 0 0 3.196 3.206c.498.292.815.255 1.258-.15.247-.227.506-.42.79-.59.1-.059.124-.042.176-.007.728.49 1.258.954 1.671 1.459.293.357.353.694.192 1.059-.115.259-.32.46-.627.614-.316.16-.675.232-1.196.242-.508-.015-1.071-.142-1.72-.385-1.066-.401-2.047-1-2.998-1.829a12.972 12.972 0 0 1-2.533-3.023c-.416-.681-.683-1.319-.815-1.949a4.004 4.004 0 0 1-.06-1.245c.03-.27.083-.558.164-.883.055-.217.153-.404.291-.554a.633.633 0 0 1 .49-.226c.065 0 .135.009.21.025l-.003-.003Z"
      />
    </SvgIcon>
  )
}
export default SvgOpeningHours
