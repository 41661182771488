import React from 'react'
import { StyledPriceWrapper } from './ProductTilePrice.style'
import ProductPriceNew from '@pages_views/ProductDetails/components/ProductPriceNew'
import { TPrice } from '@components/common/UI/ProductPrice/index.types'
import { parseProductTypeForPrice } from '@components/common/UI/ProductPrice/utils/utils'

export interface ProductTilePriceProps {
  price: TPrice | null
  productType: string
}

function ProductTilePrice(props: ProductTilePriceProps): JSX.Element | null {
  const { price, productType } = props
  return (
    <StyledPriceWrapper>
      <ProductPriceNew
        isPDP={false}
        isCL={true}
        productType={parseProductTypeForPrice(productType)}
        isCompact={true}
        price={price}
        productQuantity={['1']}
      />
    </StyledPriceWrapper>
  )
}

export default ProductTilePrice
