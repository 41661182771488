import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgContactBlisterPack = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.713 4.08A5.468 5.468 0 0 0 6.25 9.543v3.866c0 1.826.853 3.56 2.401 4.882 1.273 1.086 2.932 1.789 4.227 1.789h2.021c1.495 0 2.277-1.15 2.277-2.286v-8.25a5.47 5.47 0 0 0-5.462-5.463l-.002-.001Zm3.185 15.223h-2.021c-2.063 0-5.85-2.239-5.85-5.894V9.543a4.69 4.69 0 0 1 4.684-4.686 4.69 4.69 0 0 1 4.685 4.686v8.25c0 .75-.463 1.508-1.499 1.508l.001.002ZM11.713 6.017a3.55 3.55 0 0 0-3.546 3.546c0 .945.38 2.095.99 3 .717 1.063 1.625 1.648 2.556 1.648.93 0 1.838-.585 2.554-1.647.611-.906.991-2.056.991-3.001a3.55 3.55 0 0 0-3.545-3.546Zm0 7.417c-1.4 0-2.768-2.216-2.768-3.871a2.771 2.771 0 0 1 2.768-2.768 2.771 2.771 0 0 1 2.768 2.768c0 1.653-1.369 3.87-2.768 3.87Zm2.768 2.539a.39.39 0 0 1 .777 0v.663c0 1.341-.169 1.511-1.506 1.511h-1.195a.39.39 0 0 1 0-.777h1.195c.292 0 .594 0 .697-.031.031-.104.031-.408.031-.703v-.663Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  )
}
export default SvgContactBlisterPack
