import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import styled from '@mui/material/styles/styled'

export const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    margin: 0,
    padding: 0,
    fontSize: 14,
    fontWeight: 600,
    borderBottom: '1px solid',
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: 'inherit',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      margin: 0,
      color: 'inherit',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      padding: `${theme.spacing(4)} 0`,
    },
  })
)
