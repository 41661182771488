import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCostEffectiveSaving = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M9.813 7.721c-.795 0-1.44-.61-1.44-1.36C8.373 5.61 9.018 5 9.813 5c.794 0 1.439.61 1.439 1.36 0 .75-.646 1.361-1.44 1.361Zm0-2.124c-.465 0-.842.342-.842.762s.377.764.842.764c.464 0 .84-.343.84-.764 0-.42-.377-.762-.84-.762Zm9.837 6.158h-.8c-.216-.56-.877-1.855-2.482-2.324v-2.4a.349.349 0 0 0-.337-.349c-.994-.033-2.957.387-3.573 2.162H8.93l-.098-.002h-.01A4.665 4.665 0 0 0 5.09 10.76a.876.876 0 0 1-.48-.647.97.97 0 0 0 .993-.017.7.7 0 0 0 .324-.52.698.698 0 0 0-.228-.568.94.94 0 0 0-.561-.243.98.98 0 0 0-.308.023.883.883 0 0 1 .586-.403.299.299 0 1 0-.11-.588 1.483 1.483 0 0 0-1.2 1.622c-.136.34-.142.713-.014 1.06.124.339.362.614.676.786a4.628 4.628 0 0 0-.577 2.24c0 1.796 1.185 3.182 2.354 3.925v1.578a.35.35 0 0 0 .349.349h3.028a.35.35 0 0 0 .35-.35v-1.091h1.663v1.092a.35.35 0 0 0 .349.349h2.924a.35.35 0 0 0 .349-.35v-1.153c.66-.16 2.215-.71 3.036-2.47l1.075-.051a.349.349 0 0 0 .332-.349v-2.88a.35.35 0 0 0-.349-.35v.001ZM5.05 9.36c.012 0 .024 0 .036.002.08.007.155.037.204.082.013.013.045.047.042.081-.003.034-.04.062-.055.073a.335.335 0 0 1-.215.046c-.122-.011-.213-.074-.239-.13-.007-.017-.008-.028-.007-.035.005-.051.099-.12.234-.12Zm14.252 5.294-.958.046a.35.35 0 0 0-.306.215c-.834 2.025-2.79 2.296-2.872 2.306a.348.348 0 0 0-.307.346v1.092h-2.226v-1.092a.35.35 0 0 0-.349-.349H9.922a.35.35 0 0 0-.348.349v1.092H7.243v-1.425a.346.346 0 0 0-.174-.3c-1.324-.773-2.18-2.118-2.18-3.429A3.975 3.975 0 0 1 8.82 9.54l.096.002H12.717c.16 0 .3-.109.338-.263.387-1.528 1.924-1.829 2.615-1.885v2.31c0 .165.115.306.275.341 1.828.398 2.3 2.08 2.318 2.149.04.153.178.26.338.26h.701v2.2Zm-2.33-2.171a.35.35 0 0 0 .133-.475 2.083 2.083 0 0 0-.997-.892.35.35 0 0 0-.272.642c.278.118.513.328.661.592a.348.348 0 0 0 .475.133Z"
      />
    </SvgIcon>
  )
}
export default SvgCostEffectiveSaving
