import config from '@configs/index'
import { Attachment } from '@typesApp/product'
import { ParsedQuery } from 'query-string'
import { Usage } from './types'

const srcSetMapMediaQuery = {
  300: '(max-width: 320px)',
  400: '(min-width: 321px) and (max-width: 640px)',
  600: '(min-width: 641px) and (max-width: 960px)',
  700: '(min-width: 961px) and (max-width: 1280px)',
  900: '(min-width: 1281px) and (max-width: 1920px)',
}

export const generateProductImagePath = (
  host: string,
  attachments?: Attachment[],
  usage?: Usage,
  sequence?: string,
  isAccessoriesProduct?: boolean,
  isFramesProduct?: boolean
): string | null => {
  const attachment = attachments?.find(attachment => {
    return !isAccessoriesProduct
      ? attachment.usage?.toLowerCase() === usage?.toLowerCase() &&
          (sequence
            ? attachment.sequence.toLowerCase() === sequence?.toLowerCase() ||
              (isFramesProduct && attachment.sequence.toLowerCase() === '1.0')
            : true)
      : sequence
        ? attachment.sequence.toLowerCase() === sequence.toLowerCase()
        : true
  })

  return attachment ? host + attachment?.attachmentAssetPathRaw : null
}

export const addAkamaiParameters = (
  baseURL: string | null,
  width?: number,
  backgroundColor?: string,
  attr?: ParsedQuery<string>
) => {
  if (!baseURL) {
    return config.publicUrl + 'images/common/404.svg'
  }

  const url = new URL(baseURL)
  url.searchParams.append('impolicy', config.productImageAkamaiPolicy)

  if (width) {
    url.searchParams.append('wid', String(width))
  }

  if (attr) {
    for (const key in attr) {
      url.searchParams.append(key, attr[key] as string)
    }
  }

  // TODO use bgcolor param once Akamai fixes the issue with the background color
  url.searchParams.append('bgc', backgroundColor || `#${config.productImageAkamaiDefaultBgColor}`)

  return url.toString()
}

export const generateSrcSet = (
  baseURL: string | null,
  srcSetMap: Record<number, string> | undefined,
  backgroundColor?: string,
  attr?: ParsedQuery<string>
): string | undefined => {
  if (!baseURL || !srcSetMap) {
    return undefined
  }
  const _srcSet: string[] = []

  for (const width in srcSetMap) {
    _srcSet.push(`${addAkamaiParameters(baseURL, Number(width), backgroundColor, attr)} ${srcSetMap[width]}`)
  }

  return _srcSet.join(', ')
}

export const getMediaQuerySrcSet = (
  srcSetMap?: Record<number, string>,
  srcSetString?: string
): {
  src: string
  media: string
}[] => {
  if (!srcSetString || !srcSetMap) return []

  const urlMap = srcSetString.split(', ').reduce((acc, item) => {
    const [url = ''] = item.split(' ')
    const match = url.match(/wid=(\d+)/)
    if (match) {
      const widthKey = parseInt(match[1], 10)
      acc[widthKey] = url
    }
    return acc
  }, {})

  return Object.keys(srcSetMap).map(key => {
    const srcItem = urlMap[key]
    return {
      src: srcItem,
      media: srcSetMapMediaQuery[key],
    }
  })
}

export const isVideoURL = (url: string | null): boolean => {
  return url ? /\.(mp4|webm|ogg)$/i.test(url) : false
}
