import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgContactLensUpgrade = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M11.54 19.903a7.018 7.018 0 0 1-6.802-5.333l-.404.65a.39.39 0 0 1-.245.175l-.014.003a.387.387 0 0 1-.293-.05.4.4 0 0 1-.127-.552l.936-1.508a.387.387 0 0 1 .214-.166.403.403 0 0 1 .335.034l1.512.94a.398.398 0 0 1-.116.724l-.015.003a.39.39 0 0 1-.294-.049l-.727-.452a6.202 6.202 0 0 0 6.758 4.737.39.39 0 0 1 .435.363.39.39 0 0 1-.342.432 7.148 7.148 0 0 1-.812.047l.001.002Zm-7.221-4.697-.002.003s0-.003.002-.003Zm11.756-7.592C12.99 4.528 9.44 3.242 7.996 4.685l-.059.066c-.006.006-.014.01-.021.017-.583.583-1.237 1.907-1.16 3.723.06 1.394.586 3.51 2.76 5.685 2.01 2.012 4.132 2.545 5.556 2.639.149.01.301.014.456.014 1.229 0 2.653-.31 3.395-1.052a.293.293 0 0 0 .034-.043c.015-.013.031-.025.046-.04 1.444-1.444.158-4.993-2.929-8.079h.001Zm-.472.473c2.727 2.727 4.068 5.994 2.929 7.134-1.14 1.14-4.408-.202-7.135-2.929-1.374-1.375-2.427-2.9-2.964-4.293-.496-1.287-.482-2.323.036-2.841.276-.276.678-.407 1.167-.407 1.526 0 3.901 1.27 5.967 3.336Zm-.486 8.06c-1.31-.085-3.263-.58-5.128-2.443-2.326-2.327-2.78-4.774-2.486-6.499.337 1.641 1.546 3.686 3.42 5.56 1.78 1.78 3.713 2.96 5.308 3.365-.346.036-.72.044-1.115.018h.001Zm1.934-2.086c.019.17.164.296.332.296.012 0 .026 0 .038-.002a.333.333 0 0 0 .294-.37c-.235-2.085-1.973-4.1-2.988-5.114a.334.334 0 0 0-.472.472c1.622 1.622 2.641 3.341 2.796 4.717v.001Z"
      />
    </SvgIcon>
  )
}
export default SvgContactLensUpgrade
