import { Typography } from '@mui/material'
import { styled, css } from '@mui/material/styles'

export const StyledPriceWrapper = styled(Typography)(() => ({
  '& > * + *': {
    marginLeft: '10px',
  },
}))

export const StyledRrpPrice = styled('span', {
  shouldForwardProp: prop => prop !== 'isOnOffer',
})<{ isOnOffer?: boolean }>`
  ${({ theme, isOnOffer }) =>
    isOnOffer &&
    css`
      text-decoration: line-through;
      color: ${theme.palette.error.main};
    `}
`
