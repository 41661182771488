import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSave5 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M7.275 11.87h1.569v.805H7.275v-.804Zm1.831 1.599.45-.667c.249.191.481.313.734.313.298 0 .481-.172.481-.434v-.006c0-.263-.203-.42-.48-.42-.183 0-.33.061-.466.136l-.511-.283.096-1.796h2.1v.78h-1.386l-.036.546c.132-.056.273-.091.46-.091.582 0 1.109.333 1.109 1.108v.016c0 .789-.562 1.249-1.336 1.249a1.8 1.8 0 0 1-1.213-.45l-.002-.001Zm3.82-2.216v-.04c0-.552.329-.947.819-.947.49 0 .809.394.809.94v.042c0 .546-.334.945-.815.945-.48 0-.814-.394-.814-.94Zm2.772-.94h.597l-1.326 1.907-1.077 1.633H13.3l1.31-1.892 1.088-1.649Zm-2.206.9v.035c0 .262.1.424.253.424.152 0 .243-.156.243-.424v-.036c0-.262-.101-.424-.249-.424-.157 0-.248.157-.248.424h.001Zm1.543 1.74v-.036c0-.551.323-.946.814-.946.491 0 .81.39.81.941v.036c0 .55-.33.95-.815.95-.486 0-.81-.394-.81-.946Zm1.057 0v-.036c0-.268-.096-.43-.248-.43s-.243.162-.243.424v.036c0 .268.101.43.248.43.148 0 .243-.162.243-.425Zm.909-7.188a8.012 8.012 0 0 0-9.382-.37l.15-.917a.388.388 0 0 0-.544-.416.396.396 0 0 0-.236.3l-.295 1.81a.445.445 0 0 0 .083.326.39.39 0 0 0 .246.149l1.817.3a.389.389 0 0 0 .427-.246.439.439 0 0 0 .027-.113l.002-.02a.388.388 0 0 0-.324-.424l-.866-.142a7.233 7.233 0 0 1 8.413.371 7.222 7.222 0 0 1 1.204 10.131 7.166 7.166 0 0 1-4.817 2.7 7.16 7.16 0 0 1-5.315-1.496 7.22 7.22 0 0 1-2.74-5.292.389.389 0 0 0-.775.04 7.991 7.991 0 0 0 3.035 5.862 7.95 7.95 0 0 0 4.936 1.71 7.983 7.983 0 0 0 6.285-3.043C21.06 13.525 20.46 8.491 17 5.765H17Z"
      />
    </SvgIcon>
  )
}
export default SvgSave5
