import { IProduct } from '../product'
import { ICMAlgolia } from './CMAlgolia'
import { ICMArticle } from './CMArticle'
import { ICMChannel } from './CMChannel'
import { ICMCollection } from './CMCollection'
import { ICMExternalChannel } from './CMExternalChannel'
import { ICMExternalLink } from './CMExternalLink'
import { ICMExternalPage } from './CMExternalPage'
import { ICMExternalProduct } from './CMExternalProduct'
import { ICMHtml } from './CMHtml'
import { ICMPlaceholder } from './CMPlaceholder'
import { ICMProductList } from './CMProductList'
import { ICMProductTeaser } from './CMProductTeaser'
import { ICMQueryList } from './CMQueryList'
import { ICMTeaser } from './CMTeaser'
import { LXDynamicContent } from './LXDynamicContent'
import { ILXTeaser, ITeaserCallToAction } from './LXTeaser'
import { IPictureMedia, IVideoMedia, TMedia } from './Media'
import { IViewType } from './ViewType'

export interface IPlacement<ItemType = IPlacementItem> {
  collectionTitle: string
  viewtype: IViewType
  name: string
  marginLateral: boolean
  teaserLXCallToActionSettings: ITeaserCallToAction[]
  placementReflect: boolean
  placementCenter?: boolean
  marginVertical: 'X' | 'S' | 'M' | 'L'
  backgroundColor: React.CSSProperties['backgroundColor']
  clusterTile: boolean
  placementAnimation: string
  cta?: string
  items: ItemType[]
  currentProduct?: IProduct
  loaded?: boolean
  lazy?: boolean
}
export interface IBasePlacementItem {
  id?: string
  name?: string
  viewtype: IViewType
}

export type IPlacementItem =
  | ICMArticle
  | ICMChannel
  | ICMCollection
  | ICMExternalLink
  | ICMExternalPage
  | ICMExternalProduct
  | ICMProductTeaser
  | ILXTeaser
  | LXDynamicContent
  | ICMTeaser
  | ICMHtml
  | ICMExternalChannel
  | ICMProductList
  | ICMQueryList
  | ICMPlaceholder
  | ICMAlgolia
  | ICMCollectionFaqs

export const isLXTeaser = (item: IPlacementItem): item is ILXTeaser => (item as ILXTeaser).type === 'LXTeaser'

export const isCMCollection = (item: IPlacementItem): item is ICMCollection => item.type === 'CMCollection'

export const isCMExternalProduct = (item: IPlacementItem): item is ICMExternalProduct =>
  item.type === 'CMExternalProduct'

export const isCMProductTeaser = (item: IPlacementItem): item is ICMProductTeaser => item.type === 'CMProductTeaser'

export const isCMArticle = (item: IPlacementItem): item is ICMArticle => item.type === 'CMArticle'

export const isCMChannel = (item: IPlacementItem): item is ICMChannel => item.type === 'CMChannel'

export const isPictureMedia = (item: TMedia | undefined): item is IPictureMedia => item?.type === 'CMPicture'

export const isVideoMedia = (item?: TMedia): item is IVideoMedia => item?.type === 'CMVideo'

export const isDynamicContent = (item: IPlacementItem): item is LXDynamicContent =>
  (item as LXDynamicContent).type === 'LXDynamicContent'

export const isCMExternalPage = (item: IPlacementItem): item is ICMExternalPage => item.type === 'CMExternalPage'

export const isCMExternalChannel = (item: IPlacementItem): item is ICMExternalChannel =>
  item.type === 'CMExternalChannel'

export const isCMProductList = (item: IPlacementItem): item is ICMProductList => item.type === 'CMProductList'

export const isCMQueryList = (item: IPlacementItem): item is ICMQueryList => item.type === 'CMQueryList'

export const isCMPlaceholder = (item: IPlacementItem): item is ICMPlaceholder => item.type === 'CMPlaceholder'

export const isCMHtml = (item: IPlacementItem): item is ICMHtml => item.type === 'CMHTML'

export const isAlgoliaRecommendationsPlacement = (item: IPlacementItem): item is ICMPlaceholder =>
  item.viewtype === 'Algolia Recommendations'

export interface ICMCollectionFaqs extends ICMCollection {
  teaserTitle1: string
  teaserTitle2: string
  teaserText1: string
  name: string
  promoteToH1: boolean
}
