import { ButtonProps as MuiButtonProps } from '@mui/material'
import { ElementType } from 'react'
import Link, { LinkProps as NextLinkProps } from 'next/link'
import { StyledButton } from './Button.style'
import { useRouter } from 'next/router'
import { getClientLocale, replaceLocaleInUrl } from '@utils/locale'
import { locationOriginSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'
export interface Button {
  fillType?: 'contained' | 'text' | 'outline' | 'outlined' | 'fill'
  variant?: MuiButtonProps['color'] | 'tertiary' // Aliased to color to fit legacy architecture
  color?: MuiButtonProps['color']
  children?: MuiButtonProps['children']
  size?: MuiButtonProps['size'] | 'big'
  disabled?: MuiButtonProps['disabled']
  className?: MuiButtonProps['className']
  icon?: MuiButtonProps['startIcon'] //  Aliased to startIcon since we only have icon
  startIcon?: MuiButtonProps['startIcon']
  endIcon?: MuiButtonProps['endIcon']
  type?: MuiButtonProps['type']
  fullWidth?: MuiButtonProps['fullWidth']
  name?: MuiButtonProps['name']
  component?: ElementType
  loading?: boolean
  id?: string
  labelText?: string
  form?: MuiButtonProps['form']
  sx?: MuiButtonProps['sx']
  style?: MuiButtonProps['style']
}

interface StandardButton extends Button {
  href?: NextLinkProps['href']
  target?: '_blank' | '_self' | '_parent' | '_top'
  onClick?: never
}

interface ActionButton extends Button {
  onClick: MuiButtonProps['onClick']
  href?: never
  target?: never
}

interface NoActionButton extends Button {
  href?: never
  target?: never
  onClick?: never
}

const fillTypeMap = {
  fill: 'contained',
  outline: 'outlined',
}

const sizeMap = {
  big: 'large',
}

const variantMap = {
  tertiary: 'secondary',
}

export type ButtonProps = NoActionButton | StandardButton | ActionButton

function Button(props: ButtonProps) {
  const {
    children,
    icon: startIcon,
    endIcon,
    href,
    target,
    onClick,
    variant,
    fillType,
    size,
    labelText,
    color,
    ...rest
  } = props
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const internalHref = typeof href === 'string' ? href : href?.toString()
  const buttonVariant = fillTypeMap[fillType as string] ?? fillType
  const sizeVariant = sizeMap[size as string] ?? size
  const colorVariant = variantMap[variant as string] ?? variant ?? color

  const buttonProps = {
    startIcon,
    endIcon,
    ...(href
      ? {
          href: replaceLocaleInUrl({ isLocaleDomain, locationOrigin, locale, href: internalHref }),
          component: Link,
          children,
          ...(target ? { target } : {}),
        }
      : onClick
        ? { onClick, children }
        : { children }),
    ...rest,
    color: colorVariant,
    variant: buttonVariant,
    size: sizeVariant,
    locale: getClientLocale(isLocaleDomain, locale as string),
  }

  return (
    <StyledButton disableRipple disableElevation {...buttonProps}>
      {labelText || children}
    </StyledButton>
  )
}

export default Button
