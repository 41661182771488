import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSatisfactionReferAFriend2 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M13.65 9.171a.332.332 0 0 1-.172-.048l-.057-.036c-.251-.151-2.438-1.525-2.438-3.102A1.576 1.576 0 0 1 13.65 4.85a1.575 1.575 0 0 1 2.667 1.135c0 1.58-2.188 2.952-2.438 3.104l-.058.035a.332.332 0 0 1-.171.048Zm-1.093-4.1a.914.914 0 0 0-.914.914c0 .618.544 1.255 1 1.681a7.8 7.8 0 0 0 1.007.785 7.8 7.8 0 0 0 1.006-.785c.456-.425 1-1.063 1-1.681a.914.914 0 0 0-1.717-.438.329.329 0 0 1-.58 0 .915.915 0 0 0-.802-.476ZM10.01 9.623c1.006 0 1.823.818 1.823 1.822a1.825 1.825 0 0 1-1.823 1.822 1.825 1.825 0 0 1-1.822-1.822c0-1.004.818-1.822 1.822-1.822Zm0-.771a2.593 2.593 0 1 0 0 5.186 2.593 2.593 0 0 0 0-5.186Zm5.68 11.135a5.686 5.686 0 0 0-5.68-5.68 5.686 5.686 0 0 0-5.679 5.68.386.386 0 0 0 .77 0 4.914 4.914 0 0 1 4.91-4.908 4.914 4.914 0 0 1 4.908 4.908.386.386 0 0 0 .77 0Zm1.138-10.486a.865.865 0 0 1 0 1.727.865.865 0 0 1-.863-.863c0-.476.388-.864.863-.864Zm0-.66a1.524 1.524 0 1 0 0 3.047 1.524 1.524 0 0 0 0-3.047Zm3.503 6.867a3.507 3.507 0 0 0-3.503-3.502c-1.246 0-2.408.67-3.033 1.75a.33.33 0 0 0 .571.332 2.851 2.851 0 0 1 2.461-1.42 2.845 2.845 0 0 1 2.842 2.841.33.33 0 1 0 .66 0h.002Z"
      />
    </SvgIcon>
  )
}
export default SvgSatisfactionReferAFriend2
