import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgRefresh = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.527 5.362a7.974 7.974 0 0 1 9.338.367c3.432 2.705 4.035 7.69 1.352 11.135h.001a.386.386 0 0 1-.025.03l-.003.003a.388.388 0 0 1-.606-.478l.001-.003a.363.363 0 0 1 .021-.03l.002.002c2.422-3.11 1.878-7.611-1.222-10.053a7.2 7.2 0 0 0-8.374-.37l.862.141a.386.386 0 0 1 .322.423l-.002.02a.437.437 0 0 1-.026.112.387.387 0 0 1-.425.245l-1.81-.299a.388.388 0 0 1-.244-.148.443.443 0 0 1-.083-.324L6.9 4.332a.395.395 0 0 1 .235-.298.387.387 0 0 1 .541.414l-.149.914Zm8.838 13.277a7.975 7.975 0 0 1-9.338-.368h.001c-3.433-2.705-4.036-7.69-1.352-11.135h-.001a.396.396 0 0 1 .024-.03l.003-.003a.388.388 0 0 1 .607.478l-.002.003a.39.39 0 0 1-.02.03l-.002-.002a7.188 7.188 0 0 0 1.222 10.054 7.199 7.199 0 0 0 8.374.37l-.863-.142a.386.386 0 0 1-.322-.423l.002-.02a.438.438 0 0 1 .027-.112.387.387 0 0 1 .425-.245l1.809.299c.098.017.185.07.245.148a.443.443 0 0 1 .082.324l-.293 1.803a.394.394 0 0 1-.235.298.387.387 0 0 1-.542-.414l.15-.913Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  )
}
export default SvgRefresh
