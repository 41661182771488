import { Link } from '@components/common/Link/Link'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { locationOriginSelector } from '@redux/selectors/site'
import { useRouter } from 'next/router'
import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'

type TCmsFullBannerAnchor = {
  toLink?: string
  bannerIndex?: number
  teaserIndex?: number
}

export const CmsFullBannerAnchor: React.FC<PropsWithChildren<TCmsFullBannerAnchor>> = ({
  children,
  toLink,
  bannerIndex,
  teaserIndex,
}) => {
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const { basePath } = useStoreIdentity()
  const AnchorImageBannerProps = {
    'aria-label': `Placement_Banner${bannerIndex}_IMG link. Teaser №${teaserIndex}`,
    'data-element-id': `X_X_${teaserIndex}Placement_Banner${bannerIndex}_IMG`,
  }
  return (
    <>
      {toLink ? (
        <Link
          {...AnchorImageBannerProps}
          href={`${basePath}${toLink}`}
          locale={locale as string}
          isLocaleDomain={isLocaleDomain}
          locationOrigin={locationOrigin}
        >
          {children}
        </Link>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
