import CMSCommonMedia from '@components/Cms/CmsComponents/CmsCommonMedia'
import { useSite } from '@foundation/hooks/useSite'
import { TMedia } from '@typesApp/cmsPlacement/Media'
import { FC } from 'react'
import { ICMArticle } from '@typesApp/cmsPlacement/CMArticle'
import { getArticleTitleAlign, replaceTextFromXStore } from '@utils/placements'
import { REG_EX } from '@constants/common'
import {
  StyledTextModuleContainer,
  StyledDetailTextArticle,
  StyledTitleArticle,
  StyledWrapperTextModule,
  StyledWrapperTextModuleMedia,
} from '../TextModule.style'
import useReplaceLocaleInCMSUrl from '@hooks/useReplaceLocaleInCMSUrl'

const TextModuleArticle: FC<{ item: ICMArticle }> = ({ item }) => {
  const { mySite } = useSite()
  const replaceLocaleInCmsUrls = useReplaceLocaleInCMSUrl()
  if (!item) return null
  const title = (REG_EX.NO_SINGLE_DOTS.test(item?.title?.trim()) && item?.title) || ''
  let detailText = replaceTextFromXStore(
    `${item.detailText.replace('</table>', '</table></div><div>')}` || '',
    mySite?.xStoreCfg.shortCodes
  )
  detailText = detailText ? replaceLocaleInCmsUrls(detailText) : detailText
  const articleTitleAlign = getArticleTitleAlign(item?.articleTitleAlign)
  const media: TMedia = (item?.media && item?.media[0]) || null

  return (
    <StyledWrapperTextModule>
      {!!media && (
        <StyledWrapperTextModuleMedia>
          <CMSCommonMedia type={'FULL_WIDTH_BANNER'} media={media} isLazy={true} />
        </StyledWrapperTextModuleMedia>
      )}
      <StyledTextModuleContainer>
        {title && (
          <StyledTitleArticle
            data-cm-metadata={`[{"_":"properties.${title}"}]`}
            textAlign={articleTitleAlign}
            variant="h6"
          >
            {title}
          </StyledTitleArticle>
        )}
        {detailText && (
          <StyledDetailTextArticle
            data-cm-metadata={`[{"_":"properties.${detailText}"}]`}
            dangerouslySetInnerHTML={{ __html: detailText }}
          />
        )}
      </StyledTextModuleContainer>
    </StyledWrapperTextModule>
  )
}

export default TextModuleArticle
