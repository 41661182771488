import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgInformation = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M11.95 20.08c-4.412 0-8-3.59-8-8s3.588-8 8-8 8 3.59 8 8-3.59 8-8 8Zm0-15.226c-3.984 0-7.225 3.242-7.225 7.225 0 3.983 3.242 7.225 7.225 7.225 3.983 0 7.225-3.242 7.225-7.225 0-3.983-3.242-7.225-7.225-7.225Zm-.144 10.877c-.245 0-.473-.01-.697.004-.236.014-.378.156-.381.348-.005.203.14.373.376.378.73.013 1.462.019 2.192.002.308-.007.476-.333.297-.561-.074-.095-.232-.153-.361-.17-.212-.027-.432-.007-.662-.007v-4.811c0-.077 0-.156-.008-.233-.03-.275-.15-.388-.431-.392a29.028 29.028 0 0 0-.98 0c-.256.004-.424.161-.423.38.002.215.156.346.42.35.217.004.436 0 .66 0v4.711l-.002.001Zm-.645-7.35c.004.385.327.71.7.706a.708.708 0 0 0 .691-.714.696.696 0 0 0-1.39.008h-.001Z"
      />
    </SvgIcon>
  )
}
export default SvgInformation
