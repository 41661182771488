import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgHelp = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19 12a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm1 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8.494 2.006v.304h.78v-.193c0-.242.024-.449.075-.62a1.41 1.41 0 0 1 .282-.51c.136-.169.334-.362.591-.58.293-.239.547-.464.762-.675.215-.214.381-.449.498-.703.117-.258.176-.568.176-.931 0-.653-.207-1.164-.621-1.536C13.635 8.188 13.05 8 12.297 8c-.461 0-.879.053-1.254.158a5.368 5.368 0 0 0-1.043.41l.34.786c.277-.137.566-.254.867-.352.3-.098.645-.147 1.031-.147.477 0 .846.11 1.108.329.261.214.392.525.392.931 0 .254-.039.47-.117.65-.078.18-.207.36-.387.54-.175.175-.416.39-.72.644a3.973 3.973 0 0 0-.616.621 1.823 1.823 0 0 0-.304.633 3.19 3.19 0 0 0-.088.803ZM11.44 15.5c-.129.113-.193.307-.193.58 0 .27.065.467.193.592a.698.698 0 0 0 .498.181c.192 0 .354-.06.487-.181.136-.125.205-.322.205-.592 0-.273-.069-.467-.205-.58a.709.709 0 0 0-.486-.176.712.712 0 0 0-.499.176Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  )
}
export default SvgHelp
