import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSave45 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M18.346 17.427a7.934 7.934 0 0 1-5.332 2.989 7.928 7.928 0 0 1-5.884-1.657A7.99 7.99 0 0 1 4.096 12.9a.388.388 0 0 1 .774-.04 7.214 7.214 0 0 0 2.74 5.29 7.161 7.161 0 0 0 5.312 1.495 7.165 7.165 0 0 0 4.814-2.699c2.46-3.123 1.921-7.666-1.203-10.126a7.23 7.23 0 0 0-8.41-.372l.866.142a.39.39 0 0 1 .323.424l-.002.02a.455.455 0 0 1-.027.117.388.388 0 0 1-.425.242l-1.817-.3a.39.39 0 0 1-.247-.15.448.448 0 0 1-.082-.324l.295-1.811a.403.403 0 0 1 .24-.303.388.388 0 0 1 .538.418l-.15.916a8.008 8.008 0 0 1 9.378.369c3.46 2.725 4.057 7.756 1.333 11.216v.003Zm-9.544-3.094h.876v-.668h.402v-.714h-.402v-2.207h-.917l-1.59 2.274.112.647h1.519v.668Zm-.72-1.376.724-1.055v1.055h-.723Zm3.364 1.442c.78 0 1.346-.464 1.346-1.258v-.016c0-.774-.535-1.11-1.117-1.11-.188 0-.33.034-.464.085l.031-.545h1.402v-.79h-2.115l-.102 1.81.515.29a.893.893 0 0 1 .474-.143c.28 0 .48.158.48.423v.01c0 .265-.179.433-.48.433-.26 0-.49-.117-.739-.315l-.453.672c.326.28.724.453 1.223.453v.001Zm2.415-1.738c.49 0 .821-.398.821-.953v-.035c0-.555-.326-.953-.815-.953-.49 0-.821.398-.821.953v.04c0 .551.331.95.815.95v-.002Zm.861-.22-1.305 1.892h.601l1.106-1.662 1.31-1.906h-.602l-1.111 1.677h.001Zm-.86-1.197c.152 0 .248.163.248.433v.031c0 .27-.091.434-.244.434s-.25-.169-.25-.434v-.035c0-.265.092-.428.245-.428Zm2.124 3.134c.49 0 .82-.402.82-.953v-.04c0-.551-.325-.949-.815-.949-.49 0-.82.398-.82.953v.035c0 .555.33.953.815.953v.001Zm.005-.525c-.152 0-.254-.163-.254-.433v-.031c0-.27.092-.433.249-.433s.25.168.25.433v.035c0 .265-.097.428-.246.428l.002.001Z"
      />
    </SvgIcon>
  )
}
export default SvgSave45
