import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCustomerService = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M11.912 15.538c-1.296 0-1.874-1.073-1.998-1.64a.385.385 0 0 1 .752-.168c.025.108.27 1.038 1.248 1.038.977 0 1.272-1.005 1.283-1.048a.384.384 0 0 1 .746.186c-.138.564-.741 1.632-2.03 1.632Zm6.843-1.367c-.104.047-.34.124-.766.124-.098 0-.206-.004-.323-.013-.166.638-.458 1.345-.969 1.967-.897 1.093-1.688 1.585-2.105 1.789a1.516 1.516 0 0 1-1.49 1.778c-.834 0-1.514-.68-1.514-1.515 0-.834.68-1.513 1.514-1.513.47 0 .89.215 1.168.552.328-.164 1.022-.593 1.832-1.58 1.12-1.364 1.002-3.299 1-3.318v-1.458c-3.967-.077-6.242-1.776-7.06-2.529-1.115 1.486-2.7 2.274-3.315 2.539v2.887a.385.385 0 0 1-.53.356 4.02 4.02 0 0 1-.446.026c-.432 0-.67-.084-.786-.144-.874-.31-1.024-1.15-.98-1.577l.01-1.582c-.052-1.008.094-1.62.46-1.924.462-.387 1.171-.336 1.613-.25.167-.909.57-2.103 1.535-3.085 1.736-1.766 3.473-1.935 4.353-1.876l.056.003c.81.052 3.272.207 5.018 2.908.09.12.549.791.748 2.047.448-.043 1.142-.006 1.572.522.183.223.381.612.325 1.7l.029 1.558c.064.442-.056 1.3-.947 1.608h-.002Zm-4.91 4.13a.746.746 0 0 0-1.488 0 .746.746 0 0 0 1.488 0Zm-7.89-4.814v-3.33a5.178 5.178 0 0 1 .012-.606c-.339-.069-.81-.103-1.022.076-.041.034-.24.255-.183 1.304v.023l-.011 1.614c0 .016 0 .03-.003.045-.01.098-.038.615.492.79.05.016.06.012.091.041.05.019.234.072.622.044h.002ZM17.1 10.212V10c0-1.887-.684-2.797-.69-2.806l-.022-.028c-1.536-2.386-3.637-2.52-4.427-2.569l-.058-.003c-.75-.05-2.234.1-3.755 1.648-1.547 1.574-1.427 3.86-1.426 3.883v.021c.735-.362 2.087-1.163 2.94-2.496a.385.385 0 0 1 .617-.041c.022.025 2.205 2.505 6.82 2.604Zm1.837 2.446a.445.445 0 0 1-.005-.055l-.03-1.597v-.028c.02-.35.025-.97-.146-1.18-.212-.26-.618-.275-.9-.249.008.145.013.294.013.45v2.403c.006.092.025.528-.056 1.118.388.021.574-.034.616-.048.023-.016.026-.013.058-.024.518-.17.465-.69.45-.792v.002Z"
      />
    </SvgIcon>
  )
}
export default SvgCustomerService
