import { Typography } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const ProductPriceContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isPDP' && prop !== 'isCompact' && prop !== 'isForSuggestedProduct',
  name: 'ProductPrice',
  slot: 'Container',
})<{ isPDP: boolean | null; isCompact: boolean | null; isForSuggestedProduct?: boolean }>(
  ({ isPDP, isCompact, isForSuggestedProduct, theme }) => ({
    width: '100%',
    display: isPDP ? 'flex' : 'initial',
    flexWrap: isCompact ? 'wrap' : 'nowrap',
    justifyContent: isForSuggestedProduct ? 'center' : 'flex-start',
    alignItems: 'left',
    fontSize: isPDP ? 14 : isCompact ? 10 : 12,

    [theme.breakpoints.up('sm')]: {
      fontSize: isPDP ? 14 : 12,
      flexWrap: 'nowrap',
    },
  })
)

export const ProductPriceDetails = styled('div', {
  name: 'ProductPrice',
  slot: 'ContentDiv',
})(() => ({
  width: '100%',
  flexWrap: 'nowrap',
  justifyContent: 'left',
  alignItems: 'left',
}))

export const ProductPriceContainerPDP = styled('div', {
  name: 'ProductPrice',
  slot: 'ContainerPDP',
  shouldForwardProp: prop => prop !== 'isForSuggestedProduct',
})<{ isForSuggestedProduct: boolean | undefined }>(({ theme, isForSuggestedProduct }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  fontSize: 20,
  fontWeight: 'bold',
  justifyContent: 'space-between',
  color: theme.palette.text.dark.primary,

  div: {
    width: 'fit-content',
  },
  '> div': {
    flexDirection: !isForSuggestedProduct ? 'row' : 'column-reverse',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
}))

export const KlarnaMessageContainerPDP = styled('div', {
  name: 'KlarnaMessage',
  slot: 'ContainerPDP',
})(() => ({
  position: 'relative',
}))

export const SuggestedProductPriceContainerPDP = styled('div', {
  name: 'SuggestedProductPrice',
  slot: 'Container',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const FavouriteProductPriceContainerPDP = styled('div', {
  name: 'FavouriteProductPrice',
  slot: 'Container',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const ProductPriceTypeName = styled('span', {
  name: 'ProductPrice',
  slot: 'TextField',
})(({ theme }) => ({
  flexGrow: 0,
  fontFamily: 'inherit',
  fontSize: 20,
  fontWeight: 600,
  lineHeight: 1.4,
  textAlign: 'left',
  textTransform: 'uppercase',
  color: theme.palette.text.dark.primary,
}))

export const ProductCurrentPriceContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isPDP',
  name: 'ProductPrice',
  slot: 'CurrentPriceContainer',
})<{ isPDP: boolean | null }>(({ isPDP, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: theme.palette.text.dark.primary,
  fontSize: 20,
  fontWeight: isPDP ? 600 : 400,
}))

export const ProductFrame = styled('span')({})

export const ProductPriceCurrentPrice = styled('span', {
  name: 'ProductPrice',
  slot: 'CurrentPrice',
  shouldForwardProp: prop => prop !== 'isPDP' && prop !== 'isCompact' && prop !== 'isCart' && prop !== 'plpStyle',
})<{
  isPDP?: boolean
  isCompact?: boolean
  isCart?: boolean
}>(({ isPDP, isCompact, isCart, theme }) => ({
  fontSize: isCompact ? 12 : 14,
  fontWeight: 600,
  marginLeft: 1,

  [theme.breakpoints.up('sm')]: {
    fontSize: isPDP ? 20 : isCart ? 20 : 14,
  },
}))

export const ProductPriceLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isPDP',
  name: 'ProductPrice',
  slot: 'Label',
})(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 600,
}))

export const ProductPriceCurrentPriceContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'CurrentPriceContainer',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const SuggestedProductPriceCurrentPrice = styled('span', {
  name: 'SuggestedProductPrice',
  slot: 'CurrentPrice',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 'normal',
  lineHeight: 1.6,

  [theme.breakpoints.up('sm')]: {
    lineHeight: 1.43,
  },
}))

export const FavouriteProductPriceCurrentPrice = styled('span', {
  name: 'FavouriteProductPrice',
  slot: 'CurrentPrice',
})(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 'normal',
  lineHeight: 1.6,

  [theme.breakpoints.up('sm')]: {
    lineHeight: 1.43,
  },
}))

export const SuggestedPerBoxProductPrice = styled('span')(({ theme }) => ({
  fontSize: 10,
  lineHeight: 1.6,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
    lineHeight: 1.43,
  },
}))

export const ProductPriceFrom = styled('span', {
  name: 'ProductPrice',
  slot: 'TextField',
})({
  fontSize: 12,
})

export const SuggestedProductPriceFrom = styled('span', {
  name: 'ProductPrice',
  slot: 'TextField',
})({
  fontSize: 12,
  fontWeight: 400,
})

export const ProductPriceInitialPrice = styled('span', {
  name: 'ProductPrice',
  slot: 'InitialPrice',
  shouldForwardProp: prop => prop !== 'isCart',
})<{ isCart?: boolean }>(({ theme, isCart }) => ({
  fontSize: isCart ? 14 : 10,
  textDecoration: 'line-through',
  color: theme.palette.error.main,

  [theme.breakpoints.up('sm')]: {
    fontSize: isCart ? 14 : 12,
  },
}))

export const ProductPriceInitialPricePDP = styled('span', {
  name: 'ProductPrice',
  slot: 'InitialPricePDP',
})(({ theme }) => ({
  fontSize: 14,
  flex: '0 0 auto',
  textDecoration: 'line-through',
  padding: `0 ${theme.spacing(2.5)}`,
}))

export const SuggestedProductPriceInitialPricePDP = styled('span', {
  name: 'InitialPrice',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: theme.spacing(2.5),
  textDecoration: 'line-through',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(3),
  },
}))

export const FavouriteProductPriceInitialPricePDP = styled('span', {
  name: 'InitialPrice',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  textDecoration: 'line-through',
}))

export const ProductPriceDiscountBox = styled('span', {
  shouldForwardProp: prop => prop !== 'isPDP',
  name: 'ProductPrice',
  slot: 'DiscountBox',
})<{ isPDP: boolean | null }>(({ isPDP, theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  marginLeft: isPDP ? 'auto' : 0,
  marginRight: 'auto',
  padding: theme.spacing(0.5),
  marginTop: theme.spacing(1),
  fontSize: isPDP ? 14 : 12,
  fontWeight: 600,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.text.dark.secondary,
  width: 'fit-content',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0.6),
  },
}))

export const ProductPriceDiscount = styled('span', {
  name: 'ProductPrice',
  slot: 'DiscountText',
})(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.43,
  textTransform: 'uppercase',
  color: theme.palette.text.dark.primary,
}))

export const ProductPriceContent = styled('div', {
  name: 'ProductPrice',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: 0,
}))

export const ProductPriceDiscountContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'DiscountContainer',
})(({ theme }) => ({
  padding: theme.spacing(0.5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.background.dark.primary}`,
  background: 'transparent',
}))

export const ProductPriceBoxesNr = styled('span', {
  name: 'ProductPrice',
  slot: 'BoxesNr',
})(({ theme }) => ({
  flexGrow: 0,
  fontFamily: 'inherit',
  fontSize: 14,
  fontWeight: 'normal',
  lineHeight: 1.43,
  textAlign: 'left',
  color: theme.palette.text.dark.primary,
  paddingTop: theme.spacing(4),
}))

export const ProductPriceWrapper = styled('div', {
  name: 'ProductPrice',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'isLoading' && prop !== 'isForSuggestedProduct',
})<{ isLoading?: boolean; isForSuggestedProduct?: boolean }>(({ theme, isLoading, isForSuggestedProduct }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  justifyContent: isForSuggestedProduct ? 'center' : 'normal',
  div: {
    display: isLoading ? 'inherit' : 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    alignItems: 'baseline',
    gap: theme.spacing(1),
  },
}))

export const ProductPricePricePerBoxContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'PricePerBoxContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 0,
  alignSelf: 'stretch',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(4),
  },
}))

export const ProductPricePricePerBox = styled(Typography, {
  name: 'ProductPrice',
  slot: 'PricePerBox',
})(({}) => ({
  textAlign: 'left',
}))

export const ProductPricePricePerBoxPrices = styled('div', {
  name: 'ProductPrice',
  slot: 'PricePerBoxPrices',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 0,
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  padding: 0,
  color: theme.palette.text.dark.primary,
}))

export const ProductPricePricePerBoxInitialPrice = styled(Typography, {
  name: 'ProductPrice',
  slot: 'PricePerBoxInitialPrice',
})(({ theme }) => ({
  flexGrow: 0,
  textDecoration: 'line-through',
  textAlign: 'right',
  color: theme.palette.error.main,
}))

export const ProductPricePricePerBoxCurrentPrice = styled(Typography, {
  name: 'ProductPrice',
  slot: 'PricePerBoxCurrentPrice',
})({
  flexGrow: 0,
  textAlign: 'right',
})
