import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgLunchSandwich = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M19.516 11.712 13.15 17.58c-.36.331-.822.498-1.285.498-.462 0-.924-.167-1.284-.498l-6.368-5.868a1.073 1.073 0 0 1-.274-1.187 1.073 1.073 0 0 1 1.005-.686.389.389 0 0 1 0 .776.296.296 0 0 0-.282.193.296.296 0 0 0 .077.333l6.368 5.868c.425.391 1.09.391 1.515 0l6.367-5.868a.296.296 0 0 0 .077-.333.295.295 0 0 0-.282-.193.389.389 0 0 1 0-.776c.448 0 .842.27 1.005.686.163.418.056.883-.274 1.187h.002ZM3.956 7.933c.034-.401.223-.74.522-.977a.942.942 0 0 1-.157-.953.944.944 0 0 1 .883-.603h13.32a.94.94 0 0 1 .884.603.94.94 0 0 1-.231 1.032c.26.203.44.506.5.888.109.714-.24 1.365-.813 1.514-.331.087-.617.497-.652.935-.06.779-.854 1.511-1.635 1.511h-.007c-.373 0-.831.423-.86.795-.061.779-.854 1.511-1.635 1.511h-.007c-.373 0-.832.423-.86.795-.05.627-.518 1.261-1.32 1.261h-.046c-.802 0-1.27-.634-1.32-1.26-.029-.373-.487-.796-.86-.796h-.008c-.781 0-1.574-.733-1.635-1.51-.029-.373-.487-.796-.86-.796h-.007c-.781 0-1.574-.733-1.635-1.51-.034-.434-.427-.846-.695-.949-.559-.217-.923-.844-.866-1.49Zm14.953.109c-.023-.147-.095-.365-.32-.461l-6.081 5.602a.944.944 0 0 1-.642.25.944.944 0 0 1-.643-.25L5.057 7.502A.611.611 0 0 0 4.731 8c-.026.305.135.606.375.7.538.21 1.13.87 1.187 1.613.029.372.487.795.86.795h.007c.781 0 1.574.733 1.635 1.51.03.373.487.796.861.796h.008c.78 0 1.574.733 1.635 1.51.015.202.148.545.545.545h.046c.397 0 .529-.343.546-.545.06-.778.854-1.51 1.634-1.51h.008c.374 0 .832-.423.86-.795.062-.78.855-1.511 1.636-1.511h.006c.374 0 .832-.423.861-.795.06-.772.577-1.456 1.23-1.626.171-.045.286-.352.241-.645l-.003-.001ZM5.087 6.476l6.66 6.137c.066.06.168.06.234 0l6.66-6.137a.161.161 0 0 0 .044-.189.163.163 0 0 0-.16-.11H5.204a.162.162 0 0 0-.16.11.164.164 0 0 0 .043.189ZM15.7 7.663a.455.455 0 1 0-.911 0 .455.455 0 0 0 .91 0Zm-2.99 2.902a.455.455 0 1 1 .002-.91.455.455 0 0 1-.001.91Zm-.227-2.448a.455.455 0 1 1 .001-.91.455.455 0 0 1 0 .91Zm-4.66-.431a.455.455 0 1 1 0-.91.455.455 0 0 1 0 .91Zm2.548 1.666a.455.455 0 1 1 .002-.91.455.455 0 0 1-.002.91Z"
      />
    </SvgIcon>
  )
}
export default SvgLunchSandwich
