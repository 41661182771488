import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSmoothLensSurface = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M11.59 15.913h-.002a9.605 9.605 0 0 1-5.569-1.82c-1.522-1.117-2.429-2.555-2.429-3.846 0-1.26.85-2.4 2.393-3.21C7.44 6.272 9.43 5.85 11.587 5.85c2.159 0 4.152.423 5.61 1.19 1.543.81 2.393 1.95 2.393 3.211 0 1.29-.909 2.727-2.43 3.844a9.61 9.61 0 0 1-5.569 1.818h-.001Zm-.004-9.234c-4.019 0-7.168 1.567-7.168 3.569 0 2.558 4.01 4.837 7.17 4.838l.002.414v-.414c3.16 0 7.17-2.276 7.17-4.834 0-.937-.692-1.817-1.949-2.478-1.342-.704-3.198-1.094-5.225-1.094v-.001Zm5.734 4.48a.388.388 0 0 0-.57-.526c-.618.67-2.932 1.419-5.267 1.419h-.004c-2.338 0-4.655-.748-5.272-1.42a.388.388 0 0 0-.57.526c.845.92 3.466 1.667 5.841 1.668h.004c2.371 0 4.99-.748 5.837-1.668h.001Zm-2.389 6.916a.414.414 0 1 0-.508-.654c-.003.003-.39.296-.907.44-.656.184-1.218.047-1.67-.405-.913-.913-1.864-.861-2.5-.658a3.21 3.21 0 0 0-1.128.65.414.414 0 0 0 .567.603c.131-.123 1.321-1.164 2.475-.009.536.536 1.14.726 1.714.726.802 0 1.544-.372 1.957-.692Z"
      />
    </SvgIcon>
  )
}
export default SvgSmoothLensSurface
