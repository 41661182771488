import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgPuttingInLenses2 = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M19.74 5.3a.387.387 0 0 0-.29-.13h-4.06a.388.388 0 0 0-.386.433c.143 1.217 1.114 2.034 2.416 2.034 1.302 0 2.273-.818 2.416-2.034a.387.387 0 0 0-.095-.303ZM17.42 6.86c-.731 0-1.293-.347-1.531-.914h3.063c-.239.567-.802.914-1.532.914Zm1.694 1.93a.61.61 0 0 1-.017.027l-2.57 3.84a.35.35 0 0 1-.048.06l-4.196 4.187a.385.385 0 0 1-.2.106l-7.444 1.445a.388.388 0 1 1-.148-.762l7.327-1.422 4.084-4.076 2.326-3.477c-.566-.11-1.813-.083-2.478.853l-.008.012-1.555 2.038a.393.393 0 0 1-.034.039l-.484.483a.408.408 0 0 1-.11.077c-.761.354-3.291 1.508-3.92 1.508-.669 0-1.085-.398-1.775-1.055l-.324-.307a.413.413 0 0 1-.063-.077c-.042-.067-.406-.677-.095-1.255.388-.718.885-1.14 1.285-1.48.216-.183.402-.341.518-.502.417-.575.582-1.083.491-1.51-.01-.051-.03-.088-.1-.116-.18-.072-.503-.009-.721.14-.397.273-4.367 3.02-4.407 3.049a.388.388 0 1 1-.442-.64l4.41-3.05c.432-.298 1.03-.389 1.449-.221.302.12.505.36.572.676.14.651-.07 1.368-.622 2.128-.171.237-.4.43-.644.637-.368.313-.785.667-1.104 1.257-.073.136 0 .339.05.439l.284.27c.655.623.899.84 1.239.84.318-.005 2.066-.728 3.53-1.406l.416-.417.078-.1a.389.389 0 0 1-.691-.24V9.726l-.979-1.617c-.204.063-.434.167-.54.352-.102.177-.099.438.01.776.149.463.63.86.635.864a.39.39 0 0 1 .106.468l-.751 1.584a.388.388 0 0 1-.702-.333l.629-1.328c-.213-.217-.518-.584-.657-1.017-.178-.555-.16-1.026.055-1.4.33-.574 1.02-.726 1.314-.792a.388.388 0 0 1 .416.179l1.183 1.955c.037.061.056.13.056.201v1.17c0 .077-.023.15-.063.21l1.435-1.88c1.082-1.517 3.233-1.317 3.783-.971.288.18.31.461.208.644l.003-.002Z"
      />
    </SvgIcon>
  )
}
export default SvgPuttingInLenses2
