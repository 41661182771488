import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgOven = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M16.62 3.75H7.925a3.085 3.085 0 0 0-3.071 2.836.378.378 0 0 0-.015.101c0 .021.003.041.007.062l-.003.084v9.835c0 1.7 1.383 3.082 3.082 3.082h8.692c1.7 0 3.082-1.382 3.082-3.082V6.832c0-1.7-1.382-3.082-3.082-3.082h.001Zm-8.693.785h8.692c1.082 0 1.99.752 2.231 1.76H5.695a2.3 2.3 0 0 1 2.231-1.76h.001Zm8.692 14.43H7.926a2.3 2.3 0 0 1-2.297-2.297V7.08h13.287v9.588a2.3 2.3 0 0 1-2.297 2.297v.001ZM9.091 5.405a.42.42 0 1 1 .838 0 .42.42 0 0 1-.838 0Zm1.84 0a.42.42 0 1 1 .84 0 .42.42 0 0 1-.84 0Zm1.841 0a.42.42 0 1 1 .839 0 .42.42 0 0 1-.839 0Zm1.84 0a.42.42 0 1 1 .84 0 .42.42 0 0 1-.84 0Zm3.153 4.605H6.777a.393.393 0 0 0-.392.392v6.977c0 .216.176.392.392.392h10.99a.393.393 0 0 0 .393-.392v-6.977a.393.393 0 0 0-.393-.392h-.002Zm-.392 6.977H7.169v-6.193h10.206v6.193h-.002Zm-10.44-8.2c0-.217.176-.393.392-.393h9.894a.393.393 0 0 1 0 .785H7.325a.393.393 0 0 1-.392-.392Zm1.009 6.948 2.953-4.397a.393.393 0 0 1 .65.438l-2.952 4.397a.391.391 0 1 1-.651-.437v-.001Zm1.972-.12 1.789-2.634a.392.392 0 1 1 .649.441l-1.79 2.633a.391.391 0 0 1-.544.103.393.393 0 0 1-.104-.545v.002Z"
      />
    </SvgIcon>
  )
}
export default SvgOven
