import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgPharmacy = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M13.767 16.454h-3.05a.383.383 0 0 1-.363-.261c-.019-.056-.02-.06-.02-2.29l-1.896-.013a.368.368 0 0 1-.13-.024.434.434 0 0 1-.13.021h-.014a.384.384 0 0 1-.38-.383v-3.048a.382.382 0 0 1 .381-.383h2.168l.014-1.895c0-.046.009-.09.024-.13a.402.402 0 0 1-.022-.13v-.014a.384.384 0 0 1 .383-.38h3.049a.378.378 0 0 1 .383.383v2.167h2.15c.059 0 .118.014.172.04.1.05.182.15.213.259.01.034.014.069.014.104v3.046a.384.384 0 0 1-.383.383h-2.167l-.014 1.895c0 .046-.01.09-.024.13.014.04.021.084.021.128a.382.382 0 0 1-.383.396h.004Zm-2.652-.78h2.27v-2.168a.384.384 0 0 1 .383-.384h2.167v-2.268h-2.168a.384.384 0 0 1-.383-.384V8.303h-2.269v2.168a.384.384 0 0 1-.383.383H8.565v2.268h2.168a.384.384 0 0 1 .383.385v2.167Zm1.134 4.314c-4.41 0-7.999-3.588-7.999-7.999 0-4.41 3.59-7.999 8-7.999 4.412 0 8 3.588 8 8 0 4.41-3.588 7.998-8 7.998Zm0-15.218c-3.98 0-7.217 3.238-7.217 7.218s3.237 7.218 7.217 7.218c3.98 0 7.218-3.238 7.218-7.218S16.23 4.77 12.25 4.77Zm1.535 11.684h-3.067a.384.384 0 0 1-.383-.383v-2.165H8.182a.384.384 0 0 1-.383-.383v-3.052c0-.211.172-.383.383-.383h2.168V7.921c0-.212.171-.382.381-.383l3.05-.015h.002a.385.385 0 0 1 .383.384v2.167h2.15c.211 0 .383.172.383.383v3.05a.384.384 0 0 1-.383.383h-2.149v2.181a.384.384 0 0 1-.383.383Zm-2.684-.766h2.301v-2.181c0-.212.172-.383.383-.383h2.149V10.84h-2.151a.384.384 0 0 1-.383-.384V8.29l-2.282.01v2.17a.383.383 0 0 1-.384.382H8.565v2.286h2.152c.211 0 .383.172.383.383v2.166Z"
      />
    </SvgIcon>
  )
}
export default SvgPharmacy
