import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMultipleChoice = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <rect width={16} height={16} x={4} y={4} fill="currentColor" rx={1} />
      <path fill="#fff" d="M8 11h8v2H8z" />
    </SvgIcon>
  )
}
export default SvgMultipleChoice
