import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMapPin = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M12.001 20.18c-.1 0-.199-.038-.275-.115C9.206 17.545 6.4 12.702 6.4 9.781a5.608 5.608 0 0 1 5.601-5.601 5.608 5.608 0 0 1 5.601 5.601c0 1.38-.632 3.296-1.78 5.397-1.012 1.851-2.337 3.679-3.546 4.887a.39.39 0 0 1-.276.115h.001Zm0-15.221A4.827 4.827 0 0 0 7.18 9.781c0 2.125 1.966 6.45 4.822 9.45 2.332-2.474 4.823-6.897 4.823-9.45 0-2.553-2.163-4.822-4.822-4.822Zm.39 7.776a.39.39 0 0 0-.39-.39 2.567 2.567 0 0 1-2.564-2.564 2.567 2.567 0 0 1 2.564-2.564 2.567 2.567 0 0 1 2.564 2.564.39.39 0 0 0 .78 0c0-1.843-1.5-3.343-3.344-3.343a3.347 3.347 0 0 0-3.343 3.343c0 1.843 1.5 3.343 3.343 3.343a.39.39 0 0 0 .39-.39Z"
      />
    </SvgIcon>
  )
}
export default SvgMapPin
