import { PictureProps } from '@components/common/Media/CmsPicture'
import config from '@configs/index'
import { mediaQueries } from '@hooks/useBreakpoint/helpers'
import { IVideoMedia, TMedia } from '@typesApp/cmsPlacement/Media'
import { isVideoMedia } from '@typesApp/teaser'
import { ImageCrops } from './types'

export const generateCmsImageUrl = (
  hostnameUrls: Record<string, string>,
  uriTemplate: string | undefined,
  crop: string,
  width: number | string
): string => {
  if (!uriTemplate) {
    return 'URI_TEMPLATE_NOT_FOUND'
  }
  const url = uriTemplate
    .replace('{cropName}', crop ?? 'CROP_NOT_FOUND')
    .replace('{width}', String(width) ?? 'WIDTH_NOT_FOUND')
  return (hostnameUrls?.cmsImageServerUrl || config.cmsImageServerUrl) + url
}
export const getPictureSources = (
  hostnameUrls: Record<string, string>,
  imageCrops: ImageCrops,
  media: string[]
): PictureProps['sources'] => {
  let [desktopPictureUriTemplate, mobilePictureUriTemplate] = media

  if (mobilePictureUriTemplate === undefined) {
    mobilePictureUriTemplate = desktopPictureUriTemplate
  }

  if (!desktopPictureUriTemplate) {
    return []
  }

  const sources: PictureProps['sources'] = []

  for (const mediaQueryKey in imageCrops) {
    const crop = imageCrops[mediaQueryKey]
    const url = generateCmsImageUrl(
      hostnameUrls,
      mediaQueryKey === 'sm' ? mobilePictureUriTemplate : desktopPictureUriTemplate,
      crop.crop,
      crop.width
    )

    sources.push({
      srcSet: url,
      media: mediaQueries[mediaQueryKey],
      width: crop.width,
      height: crop.height,
    })
  }
  return sources
}

export const getVideoURL = (video: IVideoMedia | undefined): string => {
  if (!video) return ''
  let url = video.data?.uri || video.dataUrl
  url = url.startsWith('https://') ? url : url.replace('http://', 'https://')

  return url
}

export const getVideoConfiguration = (hostnameUrls: Record<string, string>, media: TMedia[]) => {
  if (!media) return [{ src: '#MEDIA_NOT_FOUND' }]
  return media.map(cmVideo => {
    if (cmVideo.type !== 'CMVideo') return { src: '#MEDIA_NOT_FOUND' }
    const uri = cmVideo.data?.uri ?? cmVideo.dataUrl
    const url = uri.startsWith('/') ? uri : `/${uri}`

    return {
      src: `${config.cmsImageServerUrl}${url}`,
      autoplay: cmVideo.autoplay,
      loop: cmVideo.loop,
      muted: cmVideo.mute,
      controls: !cmVideo.hideControl,
      playsInline: true,
      playOnHover: cmVideo.playOnHover,
      poster: generateCmsImageUrl(hostnameUrls, cmVideo.picture.uriTemplate, 'desktop1280', 1280),
    }
  })
}

export const getMediaPictureUris = (media: TMedia[]): string[] => {
  return media.map(m => {
    if (isVideoMedia(m)) {
      return m.picture?.uriTemplate && m.picture.uriTemplate
    }
    return m.uriTemplate
  })
}
