import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledPriceWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: theme.spacing(2),
}))

export const StyledPrice = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isOnOffer',
})<{ isOnOffer?: boolean }>(({ theme, isOnOffer }) => ({
  ...(isOnOffer
    ? {
        ...theme.typography.body2,
        textDecoration: 'line-through',
        color: theme.palette.error.main,
      }
    : { ...theme.typography.subtitle2 }),
}))
