import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCamera = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="currentColor"
        d="M18.328 7.278h-3.095l-.844-1.371a1.054 1.054 0 0 0-.904-.505h-2.95c-.37 0-.708.189-.902.505l-.845 1.371h-.736v-.647c0-.706-.575-1.281-1.282-1.281h-.346c-.688 0-1.247.56-1.247 1.246v.764A1.685 1.685 0 0 0 4.01 8.961v8.022c0 .927.754 1.682 1.682 1.682h12.636c.928 0 1.682-.755 1.682-1.682V8.96c0-.928-.754-1.682-1.682-1.682v-.001Zm-8.032-.962a.28.28 0 0 1 .24-.134h2.95a.28.28 0 0 1 .239.134l.592.96H9.704l.592-.961Zm-3.872-.187h.346c.276 0 .502.225.502.5v.648H5.958v-.683c0-.257.21-.467.467-.467l-.001.002ZM19.23 16.982a.903.903 0 0 1-.902.902H5.692a.903.903 0 0 1-.902-.902V8.96c0-.498.404-.902.902-.902h12.636c.498 0 .902.404.902.902v8.022Zm-7.22-8.238a4.204 4.204 0 0 0-4.2 4.199c0 2.316 1.884 4.199 4.2 4.199 2.315 0 4.199-1.883 4.199-4.2 0-2.315-1.884-4.198-4.2-4.198Zm0 7.617a3.423 3.423 0 0 1-3.42-3.42 3.423 3.423 0 0 1 3.42-3.419 3.423 3.423 0 0 1 3.419 3.42 3.423 3.423 0 0 1-3.42 3.42Zm0-5.756a2.34 2.34 0 0 0-2.337 2.337 2.34 2.34 0 0 0 2.336 2.337 2.34 2.34 0 0 0 2.337-2.337 2.34 2.34 0 0 0-2.337-2.337Zm0 3.894a1.559 1.559 0 0 1-1.558-1.557c0-.859.699-1.557 1.557-1.557.86 0 1.558.698 1.558 1.557 0 .858-.699 1.557-1.558 1.557ZM7.862 9.607a.39.39 0 0 1-.39.39H5.754a.39.39 0 0 1 0-.78h1.719a.39.39 0 0 1 .39.39Zm10.294-.096a.39.39 0 0 1-.39.39h-.048a.39.39 0 0 1 0-.78h.048a.39.39 0 0 1 .39.39Z"
      />
    </SvgIcon>
  )
}
export default SvgCamera
