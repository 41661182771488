import React, { useEffect, useState } from 'react'
import styles from './styles/index.module.scss'

interface TCountdown {
  endDateInSeconds: number | string
  startDateInSeconds?: number | string
  onExpire?: () => void
  hideOnExpire?: boolean
}

type TTimeLeft = {
  totalHours: number
  hours: number
  minutes: number
  seconds: number
}

const Countdown: React.FC<TCountdown> = ({ startDateInSeconds, endDateInSeconds, onExpire, hideOnExpire }) => {
  const calculateTimeLeft = (): TTimeLeft => {
    const endDateTime = !Number.isNaN(endDateInSeconds) ? Number(endDateInSeconds) * 1000 : Date.now()
    const difference = endDateTime - Date.now()
    let timeLeft = {
      totalHours: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }

    if (difference > 0) {
      const totalSeconds = Math.floor(difference / 1000)
      const seconds = totalSeconds % 60
      const totalMinutes = Math.floor(totalSeconds / 60)
      const minutes = totalMinutes % 60
      const totalHours = Math.floor(totalMinutes / 60)
      const hours = totalHours % 24
      const totalDays = Math.floor(totalHours / 24)

      timeLeft = {
        totalHours: totalDays * 24 + hours,
        hours: totalDays * 24 + hours,
        minutes,
        seconds,
      }
    } else {
      if (onExpire) {
        onExpire()
      }
    }

    return timeLeft
  }
  const startDateTime = !Number.isNaN(startDateInSeconds) ? Number(startDateInSeconds) * 1000 : Date.now()
  const startsInTheFuture = startDateTime > Date.now()
  const [time, setTime] = useState<TTimeLeft>({
    totalHours: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const timeLeft = calculateTimeLeft()

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  const { totalHours, hours, minutes, seconds } = time

  if (
    startsInTheFuture ||
    (timeLeft.totalHours <= 0 &&
      timeLeft.minutes <= 0 &&
      timeLeft.seconds <= 0 &&
      hideOnExpire &&
      totalHours <= 0 &&
      minutes <= 0 &&
      seconds <= 0)
  ) {
    return null
  }

  return (
    <p className={styles['countdown']}>
      <strong>{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`}</strong>
    </p>
  )
}

export default Countdown
